import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminAddemployee = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectvalue, setselectvalue] = useState([]);
  const navigate = useNavigate();
  const [emData, setData] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Employee Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      user_id: 1,
    };
    axios
      .post("https://jlmining.app/getskills", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);

        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    axios
      .get("https://jlmining.app/admin/getemployee", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }
    let formdata = {
      first_name: vll.first_name.value,

      last_name: vll.last_name.value,
      email: vll.email.value,
      password: vll.password.value,
      contact: vll.contact.value,
      address: vll.address.value,
      skills: skidata,
      years: vll.years.value,
    };
    if (vll.password.value != vll.confirm_password.value) {
      setShowPass(true);
      setShowPass({ type: "error" });
    } else {
      //console.log(formdata);
      axios
        .post("https://jlmining.app/admin/userregister", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            axios
              .get("https://jlmining.app/admin/getemployee", {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                console.log(res.data.results);
                setData(res.data.results);
              })
              .catch((err) => {});
            setShowAlert(true);
            setShowAlert({ type: "success" });
            event.target.reset();
            event.target.text();
          }
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {
          //console.log(err.response.data);
        });
    }
  };
  const options = selectedOptions;
  console.log(options);
  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
    //setSelectedOptions(selectedOptions);
  };

  const handleRemoveOption = (removedOption) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option.value !== removedOption.value)
    );
  };

  const selectedOptionLabels = selectedOptions.map(
    (selectedOption) => selectedOption.value
  );

  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Employee</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link to="javascript:void(0)">
                            {item.first_name} {item.last_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Employee has been registered successfully"
                    closable
                  />
                )}
                <div className="right-form-box bg-white shadow p-2">
                  <form action="" onSubmit={handleSubmit} method="post">
                    <div className="row gy-2">
                      <div className="col-12 col-lg-4">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            First Name{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="first_name"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-4">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Last Name{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="last_name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Phone Number{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            minlength="10"
                            onChange={handleInput}
                            placeholder="type here"
                            name="contact"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            E mail-ld{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="email"
                            onChange={handleInput}
                            placeholder="type here"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Password{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="password"
                            name="password"
                            placeholder="type here"
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Re-Enter Password{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="password"
                            name="confirm_password"
                            placeholder="type here"
                            onChange={handleInput}
                            required
                          />
                          <div className="mt-2">
                            {showPass?.type === "error" && (
                              <Alert
                                type="error"
                                description="Password does not match"
                                closable
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Address <span className="text-danger fs-14">*</span>
                          </label>
                          <textarea
                            id=""
                            onChange={handleInput}
                            cols="30"
                            rows="4"
                            name="address"
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Mention All Skills{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <Select
                            isMulti
                            options={options}
                            defaultValue={options}
                            name="skills"
                            required
                            onChange={handleSelectChange}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Years Of Experience{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            onChange={handleInput}
                            placeholder="type here"
                            name="years"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn">add</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
