import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Alert } from "antd";
import moment from "moment";
import Modal from "react-modal";
import {
  FaStar,
  FaMoon,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaThermometer,
  FaSun,
  FaEdit,
  FaUmbrella,
  FaSync,
  FaTrashAlt,
} from "react-icons/fa";

import { HeaderHome } from "./HeaderHome";
import { Footer } from "./Footer";

export const Currentroster = () => {
  const [backgroundColor, setBackgroundColor] = useState("white");
  const currentYear = new Date().getFullYear();
  const [currentDateEdit, setcurrentDateEdit] = useState("");
  const [error_startend, seterror_startend] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isClickedopen, setisClickedopen] = useState(false);
  const [isClickededit, setisClickedaddorEdit] = useState(true);
  const [isClickedadd, setisClickedadd] = useState(true);
  const [isClickedloader, setIsClickedloader] = useState(false);
  const [isClickedopen_roster, setisClickedopen_roster] = useState(false);
  const [remember_roster, setremember_roster] = useState(false);
  const [setdate, setDates] = useState(null);
  const [setdate_s, setDatessvalue] = useState(null);
  const [valuegetUser, setValuegetuser] = useState([]);
  const [events, setValuegetCalendar] = useState([]);
  const [currentroster, setcurrentroster] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen_hrs, setIsModalOpen_hrs] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [valueget, setValueget] = useState([]);
  const [valueroster, setValueroster] = useState([]);
  const [valueclient, setvalueclient] = useState("");
  const [locationname, setlocationname] = useState([]);
  const [valuelocationsId, setvaluelocationsId] = useState("");
  const [valueclientId, setvalueclientId] = useState("");
  const [valuelocations, setvaluelocations] = useState("");
  const [locatename, setlocatename] = useState("");
  const [daystatus, setdaystatus] = useState("Attendance");
  const [sel, setSelect] = useState("Present");
  const [iconset, setIcon] = useState("");
  const [colorActive, setcolorActive] = useState("");
  const [shifts, setshifts] = useState("");

  //Roster Page
  const [showAlert_hrs, setshowAlert_hrs] = useState(false);
  const [showAlert_week, setShowAlert_week] = useState(false);
  const [isConditionMet, setIsConditionMet] = useState(false);
  const [isConditionMetbtn, setIsConditionMetbtn] = useState("Submit");
  const [clientreq, setclientreq] = useState(false);
  const [opencloseroster, setopencloseroster] = useState(false);
  const [openclosecalender, setopenclosecalender] = useState(false);
  const [emData, setData] = useState([]);
  const [emDataclient, setDataclient] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activesIndex, setActivesIndex] = useState(null);
  const [clientID, setClientId] = useState("");
  const [gdata, getData] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [minisClicked, setMinClicked] = useState(false);
  const [clientinfo, setclientInfo] = useState("");
  const [locatreq, setlocatreq] = useState(false);
  const [locatid, setlocatid] = useState("");
  const [locationID, setLocationId] = useState("");
  const [isClickedloc, setIsClickedloc] = useState(false);
  const [located, setlocated] = useState("");
  const [locatedname, setlocatedname] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [rosterreq, setrosterreq] = useState(false);
  const [getallroster, setValuegetallroster] = useState([]);
  const [shiftmessagevalue, setshiftmessage] = useState("");
  const [inputvalue_data, setinputvalue_data] = useState("");
  const targetDivRef = useRef(null);
  const [isRequiredhrs, setIsRequiredhrs] = useState(false);
  const [isbtndisabed, setisbtndisabed] = useState(true);
  const [isRequiredfile, setIsRequiredfile] = useState(false);
  const [missingdates, setmissingdates] = useState("");
  const [missingdatessuccess, setmissingdatessuccess] = useState("");
  const [spinners, setspinners] = useState(false);
  const [sctext, setsctext] = useState("");
  //Roster Page
  const [checkcoverage, setcheckcoverage] = useState(false);
  const handlePopupTextChange = (event) => {
    setPopupText(event.target.value);
  };
  const [kmsvalue, setkmsvalue] = useState("");
  const [hrsvalue, sethrsvalue] = useState("");
  const [selectedFileticket, setSelectedFileticket] = useState(null);
  const [selectedFileother, setSelectedFileother] = useState(null);
  const handleFileChangeticket = (event) => {
    // console.log(event.target.files);
    setSelectedFileticket(event.target.files);
    if (event.target.files[0] !== null && kmsvalue !== "" && hrsvalue !== "") {
      setisbtndisabed(false);
    } else {
      setisbtndisabed(true);
    }
  };
  const handleFileChangeother = (event) => {
    setSelectedFileother(event.target.files);
  };
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log(event);
    console.log("dd");
    console.log(shifts);
    if (event.shft === "Add") {
      if (event.pop === "Open") {
        setIsModalOpen(true);
      }
      setDatessvalue(event.start);
      setDates(event.start);
      const userStr = JSON.parse(localStorage.getItem("users"));

      let formdata = {
        checkdate: event.start,
        user_id: userStr[0].id,
      };

      setTimeout(() => {
        let fdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
        };
        if (event.title === "Add") {
          //console.log(fdata);
          axios
            .post("https://jlmining.app/admin/getforEmp_client", fdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;
              //console.log("add");
              //console.log(alldata);
              if (alldata.length > 0) {
                setValueroster(alldata);
                // setlocatename([]);
              }
            })
            .catch((err) => {});
        } else {
          axios
            .post("https://jlmining.app/admin/getallrosterlimit", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.dataa;
              //console.log("getclientdata");
              // console.log(alldata.length);
              if (alldata.length > 0) {
                setvalueclient(alldata[0].name);
                setvalueclientId(alldata[0].client_id);
                //setValueroster(alldata);
                let fdata = {
                  checkdate: event.start,
                  user_id: userStr[0].id,
                  client_id: alldata[0].client_id,
                };
                axios
                  .post("https://jlmining.app/admin/getlocation_check", fdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.row;
                    // console.log("gttt");
                    // console.log(alldata);
                    if (alldata.length > 0) {
                      // setlocationname(alldata);
                      setvaluelocations(alldata[0].location_name);
                      setvaluelocationsId(alldata[0].location_id);
                      // setlocatename([]);
                    }
                  })
                  .catch((err) => {});
                axios
                  .post("https://jlmining.app/admin/getroster", formdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.results;
                    //console.log("getroster");
                    //console.log(res.data.results);
                    const fn = [];
                    for (let i = 0; i < alldata.length; i++) {
                      fn.push(alldata[i].type);
                    }
                    setValueget(fn);
                  })
                  .catch((err) => {});
              }
            });
        }
        setDataclient([]);
        setlocatedname("");
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
        if (event.title === "Add") {
          setisClickedaddorEdit(false);
          setisClickedadd(true);
        } else {
          setisClickedaddorEdit(true);
          setisClickedadd(false);
        }
      }, 1000);
    } else {
      //console.log(shifts);
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        const userStr = JSON.parse(localStorage.getItem("users"));
        let formdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
          shift: shifts,
        };
        axios
          .post("https://jlmining.app/admin/multiplerosteradd", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("getallcalender");
            getallcalender();
          })
          .catch((err) => {});
        setlocatedname("");
        setDataclient([]);
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
      }
    }
  };
  const handleget_refresh = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    setIsClickedloader(true);
    if (!userStr) {
      navigate("/login");
    }

    let formdata = {
      user_id: userStr[0].id,
    };
    setTimeout(() => {
      setIsClickedloader(false);
    }, 1000);
    //window.location.reload();
    getallcalender();
  };
  const handleCheckboxChange = (event) => {
    var ch = event.target.value;
    setSelect(ch);
  };
  const isRequired = () => {
    // Add your conditions here based on the selected option value
    if (sel === "Sick Leave") {
      return false;
    }
    if (sel === "AL") {
      return false;
    }
    if (sel === "Present") {
      return true;
    }
    if (sel === "") {
      return true;
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("users");
    window.location.href = "/login";
  };
  const [showAlert, setShowAlert] = useState(false);
  const numbers = Array.from({ length: 24 }, (_, index) => index + 1);
  const kms = Array.from({ length: 1000 }, (_, index) => index + 1);

  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month/day is a single digit
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  };
  const getday = () => {
    const dateList = ["2023-07-26", "2023-07-28", "2023-07-31"];

    // Convert the date strings to JavaScript Date objects and sort them in ascending order
    const sortedDates = dateList
      .map((dateString) => new Date(dateString))
      .sort((a, b) => a - b);

    // Find the earliest and latest dates in the list
    const earliestDate = sortedDates[0];
    const latestDate = sortedDates[sortedDates.length - 1];

    // Create an array of dates starting from the earliest date and incrementally add one day
    const allDates = [];
    let currentDate = new Date(earliestDate);

    while (currentDate <= latestDate) {
      allDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Filter out the dates that are already present in the given list
    const missingDates = allDates
      .filter(
        (date) => !sortedDates.find((d) => d.getTime() === date.getTime())
      )
      .map((date) => date.toISOString().slice(0, 10));

    return missingDates;
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    document.title = "Current Roster Page";
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }

    let formdata = {
      user_id: userStr[0].id,
    };
    const apiurl = "https://jlmining.app/uploads/";
    var imageUrl = `${apiurl}${valuegetUser.image}`;
    if (valuegetUser.image == null) {
      var im = "male-upload.png";
      var imageUrl = `${apiurl}${im}`;
    } else {
      var imageUrl = `${apiurl}${valuegetUser.image}`;
    }

    let formdsata = {
      user_id: userStr[0].id,
    };

    //Roster Page
    axios
      .post("https://jlmining.app/admin/getallrosters", formdsata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;

        setValuegetallroster(alldata);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getclientforroster", formdsata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("client idd");
        //console.log(res.data.results);
        setDataclient(res.data.results);
      })
      .catch((err) => {});
    //Roster Page

    axios
      .post("https://jlmining.app/admin/getuserroster", formdsata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("checkcurrent");
        // console.log(res.data.row.length);
        if (res.data.row.length > 0) {
          setopenclosecalender(true);
          setopencloseroster(false);
        } else {
          setopenclosecalender(false);
          setopencloseroster(true);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("hett");
        console.log(res.data.row);
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getcurrentroster", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.filteredResults[0];
        if (alldata != "") {
          var cudate = new Date();
          var cdd = moment(cudate).format("YYYY-MM-DD");
          var cddd = moment(alldata.startdate).format("YYYY-MM-DD");
          if (cddd != "Invalid date") {
            if (cddd > cdd) {
              setremember_roster(true);
            }
            if (cdd >= cddd) {
              setisClickedopen_roster(true);
            }
          }
        }
        setcurrentroster(alldata);
      })
      .catch((err) => {});
    getallcalender();
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/admin/getroster", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        const fn = [];
        for (let i = 0; i < alldata.length; i++) {
          fn.push(alldata[i].type);
        }
        setValueget(fn);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    getcheckcoverage();
  }, []);
  const getcheckcoverage = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/api/users/checkusercoverage", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        console.log("kjkkk");
        var checkk = res.data.results;
        if (checkk[0].id !== null) {
          setcheckcoverage(true);
        }
      })
      .catch((err) => {});
  };

  const getallcalender = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    const storedUserSearch = localStorage.getItem("userSearch");
    const userSearch = JSON.parse(storedUserSearch);

    if (userSearch === null) {
      var search = "";
    } else {
      var search = userSearch.search;
    }
    let formdata = {
      user_id: userStr[0].id,
      search: search,
    };
    axios
      .post("https://jlmining.app/admin/getallCalendardetail", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.maindata;
        console.log("u");

        var d = getday();
        const all_data = [];
        for (let i = 0; i < alldata.length; i++) {
          //   console.log(alldata[i]);
          var cudate = new Date();
          var st_date = moment(cudate).format("YYYY-MM-DD");

          var clr = "";
          var id = alldata[i].attend_id;
          var hourstatus = alldata[i].hours_status;

          var signed = "";
          if (hourstatus === "Client") {
            var signed = "Signed";
          }
          if (alldata[i].id == "") {
            var title = "";
            var ic = "";
            var colr = "white";
            var pop = "Open";
            var cudate = new Date();

            var st_date = moment(cudate).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = "";
            var hours = "";
          }
          if (alldata[i].shift === "Day") {
            var title = "DS " + signed;
            var ic = <FaSun />;
            var colr = "#B2DAF2";
            var clr = "black";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "SC") {
            var title = "SC " + signed;
            var ic = "";
            var colr = "#0B5ED7";
            var clr = "black";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Edit") {
            var title = "Edit | " + alldata[i].client_name;
            var ic = <FaEdit />;
            var pop = "Open";
            var colr = "gray";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Add") {
            var title = "Add";
            var ic = "";
            var pop = "Open";
            var colr = "white";
            var clr = "red";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Night") {
            var title = "DN " + signed;
            var ic = <FaMoon />;
            var colr = "#1D0303";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIAM") {
            var title = "FLIAM " + signed;
            var ic = <FaPlaneArrival />;
            var colr = "#B9723D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIPM") {
            var title = "FLIPM " + signed;
            var ic = <FaPlaneArrival />;
            var colr = "#7DBFFC";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOAM") {
            var title = "FLOAM " + signed;
            var ic = <FaPlaneDeparture />;
            var colr = "#9F20AA";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOPM") {
            var title = "FLOPM " + signed;
            var ic = <FaPlaneDeparture />;
            var colr = "#77D928";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Sick Leave") {
            var title = "Sick Leave " + signed;
            var ic = <FaThermometer />;
            var colr = "#395AEF";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "AL") {
            var title = "AL " + signed;
            var ic = <FaUmbrella />;
            var colr = "#F71718";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Offsite") {
            var title = "Work Offsite " + signed;
            var ic = <FaUmbrella />;
            var colr = "#490909";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Leave Without Pay") {
            var title = "Leave Without Pay " + signed;
            var ic = "";
            var colr = "#F3DC0C";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "In Isolation on site") {
            var title = "In Isolation on site " + signed;
            var ic = <FaStar />;
            var colr = "#FB02F5";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Camp") {
            var title = "Work Camp " + signed;
            var ic = <FaStar />;
            var colr = "#F8B70D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }

          let findata = {
            title: title,
            start: start,
            end: end,
            color: clr,
            backgroundColor: colr,
            text: "center",
            icon: ic,
            pop: pop,
            id: id,
            shft: shft,
            hours: hours,
          };

          all_data.push(findata);
        }

        setValuegetCalendar(all_data);
      })
      .catch((err) => {});
  };
  const handle_getClientlocate = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    let formdata = {
      clientId: event.target.value,
      user_id: userStr[0].id,
      datess: setdate_s,
    };
    setDatessvalue(null);
    // console.log("aaa");
    // console.log(formdata);
    setvaluelocationsId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getlocaterostercheck", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.filteredResults;
        setlocatename(alldata);
      })
      .catch((err) => {});
  };
  const handle_getClient = (event) => {
    let formdata = {
      clientId: event.target.value,
    };
    // console.log(formdata);
    setvalueclientId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getclient_check", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        // console.log("clid");
        //console.log(alldata);
        setlocationname(alldata);
        setlocatename([]);
      })
      .catch((err) => {});
  };
  const handleAttend = (event) => {
    var fr = event.target;
    //console.log(event);
    if (currentDateEdit !== "") {
      if (currentDateEdit < setdate) {
        seterror_startend(true);
        return;
      } else {
        seterror_startend(false);
      }
    } else {
      seterror_startend(false);
    }

    const userStr = JSON.parse(localStorage.getItem("users"));
    var sf = fr.shift.value;

    if (fr.shift.value === "") {
      return false;
    }

    let formvl = {
      clientId: valueclientId,
      location: valuelocationsId,
      roster: currentroster.type,
      user_id: userStr[0].id,
      daystart: setdate,
      shift: fr.shift.value,
      startDate: setdate,
      endDate: currentDateEdit,
    };
    //console.log(formvl);
    //return false;

    axios
      .post("https://jlmining.app/admin/attendancesave", formvl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }

        setTimeout(() => {
          setIsModalOpen(false);
          getallcalender();
          //window.location.reload();
          setShowAlert(false);
          setDataclient([]);
          setlocatedname("");
          setcurrentDateEdit("");
          let formdata = {
            user_id: userStr[0].id,
          };
          axios
            .post("https://jlmining.app/admin/getclientforroster", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              //console.log("client idd");
              // console.log(res.data.results);
              setDataclient(res.data.results);
            })
            .catch((err) => {});
        }, 1500);
      })
      .catch((err) => {});
  };

  const handleAttendWeekly = (event) => {
    var fr = event.target;

    const userStr = JSON.parse(localStorage.getItem("users"));
    //console.log(selectedFileticket);
    const formData = new FormData();
    formData.append("user_id", userStr[0].id);
    formData.append("kms", fr.kms.value);
    formData.append("hrs", fr.hrs.value);
    if (selectedFileticket.length > 0) {
      for (let i = 0; i < selectedFileticket.length; i++) {
        formData.append("ticket_file", selectedFileticket[i]);
      }
    }

    if (selectedFileother.length > 0) {
      for (let i = 0; i < selectedFileother.length; i++) {
        formData.append("other_file", selectedFileother[i]);
      }
    }
    //console.log(formData);
    axios
      .post("https://jlmining.app/admin/attendancesaveweekly", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert_week(true);
          setShowAlert_week({ type: "error" });
        } else {
          setShowAlert_week(true);
          setShowAlert_week({ type: "success" });
        }
        event.target.reset();
        setTimeout(() => {
          // window.location.reload();
          setShowAlert_week(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const handleEventStyleGetter = (event, start, end, isSelected) => {
    const top = event.start.getMinutes() + event.start.getHours() * 60;
    const height = event.end.getMinutes() + event.end.getHours() * 60 - top;

    return {
      style: {
        top: `${top}px`,
        height: `${height}px`,
        width: "100%",
        left: "0",
      },
    };
  };
  const handleevt = (event) => {
    setSelectedEvent(event);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log(event);
    console.log("dd");
    console.log(shifts);
    if (event.shft === "Add") {
      if (event.pop === "Open") {
        setIsModalOpen(true);
      }
      setDatessvalue(event.start);
      setDates(event.start);
      const userStr = JSON.parse(localStorage.getItem("users"));

      let formdata = {
        checkdate: event.start,
        user_id: userStr[0].id,
      };

      setTimeout(() => {
        let fdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
        };
        if (event.title === "Add") {
          //console.log(fdata);
          axios
            .post("https://jlmining.app/admin/getforEmp_client", fdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;
              //console.log("add");
              //console.log(alldata);
              if (alldata.length > 0) {
                setValueroster(alldata);
                // setlocatename([]);
              }
            })
            .catch((err) => {});
        } else {
          axios
            .post("https://jlmining.app/admin/getallrosterlimit", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.dataa;

              if (alldata.length > 0) {
                setvalueclient(alldata[0].name);
                setvalueclientId(alldata[0].client_id);
                //setValueroster(alldata);
                let fdata = {
                  checkdate: event.start,
                  user_id: userStr[0].id,
                  client_id: alldata[0].client_id,
                };
                axios
                  .post("https://jlmining.app/admin/getlocation_check", fdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.row;
                    // console.log("gttt");
                    // console.log(alldata);
                    if (alldata.length > 0) {
                      // setlocationname(alldata);
                      setvaluelocations(alldata[0].location_name);
                      setvaluelocationsId(alldata[0].location_id);
                      // setlocatename([]);
                    }
                  })
                  .catch((err) => {});
                axios
                  .post("https://jlmining.app/admin/getroster", formdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.results;
                    //console.log("getroster");
                    //console.log(res.data.results);
                    const fn = [];
                    for (let i = 0; i < alldata.length; i++) {
                      fn.push(alldata[i].type);
                    }
                    setValueget(fn);
                  })
                  .catch((err) => {});
              }
            });
        }
        setDataclient([]);
        setlocatedname("");
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
        if (event.title === "Add") {
          setisClickedaddorEdit(false);
          setisClickedadd(true);
        } else {
          setisClickedaddorEdit(true);
          setisClickedadd(false);
        }
      }, 1000);
    } else {
      //console.log(shifts);
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        const userStr = JSON.parse(localStorage.getItem("users"));
        let formdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
          shift: shifts,
        };
        axios
          .post("https://jlmining.app/admin/multiplerosteradd", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("getallcalender");
            getallcalender();
          })
          .catch((err) => {});
        setlocatedname("");
        setDataclient([]);
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
      }
    }
  };
  const CustomEvent1 = ({ event }) => (
    <div
      style={{
        padding: "4px 1px",
        backgroundColor:
          event.title === "Add"
            ? "red"
            : event.title === "Edit"
            ? "gray"
            : event.title === "DN"
            ? "#1D0303"
            : event.title === "FLIAM"
            ? "#B9723D"
            : event.title === "FLIPM"
            ? "#7DBFFC"
            : event.title === "FLOAM"
            ? "#9F20AA"
            : event.title === "FLOPM"
            ? "#77D928"
            : event.title === "Sick Leave"
            ? "#395AEF"
            : event.title === "AL"
            ? "#F71718"
            : event.title === "Work Offsite"
            ? "#490909"
            : event.title === "Leave Without Pay"
            ? "#F3DC0C"
            : event.title === "In Isolation on site"
            ? "#FB02F5"
            : event.title === "Work Camp"
            ? "#F8B70D"
            : event.backgroundColor,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          fontSize: "13px",
          padding: "4px 1px",
          backgroundColor:
            event.title === "Add"
              ? "red"
              : event.title === "Edit"
              ? "gray"
              : event.title === "DN"
              ? "#1D0303"
              : event.title === "FLIAM"
              ? "#B9723D"
              : event.title === "FLIPM"
              ? "#7DBFFC"
              : event.title === "FLOAM"
              ? "#9F20AA"
              : event.title === "FLOPM"
              ? "#77D928"
              : event.title === "Sick Leave"
              ? "#395AEF"
              : event.title === "AL"
              ? "#F71718"
              : event.title === "Work Offsite"
              ? "#490909"
              : event.title === "Leave Without Pay"
              ? "#F3DC0C"
              : event.title === "In Isolation on site"
              ? "#FB02F5"
              : event.title === "Work Camp"
              ? "#F8B70D"
              : event.backgroundColor,
        }}
        onClick={() => handleevt(event)}
      >
        {event.icon}
        {event.title}
      </div>
      {event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined ? (
        <div>
          <select
            className="w-100 mt-2"
            value={event.hours}
            onChange={(e) =>
              handlesubmithours(event.start, e.target.value, event.shft)
            }
          >
            <option value={""}>Choose Hours</option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </div>
  );

  const CustomEvent2 = ({ event }) => <div></div>;
  const EventComponent = ({ event }) => {
    //console.log(event);
    if (event.id !== "") {
      return <CustomEvent1 event={event} />;
    } else if (
      event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined
    ) {
      return <CustomEvent2 event={event} />;
    }

    return null;
  };
  const handlesubmithours = (event, e, s) => {
    //if (e != "") {
    //setshifts("");
    if (e == "") {
      var e = null;
    }
    setIsClickedloader(true);
    setinputvalue_data(e);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formvl = {
      date: event,
      value: e,
      shift: s,
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/calenderhoursadd", formvl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsModalOpen_hrs(true);
        setTimeout(() => {
          getcheckcoverage();
          getallcalender();
          setIsClickedloader(false);
          setIsModalOpen_hrs(false);
        }, 1000);
      })
      .catch((err) => {});
    // }
  };
  const handle_getIcon = (event, bg, shift) => {
    setBackgroundColor(bg);
    //setcolorActive(bg);
    setIcon(event);
    setshifts(shift);
    setshiftmessage("");
    setisClickedopen(true);
    if (event === "SC") {
      setsctext("SC");
    } else {
      setsctext("");
    }
  };
  const iconClassName = `weather-top-icon active ${colorActive}`;
  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.backgroundColor, // Use the color property from the event
        border: `2px solid ${event.backgroundColor}`,
        color: event.color,
        borderRadius: "0px",
        opacity: 0.8,
        display: "block",
      },
    };
  };
  const fname = valuegetUser.first_name;
  const mname = valuegetUser.middle_name;
  const lname = valuegetUser.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  const [inputValue, setInputValue] = useState("");
  const [inputValues, setInputValues] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    // console.log("check");
    // console.log(value);
    // Validate the input to allow only integer numbers
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };
  const handleInputChangehrs = (event) => {
    const value = event.target.value;

    // Validate the input to allow only integer numbers
    if (/^\d*$/.test(value)) {
      setInputValues(value);
    }
  };

  //Roster Page
  const checkcondition = () => {
    if (clientID === "") {
      setclientreq(true);
      return false;
    }
    if (locationID === "") {
      setlocatreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const deleterotser = (id) => {
    var id = id;

    let roster = {
      id: id,
    };
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "#/login";
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    const del = window.confirm("Are you sure you want to delete this roster?");
    if (del) {
      axios
        .post("https://jlmining.app/admin/deleteroster", roster, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          axios
            .post("https://jlmining.app/admin/getallrosters", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;

              setShowAlert(true);
              setShowAlert({ type: "errordelete" });
              setTimeout(() => {
                setShowAlert(false);
              }, 4500);
              window.location.reload();
              setValuegetallroster(alldata);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  };
  const handlechooseRostersubmit = (event) => {
    if (clientID === "") {
      setclientreq(true);
      return false;
    }
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (locationID === "") {
      setclientreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setclientreq(false);
    setlocatreq(false);
    var vl = event.target;
    let currentDate = new Date(vl.date.value);

    // Subtract one day
    //currentDate.setDate(currentDate.getDate() - 1);

    // Format the new date as YYYY-MM-DD
    let formattedDate = currentDate.toISOString().split("T")[0];
    let formdata = {
      type: selectedOption,
      date: formattedDate,
      locationId: locationID,
      clientID: clientID,
      user_id: userStr[0].id,
    };
    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
    let formdsata = {
      user_id: userStr[0].id,
    };
    setIsConditionMet(true);
    setIsConditionMetbtn("Don't refresh the page, Please wait...");
    axios
      .post("https://jlmining.app/admin/setRoster", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
          setIsConditionMet(false);
          setIsConditionMetbtn("Submit");
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .post("https://jlmining.app/admin/addmissingDay", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {})
            .catch((err) => {});
          axios
            .post("https://jlmining.app/admin/getallrosters", formdsata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;

              setValuegetallroster(alldata);
              setTimeout(() => {
                setShowAlert(false);
                window.location.reload();
              }, 6500);
            })
            .catch((err) => {});
        }
        if (res.data.status == "3") {
          setShowAlert(true);
          setShowAlert({ type: "errorroster" });
          setTimeout(() => {
            setShowAlert(false);
          }, 5500);
        }
        axios
          .post("https://jlmining.app/admin/getallrosters", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            var alldata = res.data.results;

            setValuegetallroster(alldata);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const handlegetmnesites = (clientId, index) => {
    setActiveIndex(index);
    setActivesIndex("");
    setClientId(clientId);
    let formdata = {
      clientId: clientId,
    };
    axios
      .post("https://jlmining.app/admin/getminesites", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data.results);
        getData(res.data.results);
        setIsClicked(true);
        setMinClicked(false);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getclientinfo", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data.row);
        if (res.data.row != "") {
          setclientInfo(res.data.row[0]);
        }
      })
      .catch((err) => {});
  };
  const handlegetmoinesites = (locationId, index) => {
    var locationId = locationId;
    // console.log(locationId);
    setActivesIndex(index);
    setlocatid(locationId);
    setMinClicked(true);
    setLocationId(locationId);
    setIsClickedloc(true);
    let location_Id = {
      locationId: locationId,
    };
    axios
      .post("https://jlmining.app/admin/getlocationr", location_Id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data.ss);
        if (res.data.ss != "") {
          setlocated(res.data.ss);
        }
      })
      .catch((err) => {});
  };

  const kmscheck = (e) => {
    if (e.target.value != "") {
      setIsRequiredfile(true);
      setkmsvalue(e.target.value);
      setIsRequiredhrs(true);
    } else {
      setIsRequiredfile(false);
      setkmsvalue("");
      setIsRequiredhrs(false);
    }

    if (
      e.target.value !== "" &&
      hrsvalue !== "" &&
      selectedFileticket !== null
    ) {
      setisbtndisabed(false);
    } else {
      setisbtndisabed(true);
    }
  };
  const hrscheck = (e) => {
    if (e.target.value !== "") {
      sethrsvalue(e.target.value);
    } else {
      sethrsvalue("");
    }
    if (
      e.target.value !== "" &&
      kmsvalue !== "" &&
      selectedFileticket !== null
    ) {
      setisbtndisabed(false);
    } else {
      setisbtndisabed(true);
    }
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);

    // Check if the pressed key is not a digit
    if (/\D/.test(keyValue)) {
      e.preventDefault(); // Prevent the keypress if it's not a digit
    }
  };
  const handleclientcheck = (e) => {
    // console.log(e.target.value);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fromdata = {
      user_id: userStr[0].id,
      client_id: e.target.value,
    };
    axios
      .post("https://jlmining.app/admin/getlocationcalender", fromdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row != "") {
          setlocatedname(res.data.row[0].location_name);
        }
      })
      .catch((err) => {});
  };
  const deletecurrentroster = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fromdata = {
      user_id: userStr[0].id,
    };
    var conf = window.confirm("Are you sure you to delete this roster?");
    if (conf) {
      axios
        .post("https://jlmining.app/admin/deletecurrentroster", fromdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {});
    }
  };

  const [startDateSC, setStartDateSC] = useState("");
  const [endDateSC, setEndDateSC] = useState("");
  const [errorSC, setErrorSC] = useState("");
  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDateSC(value);
    validateDates(value, endDateSC);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDateSC(value);
    validateDates(startDateSC, value);
  };

  const validateDates = (start, end) => {
    if (start && end && new Date(start) > new Date(end)) {
      setErrorSC("Start Date cannot be later than End Date.");
    } else {
      setErrorSC("");
    }
  };

  const handleSubmitSC = (e) => {
    e.preventDefault();
    if (!errorSC) {
      // Perform form submission logic
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        setspinners(true);
        const userStr = JSON.parse(localStorage.getItem("users"));
        let fromdata = {
          start_date: startDateSC,
          end_date: endDateSC,
          shift: shifts,
          user_id: userStr[0].id,
        };
        axios
          .post("https://jlmining.app/admin/calendershiftupdate", fromdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res);
            var er = res.data;
            if (res.data.status === "0") {
              var d = er.missing_dates.join(", ");
              var ms = er.message + " " + d;
              setmissingdates(ms);
            } else {
              setmissingdatessuccess(er.message);

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          })
          .catch((err) => {});
        console.log("Form submitted:", { startDateSC, endDateSC });
      }
    } else {
      console.log("Fix errors before submitting.");
    }
  };

  const handleEndDateChangeEdit = (e) => {
    const value = e.target.value;
    setcurrentDateEdit(value);
  };

  //Roster Page
  return (
    <>
      <HeaderHome />
      {opencloseroster && (
        <section className="profile-box text-center d-block pb-5 ">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-2">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column gap-2 text-center pic-emp">
                    <div className="emplo-box-pic ofit mx-auto">
                      {/* <img src={imageUrl} alt="image" /> */}
                    </div>
                    <div className="d-flex flex-column">
                      {/* <h3 className="fs-26 text-black fw-800">
                      {valuegetUser.first_name} {valuegetUser.middle_name}{" "}
                      {valuegetUser.last_name}
                    </h3>
                    <h4 className="fs-18 text-black">{valuegetUser.role}</h4> */}
                    </div>
                  </div>
                  <div className="d-block red-heading bg-white shadow py-4 mt-auto">
                    {clientreq && (
                      <Alert
                        type="error"
                        description="This field is required"
                        closable
                      />
                    )}
                    <h3 className="mb-3">Select Client</h3>
                    <div className="over-emplcc">
                      <div className="d-flex flex-column h-100 clientselectmain">
                        {Array.isArray(emData) ? (
                          emData.map((item, index) => (
                            <Link
                              to="javascript:void(0)"
                              key={index}
                              className={`item ${
                                activeIndex === index ? "active" : ""
                              }`}
                              onClick={() => handlegetmnesites(item.id, index)}
                            >
                              {item.name}
                            </Link>
                          ))
                        ) : (
                          <Link>No data available</Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="d-flex flex-column h-100">
                  <div className="d-block red-heading bg-white shadow py-4 mt-auto">
                    {locatreq && (
                      <Alert
                        type="error"
                        description="This field is required"
                        closable
                      />
                    )}
                    <h3 className="mb-3">Mine Sites</h3>
                    <div className="over-emplcc">
                      <div className="d-flex flex-column h-100 minestone">
                        {Array.isArray(gdata) ? (
                          gdata.map((item, index) => (
                            <Link
                              to="javascript:void(0)"
                              key={index}
                              className={`item ${
                                activesIndex === index ? "active" : ""
                              }`}
                              onClick={() =>
                                handlegetmoinesites(item.id, index)
                              }
                            >
                              {item.location_name}
                            </Link>
                          ))
                        ) : (
                          <Link>No data available</Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isClicked && (
                <div className="col-md-4 mt-4">
                  <div className="d-flex flex-column gap-2 text-center pic-emp">
                    <div className="emplo-box-pic ofit mx-auto"></div>
                    <div className="d-flex flex-column"></div>
                  </div>
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-8">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Client Name:-</label>
                          <span className="fs-14">{clientinfo.name}</span>
                        </div>

                        <div className="d-flex justify-content-between p-2">
                          <label for="">Position:-</label>
                          <span className="fs-14">{clientinfo.position}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Department:-</label>
                          <span className="fs-14">{clientinfo.department}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Work Phone Number:-</label>
                          <span className="fs-14">
                            {clientinfo.phone_number}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Mobile Phone Number:-</label>
                          <span className="fs-14">
                            {clientinfo.mobile_number}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Home Phone Number:-</label>
                          <span className="fs-14">
                            {clientinfo.home_phone_number}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Fax Number:-</label>
                          <span className="fs-14">{clientinfo.fax_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Email-Id:-</label>
                          <span className="fs-14">{clientinfo.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isClickedloc && (
                <div className="col-md-4 mt-4">
                  <div className="d-flex flex-column gap-2 text-center pic-emp">
                    <div className="emplo-box-pic ofit mx-auto"></div>
                    <div className="d-flex flex-column"></div>
                  </div>
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-8">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Location Name:-</label>
                          <span className="fs-14">{located.location_name}</span>
                        </div>

                        <div className="d-flex justify-content-between p-2">
                          <label for="">Nearest Town:-</label>
                          <span className="fs-14">{located.nearest_town}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Commodity:-</label>
                          <span className="fs-14">{located.commodity}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Contract Type:-</label>
                          <span className="fs-14">{located.contract_type}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Duration Start:-</label>
                          <span className="fs-14">
                            {located.duration_start}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Duration End:-</label>
                          <span className="fs-14">{located.duration_end}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Scope:-</label>
                          <span className="fs-14">{located.scope}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row gy-4 mt-4">
              <div className="col-md-6 m-auto">
                <div className="d-flex flex-column h-100 ">
                  <form
                    className="mt-auto"
                    action="javascript:void(0)"
                    onSubmit={handlechooseRostersubmit}
                    method="post"
                  >
                    {showAlert?.type === "error" && (
                      <Alert
                        type="error"
                        description="You have no permission to add this roster!"
                        closable
                      />
                    )}
                    {showAlert?.type === "errorroster" && (
                      <Alert
                        type="error"
                        description="You have no permission to add this roster,because your location duration date is expire!."
                        closable
                      />
                    )}
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="Roster has been successfully created!"
                        closable
                      />
                    )}
                    <div className="d-block red-heading bg-white shadow py-4">
                      <h3 className="mb-3">Remove Rosters</h3>
                      <div className="d-block num-block-box">
                        <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center">
                          <div className="d-flex flex-column gap-3">
                            {getallroster.length === 0 ? (
                              <div className="d-flex px-3 gap-4 align-items-center">
                                No data available
                              </div>
                            ) : (
                              getallroster.map((item, index) => (
                                <div
                                  className="d-flex px-3 gap-4 align-items-center"
                                  key={item.id}
                                >
                                  <div>
                                    <h6>({item.type})</h6>
                                  </div>
                                  <div className="">
                                    <button
                                      className="border-0 bg-danger text-white py-1 px-2 fs-14"
                                      onClick={() => deleterotser(item.id)}
                                      type="button"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      {rosterreq && (
                        <Alert
                          type="error"
                          description="This field is required"
                          closable
                        />
                      )}
                      <h3 className="mb-3 mt-3">Select Rosters</h3>
                      <div className="d-block num-block-box">
                        <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center">
                          <div className="innerradio">
                            <label for="1">
                              <input
                                id="1"
                                required
                                name="selroaster"
                                checked={selectedOption === "2/2"}
                                onChange={handleOptionChange}
                                type="radio"
                                value="2/2"
                              />
                              <p> 2/2</p>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="2">
                              <input
                                id="2"
                                checked={selectedOption === "3/1"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="3/1"
                              />
                              <p> 3/1</p>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="3">
                              <input
                                id="3"
                                checked={selectedOption === "8/6"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="8/6"
                              />
                              <p> 8/6 </p>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="4">
                              <input
                                id="4"
                                checked={selectedOption === "2/1"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="2/1"
                              />
                              <p> 2/1 </p>
                              <span class="checkmark"></span>{" "}
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="5">
                              <input
                                id="5"
                                checked={selectedOption === "15/13"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="15/13"
                              />
                              <p> 15/13</p>
                              <span class="checkmark"></span>{" "}
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="6">
                              <input
                                id="6"
                                checked={selectedOption === "7/7"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="7/7"
                              />
                              <p> 7/7</p>
                              <span class="checkmark"></span>{" "}
                            </label>
                          </div>
                          <div className="innerradio">
                            <label for="7">
                              <input
                                id="7"
                                checked={selectedOption === "5/2"}
                                onChange={handleOptionChange}
                                name="selroaster"
                                type="radio"
                                value="5/2"
                              />
                              <p> 5/2 </p>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div class="form-box formbox">
                        <label className="text-left mb-2">Choose Date</label>
                        <input type="date" name="date" required />
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          disabled={isConditionMet}
                          onClick={() => checkcondition()}
                          className="submit-btn"
                        >
                          {isConditionMetbtn}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {openclosecalender && (
        <section className="text-center py-3 py-md-5 d-block mt-md-5 mb-5">
          <Modal
            isOpen={isModalOpen_hrs}
            onRequestClose={() => setIsModalOpen_hrs(false)}
            contentLabel="Add Text Modal"
            className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
          >
            {" "}
            <h3 className="text-success text-center fs-5">
              Your hour has been submited
            </h3>
          </Modal>
          <div className="d-block pt-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="d-flex flex-column flex-md-row h-100 gap-3 justify-content-md-center mb-4">
                  <span>
                    You are working in{" "}
                    <strong>
                      {currentroster?.client_name} / {currentroster?.lname}
                    </strong>
                  </span>
                </div>
                <div className="col-md-9 col-lg-6 num-block-box">
                  <div className="d-flex flex-column flex-md-row h-100 gap-3 justify-content-md-center">
                    {checkcoverage && (
                      <div className="flex-grow-1">
                        <select
                          className="form-control w-100 float-start text-center text-md-start"
                          onClick={(e) => handleclientcheck(e)}
                        >
                          <option value="">Select Client</option>
                          {Array.isArray(emDataclient) ? (
                            emDataclient.map((item, index) => (
                              <option value={item.id}>{item.name}</option>
                            ))
                          ) : (
                            <option>No data available</option>
                          )}
                        </select>
                      </div>
                    )}
                    {checkcoverage && (
                      <div className="flex-grow-1">
                        <input
                          type="text"
                          className="form-control text-center text-md-start"
                          value={locatedname}
                          disabled
                          placeholder="Mine Site"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3 justify-content-center">
                <div className="col-md-9 col-lg-6">
                  <div className="d-block red-heading bg-white shadow py-4">
                    <h3 className="mb-3" ref={targetDivRef}>
                      Select Rosters
                    </h3>
                    <div className="d-block num-block-box">
                      <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center mb-2">
                        <div className="innerradio">
                          <label for="1">
                            <input
                              id="1"
                              disabled={!valueget.includes("2/2")}
                              checked={valueget.includes("2/2")}
                              name="selroaster"
                              type="checkbox"
                              value="2/2"
                            />
                            <p> 2/2</p>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="2">
                            <input
                              id="2"
                              disabled={!valueget.includes("3/1")}
                              checked={valueget.includes("3/1")}
                              name="selroaster"
                              type="checkbox"
                              value="3/1"
                            />
                            <p> 3/1</p>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="3">
                            <input
                              id="3"
                              disabled={!valueget.includes("8/6")}
                              checked={valueget.includes("8/6")}
                              name="selroaster"
                              type="checkbox"
                              value="8/6"
                            />
                            <p> 8/6 </p>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="4">
                            <input
                              id="4"
                              disabled={!valueget.includes("2/1")}
                              checked={valueget.includes("2/1")}
                              name="selroaster"
                              type="checkbox"
                              value="2/1"
                            />
                            <p> 2/1 </p>
                            <span className="checkmark"></span>{" "}
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="5">
                            <input
                              id="5"
                              disabled={!valueget.includes("15/13")}
                              checked={valueget.includes("15/13")}
                              name="selroaster"
                              type="checkbox"
                              value="15/13"
                            />
                            <p> 15/13</p>
                            <span className="checkmark"></span>{" "}
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="6">
                            <input
                              id="6"
                              disabled={!valueget.includes("7/7")}
                              checked={valueget.includes("7/7")}
                              name="selroaster"
                              type="radio"
                              value="7/7"
                            />
                            <p> 7/7</p>
                            <span className="checkmark"></span>{" "}
                          </label>
                        </div>
                        <div className="innerradio">
                          <label for="7">
                            <input
                              id="7"
                              disabled={!valueget.includes("5/2")}
                              checked={valueget.includes("5/2")}
                              name="selroaster"
                              type="radio"
                              value="5/2"
                            />
                            <p> 5/2</p>
                            <span className="checkmark"></span>{" "}
                          </label>
                        </div>
                      </div>
                      {/* weather icon top */}
                      {isClickedopen && (
                        <div className="d-flex gap-2 justify-content-center my-3">
                          <Link
                            to=""
                            className={iconClassName}
                            style={{ backgroundColor }}
                          >
                            <i className={iconset}>{sctext}</i>
                          </Link>
                        </div>
                      )}
                      {isClickedopen_roster && (
                        <div className="d-flex gap-2 justify-content-center my-3">
                          <b>Your Current roster is</b>{" "}
                          <span className="currentroster">
                            {currentroster.type}
                          </span>
                        </div>
                      )}
                      {remember_roster && (
                        <div className="d-flex gap-2 justify-content-center my-3">
                          <b>Your roster start from</b>{" "}
                          <span className="currentrosters">
                            {moment(currentroster.startdate).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </div>
                      )}
                      {/* weather icons bottom */}

                      <div className="d-block px-4">
                        <div className="row gy-3 list-weather align-items-center">
                          <div className="col-6 col-md-3">
                            <Link
                              className="d-flex align-items-center gap-2"
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "fas fa-umbrella-beach",
                                  "#F71718",
                                  "AL"
                                )
                              }
                            >
                              <div className="flex-shrink-0 icon-user-we">
                                <i className="fas fa-umbrella-beach"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Annual Leave </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-treasure-chest",
                                  "#F8B70D",
                                  "Work Camp"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we bg-warning">
                                <i className="far fa-treasure-chest"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Workers Comp </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "fas fa-plane-arrival",
                                  "#7DBFFC",
                                  "FLIPM"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we bgfip">
                                <i className="fas fa-plane-arrival"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Fly in PM </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-plane-departure",
                                  "#9F20AA",
                                  "FLOAM"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we foa">
                                <i className="far fa-plane-departure"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Fly out AM </h5>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="row gy-3 list-weather align-items-center mt-4">
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-thermometer",
                                  "#395AEF",
                                  "Sick Leave"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we sl">
                                <i className="far fa-thermometer"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Sick Leave </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-sun-cloud",
                                  "#B2DAF2",
                                  "Day"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we ds">
                                <i className="far fa-sun-cloud text-dark"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Day's Worked, dayshift </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-plane-arrival",
                                  "#B9723D",
                                  "FLIAM"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we fia">
                                <i className="far fa-plane-arrival"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Fly in AM </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-chimney",
                                  "#FB02F5",
                                  "In Isolation on site"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we iso">
                                <i className="far fa-chimney"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>In Isolation on site </h5>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="row gy-3 list-weather align-items-center mt-4">
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "fas fa-map-marker-times",
                                  "#F3DC0C",
                                  "Leave Without Pay"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we  lwp">
                                <i className="fas fa-map-marker-times"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Leave Without Pay </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "far fa-moon-cloud",
                                  "#1D0303",
                                  "Night"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we ns">
                                <i className="far fa-moon-cloud"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Day's Worked, NightShift </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "fas fa-plane-departure",
                                  "#77D928",
                                  "FLOPM"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we fop">
                                <i className="fas fa-plane-departure"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Fly out PM </h5>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon(
                                  "fal fa-digging",
                                  "#490909",
                                  "Work Offsite"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we wo">
                                <i className="fal fa-digging"></i>
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>Work Offsite </h5>
                              </div>
                            </Link>
                          </div>

                          <div className="col-6 col-md-3">
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                handle_getIcon("SC", "#0B5ED7", "SC")
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <div className="flex-shrink-0 icon-user-we sc">
                                SC
                              </div>
                              <div className="list-we-name">
                                {" "}
                                <h5>SC </h5>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <form onSubmit={handleSubmitSC}>
                          {/* <div className="row mt-4">

                            <div className="col-md-6">
                              <label className="mb-2 float-start font-weight-bold">Start Date</label>
                              <input
                                type="date"
                                name="start_date_shift"
                                required
                                value={startDateSC}
                                onChange={handleStartDateChange}
                                className="form-control mb-2"
                              />
                            </div>


                            <div className="col-md-6">
                              <label className="mb-2 float-start font-weight-bold">End Date</label>
                              <input
                                type="date"
                                name="end_date_shift"
                                required
                                value={endDateSC}
                                onChange={handleEndDateChange}
                                className="form-control mb-2"
                              />
                            </div>
                          </div> */}

                          {/* Error Message */}
                          {errorSC && (
                            <p className="text-danger mt-2">{errorSC}</p>
                          )}

                          {/* Submit Button */}
                          <div className="row mt-3">
                            {spinners && (
                              <div
                                class="spinner-border text-success"
                                role="status"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            )}
                          </div>
                          <span className="text-danger">{missingdates}</span>
                          <span className="text-success">
                            {missingdatessuccess}
                          </span>
                        </form>
                      </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center my-3 text-danger">
                      <b>{shiftmessagevalue}</b>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* section */}
          <div className="d-block mt-5">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="mb-3 mb-md-0 float-md-end">
                  {isClickedloader && (
                    <img
                      className="imgsrc"
                      src={require("./assets/images/loading-gif.gif")}
                      alt="logo"
                    />
                  )}

                  <button
                    type="submit"
                    className="refreshbutton"
                    onClick={() => handleget_refresh()}
                    title="Refresh Calendar"
                  >
                    <FaSync />
                  </button>
                  <button
                    type="button"
                    className="text-danger deletebtn"
                    onClick={deletecurrentroster}
                    title="Refresh Calendar"
                  >
                    <FaTrashAlt />
                  </button>
                </div>

                <div
                  className="hide-day-week-agenda-buttons"
                  style={{
                    color: "black",
                  }}
                >
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    views={["month"]}
                    components={{
                      event: EventComponent,
                    }}
                    style={{ height: 500 }}
                  />
                  <hr
                    style={{
                      border: "2px solid #000",
                      margin: "30px 0",
                      opacity: 1,
                    }}
                  />
                  <h5>Enter Travel Hours</h5>
                  <form
                    action="javascript:void(0)"
                    onSubmit={handleAttendWeekly}
                    method="post"
                    className="mt-4"
                  >
                    <div className="d-block px-3">
                      <div class="row gy-4">
                        <div class="col-md-4">
                          <label className="mb-2 float-start">
                            Enter Traveling KMS{" "}
                          </label>

                          <input
                            onKeyPress={(e) => handleKeyPress(e)}
                            onInput={kmscheck}
                            type="text"
                            name="kms"
                            placeholder="Enter KMS"
                            className="form-control mb-2"
                          />
                        </div>

                        <div class="col-md-4">
                          <label className="mb-2 float-start">
                            Enter Traveling Hours{" "}
                          </label>

                          <input
                            onKeyPress={(e) => handleKeyPress(e)}
                            type="text"
                            name="hrs"
                            onInput={hrscheck}
                            required={isRequiredhrs}
                            placeholder="Enter Hours"
                            className="form-control mb-2"
                          />
                        </div>
                        <div class="col-md-4">
                          <label className="mb-2 float-start">
                            Upload Receipt's{" "}
                          </label>

                          <input
                            type="file"
                            name="ticket_file"
                            multiple
                            accept="image/*"
                            required={isRequiredfile}
                            onChange={handleFileChangeticket}
                            className="form-control mb-2"
                          />
                        </div>
                        <div class="col-md-4 mt-3">
                          <label className="mb-2 float-start">
                            Other Expenses
                          </label>

                          <input
                            type="file"
                            name="other_file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChangeother}
                            className="form-control mb-2"
                          />
                        </div>
                        <div class="col-md-4">
                          {showAlert_week?.type === "success" && (
                            <Alert
                              type="success"
                              description="Your data has been successfully saved in last week!"
                              closable
                            />
                          )}
                          {showAlert_week?.type === "error" && (
                            <Alert
                              type="error"
                              description="There was an error saving your data for the previous week. Please ensure the current week's date is selected and try again."
                              closable
                            />
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-success"
                        disabled={isbtndisabed}
                        type="submit"
                      >
                        Submit Travel Hours
                      </button>
                    </div>
                  </form>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Add Text Modal"
                    className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                  >
                    {" "}
                    <form
                      action="javascript:void(0)"
                      onSubmit={handleAttend}
                      method="post"
                    >
                      {showAlert?.type === "error" && (
                        <Alert
                          type="error"
                          description="Already Exist"
                          closable
                        />
                      )}
                      {showAlert?.type === "success" && (
                        <Alert
                          type="success"
                          description="Successfully created!"
                          closable
                        />
                      )}
                      <h4 className="mb-2"> Choose Roster For Attendance </h4>
                      {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                      <div class="row">
                        <div class="col-md-6">
                          <label className="mb-2">Client Name</label>
                          {isClickededit && (
                            <input
                              type="text"
                              name="clientId"
                              required
                              disabled
                              value={valueclient}
                              className="form-control mb-2"
                            />
                          )}
                          {isClickedadd && (
                            <select
                              className="form-control mb-2"
                              required
                              onChange={handle_getClient}
                              name="client_Id"
                            >
                              <option value={""}>Select value</option>
                              {Array.isArray(valueroster) ? (
                                valueroster.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))
                              ) : (
                                <option value=""></option>
                              )}
                            </select>
                          )}
                        </div>
                        <div class="col-md-6">
                          <label className="mb-2">Mine Site</label>
                          {isClickededit && (
                            <input
                              type="text"
                              name="location"
                              required
                              disabled
                              value={valuelocations}
                              className="form-control mb-2"
                            />
                          )}
                          {isClickedadd && (
                            <select
                              className="form-control mb-2"
                              required
                              onChange={handle_getClientlocate}
                              name="location_id"
                            >
                              <option value={""}>Select value</option>
                              {Array.isArray(locationname) ? (
                                locationname.map((item, index) => (
                                  <option value={item.id}>
                                    {item.location_name}
                                  </option>
                                ))
                              ) : (
                                <option value=""></option>
                              )}
                            </select>
                          )}
                        </div>
                        <div class="col-md-6">
                          <label className="mb-2">Roster</label>

                          <input
                            type="text"
                            name="roster"
                            required
                            disabled
                            value={valueget}
                            className="form-control mb-2"
                          />
                        </div>
                        <div class="col-md-6">
                          <label className="mb-2">Shift</label>
                          <select
                            name="shift"
                            required
                            className="form-control mb-2"
                          >
                            <option value="">--Select--</option>
                            <option value="AL">Annual Leave</option>
                            <option value="Work Camp">Work Camp</option>
                            <option value="FLIPM">Fly in PM</option>
                            <option value="FLOAM">Fly out AM</option>
                            <option value="Sick Leave">Sick Leave</option>
                            <option value="Day">Day's Worked, dayshift</option>
                            <option value="FLIAM">Fly in AM </option>
                            <option value="In Isolation on site">
                              In Isolation on site
                            </option>
                            <option value="Leave Without Pay">
                              Leave Without Pay{" "}
                            </option>
                            <option value="Night">
                              Day's Worked, NightShift
                            </option>
                            <option value="FLOPM">Fly out PM </option>
                            <option value="Work Offsite">Work Offsite</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <label className="mb-2">Start Date</label>
                          <input
                            type="date"
                            value={setdate_s}
                            disabled
                            className="form-control mb-2"
                          />
                        </div>
                        <div className="">
                          <label className="mb-2">End Date</label>
                          <input
                            type="date"
                            name="end_date"
                            value={currentDateEdit}
                            onChange={handleEndDateChangeEdit}
                            className="form-control mb-2"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        {error_startend && (
                          <span className="text-danger">
                            Start date cannot be later than the end date
                          </span>
                        )}
                        <button className="btn btn-success" type="submit">
                          Save
                        </button>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          {/* section */}
        </section>
      )}
      <Footer />
    </>
  );
};
