import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import {
//   HashRouter as Router,
//   BrowserRouter,
//   Routes,
//   Route,
// } from "react-router-dom";
//import logo from "./logo.svg";
import "./App.css";
import { Login } from "./Login";
import { Register } from "./Register";
import { Landingpage } from "./Landingpage";
import { Syn } from "./Syn";
import { Home } from "./Home";
import { Roster } from "./Roster";
import { Profile } from "./Profile";
import { Logout } from "./Logout";
import { Cl } from "./Cl";
import { Notificationforuser } from "./Notificationforuser";

import { Currentroster } from "./Currentroster";
import { Timesheet } from "./Timesheet";
import { Forgotpassword } from "./Forgotpassword";
import { Clientinfo } from "./Clientinfo";
import { Contact } from "./Contact";
import { Experthelp } from "./Experthelp";
import { Privacypolicy } from "./Privacypolicy";
import { Homenew } from "./Homenew";
import { Checkpdf } from "./Checkpdf";
import { Checkpdfpanada } from "./Checkpdfpanada";
import { Termscondition } from "./Termscondition";
import { AdminLogin } from "./Admin/AdminLogin";
import { AdminNotification } from "./Admin/AdminNotification";
import { AdminClient } from "./Admin/AdminClient";
import { AdminContractfiles } from "./Admin/AdminContractfiles";
import { AdminContractfolder } from "./Admin/AdminContractfolder";
import { AdminAddclient } from "./Admin/AdminAddclient";
import { AdminEmployee } from "./Admin/AdminEmployee";
import { AdminAddemployee } from "./Admin/AdminAddemployee";
import { AdminLocation } from "./Admin/AdminLocation";
import { AdminAddlocation } from "./Admin/AdminAddlocation";
import { AdminCalendar } from "./Admin/AdminCalendar";
import { AdminTimesheet } from "./Admin/AdminTimesheet";
import { AdminAddskill } from "./Admin/AdminAddskill";
import { AdminLogout } from "./Admin/AdminLogout";
import { EmployeeResume } from "./Admin/EmployeeResume";
import { JlCertificate } from "./Admin/JlCertificate";
import { EmployeeResumePdf } from "./Admin/EmployeeResumePdf";

import { AdminAddlicence } from "./Admin/AdminAddlicence";
import { AdminAddtrade } from "./Admin/AdminAddtrade";
import { AdminAddvocationaltraining } from "./Admin/AdminAddvocationaltraining";
import { AdminAddEquipmentwork } from "./Admin/AdminAddEquipmentwork";
import { AdminAddmachinery } from "./Admin/AdminAddmachinery";
import { AdminAddmentioncertificate } from "./Admin/AdminAddmentioncertificate";
import { AdminAddreference } from "./Admin/AdminAddreference";
import { AdminAddPreviouswork } from "./Admin/AdminAddPreviouswork";
import { AdminOnboardingdocument } from "./Admin/onboarding/AdminOnboardingdocument";
import { AdminOnboardingfiles } from "./Admin/onboarding/AdminOnboardingfiles";
import { Ohs } from "./Ohs";
import { Acknowledgedoc } from "./Acknowledgedoc";
import { Acknowledgedocfolder } from "./Acknowledgedocfolder";
import { Acknowledgedocfiles } from "./Acknowledgedocfiles";
import { Banner } from "./Banner";
import { Elearninghub } from "./Elearninghub";
import { Onboarding } from "./Onboarding";
import { Onboardingfiles } from "./Onboardingfiles";
import { Incident } from "./Incident/Incident";
import { Victim } from "./Incident/Victim";
import { Reporter } from "./Incident/Reporter";
import { About } from "./Incident/About";
import { Summary } from "./Incident/Summary";
import { Detail } from "./Nearmiss/Detail";
import { Nearreporter } from "./Nearmiss/Nearreporter";
import { Aboutmiss } from "./Nearmiss/Aboutmiss";
import { Summarynear } from "./Nearmiss/Summarynear";
import { Reporth } from "./Reporthazard/Reporth";
import { Documents } from "./Admin/Documents";
import { Incidents } from "./Admin/Incident/Incidents";
import { Nearmiss } from "./Admin/Incident/Nearmiss";
import { Reporthazards } from "./Admin/Incident/Reporthazards";
import { Elearningcourse } from "./Admin/Elearning/Elearningcourse";
import { Assigncourse } from "./Admin/Elearning/Assigncourse";
import { Courselist } from "./Admin/Elearning/Courselist";
import { Assignment } from "./Admin/Elearning/Assignment";
import { AdminRoster } from "./Admin/AdminRoster";
import { Documentsuser } from "./Documentsuser";
import { Startcourse } from "./Elearningcourseuser/Startcourse";
import { Course } from "./Elearningcourseuser/Course";
import { Healthresponsibility } from "./Elearningcourseuser/Healthresponsibility";
import { WorkerinductionEmergency } from "./Elearningcourseuser/WorkerinductionEmergency";
import { WorkermentalHealthawareness } from "./Elearningcourseuser/WorkermentalHealthawareness";
import { Displayscreen } from "./Elearningcourseuser/Displayscreen";
import { Mannual } from "./Elearningcourseuser/Mannual";
import { Preparing } from "./Elearningcourseuser/Preparing";
import { Firstads } from "./Elearningcourseuser/Firstads";
import { Noises } from "./Elearningcourseuser/Noises";
import { Silica } from "./Elearningcourseuser/Silica";
import { Remote } from "./Elearningcourseuser/Remote";
import { Driving } from "./Elearningcourseuser/Driving";
import { Risk } from "./Elearningcourseuser/Risk";
import { Working } from "./Elearningcourseuser/Working";
import { Asbestos } from "./Elearningcourseuser/Asbestos";
import { Chemical } from "./Elearningcourseuser/Chemical";
import { Lesson2 } from "./Elearningcourseuser/Lesson2";
import { Lesson3 } from "./Elearningcourseuser/Lesson3";
import { Lesson4 } from "./Elearningcourseuser/Lesson4";

//Work Information Route
import { Workhome } from "./Workinformation/Workhome";
import { Siteaccess } from "./Siteaccess";
import { Usermannual } from "./Usermannual";

//Work Information Route

import { Client } from "./Client";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom-style.css";
import "./assets/css/style.css";
import "./assets/css/otherstyle.css";

function App() {
  //const [currentForm, setCurrentForm] = useState("login");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      setUser(JSON.parse(userStr));
    }
  }, []);
  // console.log(user);
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Banner />}></Route>

        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>

        <Route path="/register" element={<Landingpage />}></Route>
        <Route path="/register/:id" element={<Landingpage />}></Route>
        <Route path="/forgotpassword" element={<Forgotpassword />}></Route>
        <Route path="/cl" element={<Cl />}></Route>
        <Route path="/checkpdf" element={<Checkpdf />}></Route>
        <Route path="/Checkpdfpanada" element={<Checkpdfpanada />}></Route>
        <Route path="/syn" element={<Syn />}></Route>
        <Route path="/home" element={<Homenew />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/experthelp" element={<Experthelp />}></Route>
        <Route path="/terms&condition" element={<Termscondition />}></Route>
        <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
        <Route path="/newroster" element={<Roster />}></Route>
        <Route path="/timesheet" element={<Timesheet />}></Route>
        <Route path="/currentroster" element={<Currentroster />}></Route>
        <Route path="/notifications" element={<Notificationforuser />}></Route>
        <Route path="/onboarding" element={<Onboarding />}></Route>
        <Route path="/acknowledgedoc" element={<Acknowledgedoc />}></Route>
        <Route
          path="/acknowledgedocfolder/:folder_name"
          element={<Acknowledgedocfolder />}
        ></Route>
        <Route
          path="/acknowledgedocfile/:folder_name/:file_name"
          element={<Acknowledgedocfiles />}
        ></Route>
        <Route path="/clinetinformation" element={<Clientinfo />}></Route>

        <Route path="/admin/login" element={<AdminLogin />}></Route>
        <Route
          path="/admin/contract-files/:id"
          element={<AdminContractfiles />}
        ></Route>
        <Route
          path="/admin/contract-folder"
          element={<AdminContractfolder />}
        ></Route>
        <Route path="/admin/addskill" element={<AdminAddskill />}></Route>
        <Route path="/admin/addlicence" element={<AdminAddlicence />}></Route>
        <Route path="/admin/addtrade" element={<AdminAddtrade />}></Route>
        <Route path="/admin/documents" element={<Documents />}></Route>
        <Route path="/admin/e-learning" element={<Elearningcourse />}></Route>
        <Route path="/admin/courselist" element={<Courselist />}></Route>
        <Route path="/onboardingfiles" element={<Onboardingfiles />}></Route>
        <Route
          path="/admin/onboardingdocument"
          element={<AdminOnboardingdocument />}
        ></Route>
        <Route path="/admin/roster" element={<AdminRoster />}></Route>
        <Route
          path="/admin/onboardingfiles/:id"
          element={<AdminOnboardingfiles />}
        ></Route>
        <Route
          path="/admin/assign-new-course"
          element={<Assigncourse />}
        ></Route>
        <Route path="/admin/assignment" element={<Assignment />}></Route>
        <Route path="/site_access" element={<Siteaccess />}></Route>
        <Route path="/user_mannual" element={<Usermannual />}></Route>
        <Route path="/documents" element={<Documentsuser />}></Route>
        <Route path="/e-learninghub" element={<Elearninghub />}></Route>

        <Route path="/start-course/:id" element={<Startcourse />}></Route>
        <Route path="/lesson1/:id/:id" element={<Course />}></Route>
        <Route path="/course/risk-assessment/:id" element={<Risk />}></Route>
        <Route
          path="/course/working-at-heights/:id"
          element={<Working />}
        ></Route>
        <Route
          path="/course/first-aid-awareness/:id"
          element={<Firstads />}
        ></Route>
        <Route path="/course/silica-awareness/:id" element={<Silica />}></Route>
        <Route path="/course/noise-awareness/:id" element={<Noises />}></Route>
        <Route
          path="/course/healthresponsibility/:id"
          element={<Healthresponsibility />}
        ></Route>
        <Route
          path="/course/preparing-safe-work-method-statements/:id"
          element={<Preparing />}
        ></Route>
        <Route
          path="/course/remote-isolated-work/:id"
          element={<Remote />}
        ></Route>
        <Route
          path="/course/driving-for-work/:id"
          element={<Driving />}
        ></Route>
        <Route
          path="/course/display-screen-equipment/:id"
          element={<Displayscreen />}
        ></Route>
        <Route path="/course/manual-handling/:id" element={<Mannual />}></Route>
        <Route
          path="/course/hazardous-chemicals/:id"
          element={<Chemical />}
        ></Route>
        <Route
          path="/course/asbestos-awareness/:id"
          element={<Asbestos />}
        ></Route>
        <Route
          path="/course/worker-induction-emergency-preparedness/:id"
          element={<WorkerinductionEmergency />}
        ></Route>
        <Route
          path="/course/workplace-mental-health-awareness/:id"
          element={<WorkermentalHealthawareness />}
        ></Route>

        <Route path="/lesson2/:id/:id" element={<Lesson2 />}></Route>
        <Route path="/lesson3/:id/:id" element={<Lesson3 />}></Route>
        <Route path="/lesson4/:id/:id" element={<Lesson4 />}></Route>
        <Route
          path="/admin/actioncenter/reporthazard"
          element={<Reporthazards />}
        ></Route>
        <Route
          path="/admin/actioncenter/incidents"
          element={<Incidents />}
        ></Route>
        <Route
          path="/admin/addvocationalTraining"
          element={<AdminAddvocationaltraining />}
        ></Route>
        <Route
          path="/admin/addpreviouswork"
          element={<AdminAddPreviouswork />}
        ></Route>
        <Route
          path="/admin/addreference"
          element={<AdminAddreference />}
        ></Route>
        <Route
          path="/admin/addmachinery"
          element={<AdminAddmachinery />}
        ></Route>
        <Route
          path="/admin/addmentioncertificate"
          element={<AdminAddmentioncertificate />}
        ></Route>
        <Route
          path="/admin/addequipmentwork"
          element={<AdminAddEquipmentwork />}
        ></Route>
        <Route path="/admin/logout" element={<AdminLogout />}></Route>
        <Route
          path="/admin/employeeresume/:id"
          element={<EmployeeResume />}
        ></Route>
        <Route
          path="/admin/previousresume/:id"
          element={<JlCertificate />}
        ></Route>
        <Route
          path="/admin/employeeresumepdf/:id"
          element={<EmployeeResumePdf />}
        ></Route>
        <Route
          path="/admin/notification"
          element={<AdminNotification />}
        ></Route>
        <Route path="/admin/employee" element={<AdminEmployee />}></Route>
        <Route path="/admin/location" element={<AdminLocation />}></Route>
        <Route path="/admin/timesheet" element={<AdminTimesheet />}></Route>
        <Route path="/admin/calendar" element={<AdminCalendar />}></Route>
        <Route path="/calendar" element={<Calendar />}></Route>
        <Route path="/admin/client" element={<AdminClient />}></Route>
        <Route path="/admin/addlocation" element={<AdminAddlocation />}></Route>
        <Route path="/admin/addclient" element={<AdminAddclient />}></Route>
        <Route path="/admin/addemployee" element={<AdminAddemployee />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/workinformation/home" element={<Workhome />}></Route>
        <Route path="/ohs" element={<Ohs />}></Route>
        <Route path="/incident" element={<Incident />}></Route>
        <Route path="/incident/detail/:id" element={<Incident />}></Route>
        <Route path="/incident/victim/:id" element={<Victim />}></Route>
        <Route path="/incident/reporter/:id" element={<Reporter />}></Route>
        <Route path="/incident/about/:id" element={<About />}></Route>
        <Route path="/incident/summary/:id" element={<Summary />}></Route>
        <Route path="/near-miss/summary/:id" element={<Summarynear />}></Route>
        <Route path="/near-miss/detail" element={<Detail />}></Route>
        <Route path="/near-miss/detail/:id" element={<Detail />}></Route>
        <Route path="/near-miss/about/:id" element={<Aboutmiss />}></Route>
        <Route path="/reporthazard" element={<Reporth />}></Route>
        <Route path="/reporthazard/edit/:id" element={<Reporth />}></Route>
        <Route
          path="/admin/actioncenter/near_miss"
          element={<Nearmiss />}
        ></Route>
        <Route
          path="/near-miss/reporter/:id"
          element={<Nearreporter />}
        ></Route>
        <Route path="/client" element={<Client />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
