import React, { useRef, useState, useEffect } from "react";
import { Header } from "./Header";
import axios from "axios";
import { Footer } from "./Footer";
import { Checkpdf } from "./Checkpdf"; // Use named import

import DataTable from "react-data-table-component";
import "./assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
export const Acknowledgedocfiles = () => {
  const [records, setRecords] = useState([]);
  const [setSelectedFile_doc, setsetSelectedFile_doc] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suc, setsuc] = useState(false);
  const apiURL = "https://jlmining.app/";
  const [docUp1, setdocUp1] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedPdftruefalse, setselectedPdftruefalse] = useState(false);
  const folder_name = useParams();
  const file_name = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Acknowledge Document Page";
  }, []);
  useEffect(() => {
    handleDownload(file_name);
  }, []);
  useEffect(() => {
    getusercontractDetail();
  }, []);
  const getusercontractDetail = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    axios
      .post(apiURL + "api/admincontracts/getusercontractDetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setRecords(res.data.results);
      })
      .catch((err) => {});
  };
  //const handleDownload = (fileName) => {
  // const fileUrl = `https://jlmining.app/uploads/contractfolder/${fileName}`;
  // const link = document.createElement("a");
  // link.href = fileUrl;
  // link.setAttribute("download", fileName); // Ensures the browser downloads the file
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);

  //};
  const handleDownload = (fileName) => {
    const filePath = `https://jlmining.app/uploads/contractfolder/${folder_name.folder_name}/${folder_name.file_name}`;

    // Clear state first to force re-render
    setSelectedPdf(null);

    setTimeout(() => {
      setSelectedPdf(filePath);
      setselectedPdftruefalse(true);
    }, 100);
  };

  const columns = [
    {
      name: "File / Folder",
      selector: (row) =>
        row.folder_id === 0 ? row.file_name : row.folder_name,
      cell: (row) =>
        row.folder_id === 0 ? (
          <span>{row.file_name}</span> // Show file name
        ) : (
          <a href={`/folder/${row.folder_name}`}>
            <img
              src={require("./assets/images/open-folder_5994725.png")} // Replace with your folder image path
              alt="Folder"
              style={{ width: 30, height: 30 }}
            />
          </a>
        ),
      sortable: true,
    },
    {
      name: "Download",
      cell: (row) =>
        row.folder_id === 0 ? ( // If it's a file, show download link
          <a href={`/folder/${row.folder_name}/${row.file_name}`} download>
            {row.file_name}
          </a>
        ) : (
          <a href={`/folder/${row.folder_name}`}>Folder</a> // If it's a folder, link to the folder
        ),
      sortable: true,
    },
  ];

  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleFileChange_doc = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setsetSelectedFile_doc(event.target.files[0]);
    }
  };
  const handlesubmitDoc = (event) => {
    event.preventDefault();
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    formData.append("user_id", userStr[0].id);
    formData.append("file", setSelectedFile_doc);

    axios
      .post(
        "https://jlmining.app/api/admincontracts/uploadcontractFiles",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setsuc(true);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        console.log(res.data);
      })
      .catch((err) => {});
  };
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          z-index:99;
        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <Header />

      {/* <div className="banner-ach py-5">
        <section className="d-block pb-5">
          <div className="container-lg">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1 notif-content">
                <h2>Upload Acknowledge Documents</h2>
                {suc && (
                  <span className="text-success">File upload successfully</span>
                )}
                <div className="gap-2 ">
                  <form method="post" onSubmit={handlesubmitDoc}>
                    <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                      <i className="fal fa-arrow-alt-up text-dark"></i>
                      <h1>Drag and drop your files here to upload</h1>
                      <h6 className="text-dark border-0">Browse files...</h6>
                      <input
                        type="file"
                        onChange={handleFileChange_doc}
                        required
                        className="file-name-block"
                      />
                    </div>
                    <div className="d-flex justify-content-center ">
                      <div className="position-relative  Document-nm1">
                        <button
                          type="submit"
                          className={`${docUp1 === true ? "docup" : ""}`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}
      <section className="d-block">
        {selectedPdf && <Checkpdf key={selectedPdf} pdfPath={selectedPdf} />}
      </section>

      <Footer />
    </>
  );
};
