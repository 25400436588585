import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
import { ReactDialogBox } from "react-js-dialog-box";
export const AdminContractfolder = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [errormessage, seterrormessage] = useState(
    "Client deleted successfully!"
  );
  const [isClickedclient, setIsClickedclient] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const [showAlertts, setShowAlertts] = useState(false);
  const [message, setmessage] = useState("");
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [tab1, settab1] = useState(false);
  const [tab2, settab2] = useState(false);
  const [tab3, settab3] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selecttype, setSelecttype] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [deleteerror, setdeleteerror] = useState(false);
  const [getfolder, setgetfolder] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [deletemessage, setdeletemessage] = useState(false);
  const [docUp1, setdocUp1] = useState(false);
  const [isuserId, setisuserId] = useState([]);

  const [distrue, setdistrue] = useState(true);
  const apiURL = "https://jlmining.app/";
  const handlegetClient = (clientId, index) => {
    setSelectedIndices(
      (prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index) // Remove if already selected
          : [...prevSelected, index] // Add if not selected
    );
    setisuserId(
      (prevIds) =>
        prevIds.includes(clientId)
          ? prevIds.filter((id) => id !== clientId) // Remove if already selected
          : [...prevIds, clientId] // Add if not selected
    );
  };

  useEffect(() => {
    if (selectedIndices.length > 0) {
      setIsClickedclient(true);
    } else {
      setIsClickedclient(false);
    }
  }, [selectedIndices]);

  useEffect(() => {
    if (selectedIndices.length > 0 && checkboxes.length > 0) {
      setdistrue(false);
      setIsClickedclient(true);
    } else {
      setdistrue(true);
      setIsClickedclient(false);
    }
  }, [selectedIndices, checkboxes]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Contract Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getemployee", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    let formdata = {};
    axios
      .post(
        "https://jlmining.app/api/admincontracts/getcontrectfiled",
        formdata,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setgetfolder(res.data.results);
      })
      .catch((err) => {});
  }, []);
  const getallfiles = () => {
    let formdata = {};
    axios
      .post(
        "https://jlmining.app/api/admincontracts/getcontrectfiled",
        formdata,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setgetfolder(res.data.results);
      })
      .catch((err) => {});
  };
  const handleedit = (event) => {
    setIsClickedclient(!isClickedclient);
  };
  const handleSubmit = (event) => {
    setdocUp1(true);
    event.preventDefault();
    const vl = event.target;
    let formdata = {
      folder: vl.folder.value,
    };
  };
  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {};

    if (selectAll === true) {
      setSelecttype(true);
    } else {
      setSelecttype(false);
    }
  }, [selectAll]);
  const closeBox = () => {
    setisOpen(false);
  };

  const deletefolder_tab1 = () => {
    if (checkboxes.length > 0) {
      settab1(true);
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const deletefolder = () => {
    if (checkboxes.length > 0) {
      settab2(false);
      console.log(checkboxes);
      setdeletemessage(false);
      let formdata = {
        multi_Id: checkboxes,
      };
      axios
        .post(
          "https://jlmining.app/api/admincontracts/deletefoldercontract",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          settab3(true);
          setCheckboxes([]);
          setSelectAll(false);
          var alldata = res.data.results;
          setgetfolder(alldata);
          setdeleteerror(true);
          setTimeout(() => {
            setdeleteerror(false);
            setShowAlert(false);
            settab3(false);
          }, 4500);
        })
        .catch((err) => {});
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const checkall = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const updatedCheckboxes = getfolder.map((item, index) => ({
        id: item.id,
        isChecked: true,
      }));
      setCheckboxes(updatedCheckboxes);
      console.log(updatedCheckboxes);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (id, index, event) => {
    const isChecked = event.target.checked;
    let updatedCheckboxes = [...checkboxes];

    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox.id === id
    );

    if (isChecked) {
      if (checkboxIndex === -1) {
        updatedCheckboxes.push({ id, isChecked });
      } else {
        updatedCheckboxes[checkboxIndex].isChecked = isChecked;
      }
    } else {
      if (checkboxIndex !== -1) {
        updatedCheckboxes.splice(checkboxIndex, 1);
      }
    }
    setCheckboxes(updatedCheckboxes);
  };

  const closetab1 = () => {
    settab1(false);
  };
  const opentab2 = () => {
    settab1(false);
    settab2(true);
  };
  const closetab2 = () => {
    settab2(false);
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSubmitfiles = (event) => {
    event.preventDefault();

    var folder_name = event.target.folder_name.value;
    let formData = { folder: folder_name };

    axios
      .post(
        "https://jlmining.app/api/admincontracts/savecontractfiles",
        formData,
        {
          headers: { Accept: "application/json" },
        }
      )
      .then((res) => {
        setShowAlert({
          type: "success",
          message: "Folder saved successfully!",
        });
        getallfiles();
        // ✅ Reset the form
        event.target.reset();

        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      })
      .catch((err) => {
        if (err.response && err.response.data.error) {
          // Show error message if folder already exists
          seterrormessage(err.response.data.error);
          setShowAlert({
            type: "error",
            message: err.response.data.error,
          });
        } else {
          setShowAlert({ type: "error", message: "Something went wrong!" });
        }
      });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 10) {
      alert("You can only upload up to 10 files.");
      event.target.value = ""; // Reset the file input
      return;
    }

    setSelectedFiles(files);
  };
  const handlesendFile = () => {
    if (selectedIndices.length > 0 && checkboxes.length > 0) {
      let formdata = {
        user_id: isuserId,
        files: checkboxes,
      };
      axios
        .post(
          "https://jlmining.app/api/admincontracts/sendcontractFolder",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          const { insertedFiles, alreadyExists } = res.data;

          let message = "Files saved successfully.\n";

          if (alreadyExists.length > 0) {
            message += "These files were already assigned:\n";
            alreadyExists.forEach((file) => {
              message += `- ${file.fullName} (User ID: ${file.userId})\n`;
            });
          }

          setmessage(message); // Ensure 'setMessage' is correctly used in your state hook

          setShowAlertts(true);
          setShowAlertts({ type: "success" });

          setTimeout(() => {
            setShowAlertts(false);
            setSelectedIndices([]);
            setisuserId([]);
            setCheckboxes([]);
            //window.location.reload();
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const searcfilename = (event) => {
    console.log(event.target.value);
    let formdata = {
      search: event.target.value,
    };
    axios
      .post("https://jlmining.app/api/admincontracts/searchfolder", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        setgetfolder(alldata);
      })
      .catch((err) => {});
  };
  const searcfileemployee = (event) => {
    console.log(event.target.value);
    let formdata = {
      search: event.target.value,
    };
    axios
      .post(
        "https://jlmining.app/api/admincontracts/searcfileemployee",
        formdata,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert type="error" description={errormessage} closable />
                )}
                {showAlerts?.type === "errors" && (
                  <Alert
                    type="error"
                    description="You have no permissions to delete this client, because this client use for roster"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <div className="col-md-12 over-empl">
                    <div className="right-form-box bg-white shadow p-2 ">
                      <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                        Send Contract Document
                      </h5>
                      <div className="block  px-4">
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control w-50"
                            onInput={searcfilename}
                            placeholder="Search file..."
                          />
                        </div>

                        <div className="row mt-4">
                          <div className="form-check d-flex gap-2 align-items-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectAll}
                              onChange={checkall}
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label "
                              for="flexCheckDefault"
                            >
                              Select All
                            </label>

                            <Link
                              to="javascript:void(0)"
                              onClick={deletefolder_tab1}
                            >
                              Delete
                            </Link>
                          </div>
                        </div>

                        {deleteerror && (
                          <span className="text-danger">
                            Folder has been deleted successfully
                          </span>
                        )}
                        {deletemessage && (
                          <span className="text-danger mt-4">
                            Please choose atleast one folder
                          </span>
                        )}
                        <div className="row mt-4 scroll-text-box">
                          {Array.isArray(getfolder) && getfolder.length > 0 ? (
                            getfolder.map((item, index) => (
                              <div
                                className="form-check d-flex gap-3 align-items-start border-bottom py-2"
                                key={index}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      item.id,
                                      checkboxes.findIndex(
                                        (checkbox) => checkbox.id === item.id
                                      ),
                                      event
                                    )
                                  }
                                  checked={
                                    (checkboxes.find(
                                      (checkbox) => checkbox.id === item.id
                                    ) &&
                                      checkboxes.find(
                                        (checkbox) => checkbox.id === item.id
                                      ).isChecked) ||
                                    false
                                  }
                                  key={index}
                                  id={`folderCheckbox_${index}`}
                                />
                                <Link to={`/admin/contract-files/${item.id}`}>
                                  <img
                                    src={require("../assets/images/open-folder_5994725.png")}
                                    width="30"
                                    alt="Image"
                                  />{" "}
                                  {item.name}
                                </Link>
                              </div>
                            ))
                          ) : (
                            <div>
                              <p>No data available</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {tab1 && (
                        <>
                          <ReactDialogBox
                            closeBox={closeBox}
                            closeButtonColor="white"
                            bodyTextColor="black"
                            bodyHeight="280px"
                          >
                            <style>
                              {`
                                                                                      header._br_-q {
                                                                                        display: none;
                                                                                      }
                                                                                    `}
                            </style>
                            <div
                              style={{
                                height: "250px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                                <div className="text-center">
                                  <img
                                    src={require("../assets/images/3997872.png")}
                                    width="100"
                                    alt="Image"
                                  />
                                  <h4 className="text-danger">Confirmation</h4>
                                  <span>
                                    <b>
                                      Are You Sure You Want To Delete This File
                                    </b>
                                  </span>
                                  <br />
                                  <span
                                    className="color-grey"
                                    style={{ fontSize: "12px" }}
                                  >
                                    If you delete this file, you might lose it
                                    permanently and won't be able to retrieve
                                    it.
                                    <br /> Please think carefully before
                                    proceeding.
                                  </span>
                                  <div className="d-flex justify-content-between ">
                                    <button
                                      onClick={opentab2}
                                      type="button"
                                      className=" btn btn-danger"
                                    >
                                      Yes
                                    </button>
                                    <button
                                      type="button"
                                      onClick={closetab1}
                                      className=" btn btn-success"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReactDialogBox>
                        </>
                      )}
                      {tab2 && (
                        <>
                          <ReactDialogBox
                            closeBox={closeBox}
                            closeButtonColor="white"
                            bodyTextColor="black"
                            bodyHeight="280px"
                          >
                            <style>
                              {`
                                                                                      header._br_-q {
                                                                                        display: none;
                                                                                      }
                                                                                    `}
                            </style>
                            <div
                              style={{
                                height: "250px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                                <div className="text-center">
                                  <img
                                    src={require("../assets/images/2959016.png")}
                                    width="100"
                                    alt="Image"
                                  />
                                  <h4 className="text-danger">Confirmation</h4>
                                  <span>
                                    <b>Still You have chance by clicking No</b>
                                  </span>

                                  <div className="d-flex justify-content-between ">
                                    <button
                                      type="button"
                                      onClick={closetab2}
                                      className=" btn btn-success"
                                    >
                                      No
                                    </button>
                                    <button
                                      type="button"
                                      className=" btn btn-danger"
                                      onClick={deletefolder}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReactDialogBox>
                        </>
                      )}
                      {tab3 && (
                        <>
                          <ReactDialogBox
                            closeBox={closeBox}
                            closeButtonColor="white"
                            bodyTextColor="black"
                            bodyHeight="280px"
                          >
                            <style>
                              {`
                                                                                      header._br_-q {
                                                                                        display: none;
                                                                                      }
                                                                                    `}
                            </style>
                            <div
                              style={{
                                height: "250px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                                <div className="text-center">
                                  <img
                                    src={require("../assets/images/2441914.png")}
                                    width="100"
                                    alt="Image"
                                  />
                                  <h4 className="text-danger">Deleted</h4>
                                  <span>
                                    You have successfully deleted the file
                                    <br />
                                    that you don't want
                                  </span>
                                </div>
                              </div>
                            </div>
                          </ReactDialogBox>
                        </>
                      )}
                      {isOpen && (
                        <>
                          <ReactDialogBox
                            closeBox={closeBox}
                            modalWidth="50%"
                            headerBackgroundColor="red"
                            headerTextColor="white"
                            headerHeight="40"
                            closeButtonColor="white"
                            bodyBackgroundColor="white"
                            bodyTextColor="black"
                            bodyHeight="280px"
                            headerText="Add Folder"
                          >
                            <div
                              style={{
                                height: "250px",
                                paddingTop: "20px",
                              }}
                            >
                              <div className="d-flex flex-column gap-3 bg-white inperson mt-4">
                                {showAlert?.type === "error" && (
                                  <span className="text-danger">
                                    This folder is already exists
                                  </span>
                                )}
                                <form
                                  action="javascript:void(0)"
                                  onSubmit={handleSubmit}
                                  method="post"
                                >
                                  <div className="d-flex flex-column gap-2">
                                    <div className="mb-3">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Create Folder
                                      </label>
                                      <input
                                        type="text"
                                        name="folder"
                                        required
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter here..."
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <button
                                        type="submit"
                                        style={{
                                          background: "#F71701 !important",
                                        }}
                                        className={`btn btn-danger${
                                          docUp1 === true ? "docup" : ""
                                        }`}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </ReactDialogBox>
                        </>
                      )}

                      <span className="fs-20 text-center"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="">
                  <div className="block-full">
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <div className="right-form-box bg-white shadow p-2 ">
                          <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                            Create Folder
                          </h5>
                          {showAlert?.type === "success" && (
                            <Alert
                              type="success"
                              description="Folder name saved successfully"
                              closable
                            />
                          )}
                          <form action="" onSubmit={handleSubmitfiles}>
                            <div className="row gy-2">
                              <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column gap-2">
                                  <label for="">
                                    Create Folder{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    required
                                    type="text"
                                    name="folder_name"
                                    placeholder="type here"
                                  />
                                </div>
                              </div>

                              <div className="d-flex align-items-center gap-3 justify-content-end">
                                <button className="sub-btn" type="submit">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="right-form-box bg-white shadow p-2 ">
                          <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                            Select Employee
                          </h5>
                          {showAlertts?.type === "success" && (
                            <Alert
                              type="success"
                              description={message}
                              closable
                            />
                          )}
                          <div className="col-md-12 over-empl">
                            <div className="right-form-box bg-white shadow p-2 ">
                              <div className="block  px-4">
                                <div className="d-flex justify-content-between mb-4">
                                  <h4 className="fs-5">Select Employee</h4>
                                  <input
                                    type="text"
                                    className="form-control w-50"
                                    onInput={searcfileemployee}
                                    placeholder="Search employee..."
                                  />
                                  <button
                                    onClick={handlesendFile}
                                    type="submit"
                                    disabled={distrue}
                                    className="sub-btn"
                                    style={{
                                      opacity: distrue ? 0.1 : 1,
                                      pointerEvents: distrue ? "none" : "auto",
                                    }}
                                  >
                                    Send Files
                                  </button>
                                </div>

                                <div className="d-flex flex-column h-100 clientselectmain">
                                  {Array.isArray(data) ? (
                                    data.map((item, index) => (
                                      <Link
                                        to="javascript:void(0)"
                                        className={`item ${
                                          selectedIndices.includes(index)
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handlegetClient(item.id, index)
                                        }
                                      >
                                        {item.step2_title} {item.first_name}{" "}
                                        {item.last_name}{" "}
                                      </Link>
                                    ))
                                  ) : (
                                    <Link> No data available </Link>
                                  )}
                                </div>
                              </div>

                              <span className="fs-20 text-center"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
