import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminNotification = () => {
  const navigate = useNavigate();
  const [valuenotifications, setValuegetnotifications] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Notification Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {};
    axios
      .post("https://jlmining.app/admin/getallnotifications", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        var alldata = res.data.data;
        setValuegetnotifications(alldata);
      })
      .catch((err) => {});
  }, []);
  const refreshnot = () => {
    let formdata = {};
    axios
      .post("https://jlmining.app/admin/getallnotifications", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        var alldata = res.data.data;
        setValuegetnotifications(alldata);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (valuenotifications) {
      const ws = new WebSocket("wss://jlmining.app");

      ws.onmessage = (event) => {
        try {
          const receivedMessage = JSON.parse(event.data);
          console.log(receivedMessage);

          if (receivedMessage.event === "AssignNewCourse") {
            refreshnot();
          }
        } catch (error) {
          console.error("Failed to parse WebSocket message:", error);
          console.error("Raw message:", event.data);
        }
      };

      ws.onopen = () => {
        console.log("WebSocket connection established");
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      return () => {
        ws.close();
        console.log("WebSocket connection closed");
      };
    }
  }, [valuenotifications]);
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-12">
                <div className="right-form-box bg-white shadow p-2 ">
                  <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                    Notification Center
                  </h5>
                  <div className="block scroll-text-box px-4">
                    {Array.isArray(valuenotifications) ? (
                      valuenotifications.map((item, index) => (
                        <div className="  gap-2 mt-3">
                          <span className="fw-800">
                            {" "}
                            {item.first_name} {item.middle_name}{" "}
                            {item.last_name}
                          </span>{" "}
                          {item.message} on {item.nd}
                        </div>
                      ))
                    ) : (
                      <Link> No data available </Link>
                    )}
                  </div>

                  <span className="fs-20 text-center"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
