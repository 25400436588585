import React, { useRef, useState, useEffect } from "react";
import { Header } from "./Header";
import axios from "axios";
import { Footer } from "./Footer";
import { Checkpdf } from "./Checkpdf"; // Use named import

import DataTable from "react-data-table-component";
import "./assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
export const Acknowledgedocfolder = () => {
  const [records, setRecords] = useState([]);
  const [setSelectedFile_doc, setsetSelectedFile_doc] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suc, setsuc] = useState(false);
  const apiURL = "https://jlmining.app/";
  const [docUp1, setdocUp1] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedPdftruefalse, setselectedPdftruefalse] = useState(false);
  const folder_name = useParams();

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Acknowledge Document File Page";
  }, []);
  useEffect(() => {
    getusercontractDetail();
  }, []);
  const getusercontractDetail = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
      folder_name: folder_name,
    };
    axios
      .post(
        apiURL + "api/admincontracts/getusercontractDetailFolderdetail",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setRecords(res.data.results);
      })
      .catch((err) => {});
  };

  const columns = [
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => row.file_name,
      sortable: true,
    },
    {
      name: "Download",
      cell: (row) => (
        <a
          target="_blank"
          href={`/acknowledgedocfile/${row.name}/${row.file_name}`}
        >
          {row.file_name}
        </a>
      ),
      sortable: true,
    },
  ];

  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleFileChange_doc = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setsetSelectedFile_doc(event.target.files[0]);
    }
  };
  const handlesubmitDoc = (event) => {
    event.preventDefault();
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    formData.append("user_id", userStr[0].id);
    formData.append("file", setSelectedFile_doc);

    axios
      .post(
        "https://jlmining.app/api/admincontracts/uploadcontractFiles",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setsuc(true);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        console.log(res.data);
      })
      .catch((err) => {});
  };
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          z-index:99;
        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <Header />

      <div className="banner-ach py-5">
        <section className="d-block pb-5">
          <div className="container-lg">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1 notif-content ">
                <h2>Acknowledge Document Files</h2>
                <div className="gap-2 ">
                  <DataTable
                    columns={columns}
                    data={filteredRecords}
                    pagination
                    highlightOnHover
                    noDataComponent="No records found."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="d-block">
        {selectedPdf && <Checkpdf key={selectedPdf} pdfPath={selectedPdf} />}
      </section>

      <Footer />
    </>
  );
};
