import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/admin/css/custom-style.css";
import "../assets/admin/css/main.css";
import { AdminNotification } from "./AdminNotification";
import { AdminClient } from "./AdminClient";
import { FaSignOutAlt } from "react-icons/fa";
const AdminHeader = ({ content }) => {
  const location = useLocation();
  const [showlog, sertshowlog] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("admin");
    window.location.href = "/admin/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);

  const [svlexp, setsvlexp] = useState(content);

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };
    console.log("Hedaer");
    console.log(content);
    if (content === undefined) {
      getexpTotal();
    }
    setsvlexp(content);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDivVisible, content]);

  function getexpTotal() {
    axios
      .get("https://jlmining.app/admin/getemployeeAdmin", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          // Initialize a variable to store the total uploads count
          let totalUploadsCount = 0;

          // Loop through the results array and sum up the total_uploads parameter
          res.data.results.forEach((employee) => {
            totalUploadsCount += employee.total_uploads;
          });

          // Use the totalUploadsCount as needed
          setsvlexp(totalUploadsCount);
        }
      })
      .catch((err) => {});
  }
  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
  };
  const handleclicklogoutshow = () => {
    sertshowlog(!showlog);
  };

  return (
    <header className="admin-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-8 col-md-3">
            <div className="d-flex gap-5 align-items-center">
              <div className="nav-mob d-block d-md-none">
                <button type="button" onClick={toggleDivVisibility}>
                  <i className="fal fa-bars"></i>
                </button>
              </div>
              <Link to="#" className="logo-box fulw d-inline-block">
                <img
                  src={require("../assets/admin/images/logo2.png")}
                  alt="image"
                />
              </Link>
              <Link to="#" className="logo-box fulw d-inline-block">
                <img
                  src={require("../assets/admin/images/hss.png")}
                  alt="image"
                />
              </Link>
            </div>
          </div>
          <div className="col-4 col-md-9">
            <div className="d-flex gap-2 align-items-center justify-content-end">
              <div className="user-box rel">
                <button
                  className="d-flex gap-2 align-items-center"
                  onClick={handleclicklogoutshow}
                  type="button"
                >
                  <span className="user-admin-img ofit">
                    <img
                      src={require("../assets/admin/images/user.png")}
                      alt="image"
                    />
                  </span>
                  <h6 className="user-text-admin">Admin Name</h6>
                </button>
                {showlog && (
                  <div className="drop-user-links mt-2 text-center">
                    <Link to="/admin/logout">
                      <FaSignOutAlt /> Logout
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Admin Header content */}

      <div
        className="navbar-top"
        style={{ display: isDivVisible ? "block" : "none" }}
      >
        <div className="d-flex flex-column">
          <div className="nav-link-main d-flex flex-column">
            <Link
              className={
                location.pathname === "/admin/notification" ? "active" : ""
              }
              to="/admin/notification"
            >
              Notification center
            </Link>
            <Link
              className={
                location.pathname.startsWith("/admin/onboardingdocument") ||
                location.pathname.startsWith("/admin/onboardingfiles/")
                  ? "active"
                  : ""
              }
              to="/admin/onboardingdocument"
            >
              Onboarding Document
            </Link>
            <Link
              className={
                location.pathname === "/admin/contract-folder" ? "active" : ""
              }
              to="/admin/contract-folder"
            >
              Acknowledge Documents
            </Link>

            <Link
              className={
                location.pathname === "/admin/timesheet" ? "active" : ""
              }
              to="/admin/timesheet"
            >
              Time Sheet
            </Link>
            <Link
              className={location.pathname === "/admin/roster" ? "active" : ""}
              to="/admin/roster"
            >
              Roster
            </Link>
            <Link
              className={
                location.pathname === "/admin/employee" ||
                location.pathname === "/admin/addemployee"
                  ? "active"
                  : ""
              }
              to="/admin/employee"
            >
              Employees <span className="textlic">{svlexp}</span>
            </Link>
            <Link
              className={
                location.pathname === "/admin/client" ||
                location.pathname === "/admin/addclient"
                  ? "active"
                  : ""
              }
              to="/admin/client"
            >
              Upload clients
            </Link>
            <Link
              className={
                location.pathname === "/admin/location" ||
                location.pathname === "/admin/addlocation"
                  ? "active"
                  : ""
              }
              to="/admin/location"
            >
              Upload locations
            </Link>
            <Link
              className={
                location.pathname === "/admin/addskill" ||
                location.pathname === "/admin/addskill"
                  ? "active"
                  : ""
              }
              to="/admin/addskill"
            >
              Add Skill
            </Link>
            <Link
              className={
                location.pathname === "/admin/addlicence" ||
                location.pathname === "/admin/addlicence"
                  ? "active"
                  : ""
              }
              to="/admin/addlicence"
            >
              Add Licence
            </Link>
            <Link
              className={
                location.pathname === "/admin/addmentioncertificate" ||
                location.pathname === "/admin/addmentioncertificate"
                  ? "active"
                  : ""
              }
              to="/admin/addmentioncertificate"
            >
              Add Mention Certificate
            </Link>
            <Link
              className={
                location.pathname === "/admin/addtrade" ||
                location.pathname === "/admin/addtrade"
                  ? "active"
                  : ""
              }
              to="/admin/addtrade"
            >
              Add Trade
            </Link>
            <Link
              className={
                location.pathname === "/admin/addmachinery" ||
                location.pathname === "/admin/addmachinery"
                  ? "active"
                  : ""
              }
              to="/admin/addmachinery"
            >
              Add Machinery Skill
            </Link>
            <Link
              className={
                location.pathname === "/admin/addvocationalTraining" ||
                location.pathname === "/admin/addvocationalTraining"
                  ? "active"
                  : ""
              }
              to="/admin/addvocationalTraining"
            >
              Add Vocational Training Skills
            </Link>
            <Link
              className={
                location.pathname === "/admin/addequipmentwork" ||
                location.pathname === "/admin/addequipmentwork"
                  ? "active"
                  : ""
              }
              to="/admin/addequipmentwork"
            >
              Add Equipment Work
            </Link>
            <Link
              className={
                location.pathname === "/admin/addpreviouswork" ||
                location.pathname === "/admin/addpreviouswork"
                  ? "active"
                  : ""
              }
              to="/admin/addpreviouswork"
            >
              Add Previous Work
            </Link>
            <Link
              className={
                location.pathname === "/admin/documents" ||
                location.pathname === "/admin/documents"
                  ? "active"
                  : ""
              }
              to="/admin/documents"
            >
              Add Documents
            </Link>
            <Link
              className={
                location.pathname === "/admin/actioncenter/incidents" ||
                location.pathname === "/admin/actioncenter/near_miss" ||
                location.pathname === "/admin/actioncenter/reporthazard"
                  ? "active"
                  : ""
              }
              to="/admin/actioncenter/incidents"
            >
              Action Center
            </Link>
            <Link
              className={
                location.pathname === "/admin/e-learning" ? "active" : ""
              }
              to="/admin/e-learning"
            >
              Add E-Learning Course
            </Link>
            <Link
              className={
                location.pathname === "/admin/assign-new-course"
                  ? "active"
                  : "" || location.pathname === "/admin/courselist"
                  ? "active"
                  : ""
              }
              to="/admin/courselist"
            >
              Assign Inductions
            </Link>

            <Link to="/admin/logout">
              <FaSignOutAlt /> Logout
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
