import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ReactDialogBox } from "react-js-dialog-box";
import { FaPlus, FaFolder, FaTrash } from "react-icons/fa";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export const AdminOnboardingdocument = () => {
  const navigate = useNavigate();
  const [tab1, settab1] = useState(false);
  const [tab2, settab2] = useState(false);
  const [tab3, settab3] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selecttype, setSelecttype] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [deleteerror, setdeleteerror] = useState(false);
  const [getfolder, setgetfolder] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [deletemessage, setdeletemessage] = useState(false);
  const [docUp1, setdocUp1] = useState(false);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../../assets/admin/images/f-icon.png"));
    document.title = "Onboarding Document Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {};
    axios
      .post("https://jlmining.app/admin/getfolder", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        var alldata = res.data.results;
        setgetfolder(alldata);
      })
      .catch((err) => {});
    if (selectAll === true) {
      setSelecttype(true);
    } else {
      setSelecttype(false);
    }
  }, [selectAll]);
  const closeBox = () => {
    setisOpen(false);
  };
  const openpopup = () => {
    setisOpen(true);
  };
  const handleSubmit = (event) => {
    setdocUp1(true);
    event.preventDefault();
    const vl = event.target;
    let formdata = {
      folder: vl.folder.value,
    };
    axios
      .post("https://jlmining.app/admin/creatfolder", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;

        if (alldata.length > 0) {
          setgetfolder(alldata);
          setShowAlert(true);
          setShowAlert({ type: "success" });
          event.target.reset();
          setisOpen(false);
        } else {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        }

        setTimeout(() => {
          setdocUp1(false);
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const deletehandle = (id) => {
    var de = window.confirm("Are you sure you want to delete this folder");
    if (de) {
      let formdata = {
        id: id,
      };
      axios
        .post("https://jlmining.app/admin/deletefolder", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.results;
          setgetfolder(alldata);
          setdeleteerror(true);
          setTimeout(() => {
            setdeleteerror(false);
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {});
    }
  };
  const deletefolder_tab1 = () => {
    if (checkboxes.length > 0) {
      settab1(true);
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const deletefolder = () => {
    if (checkboxes.length > 0) {
      settab2(false);
      console.log(checkboxes);
      setdeletemessage(false);
      let formdata = {
        multi_Id: checkboxes,
      };
      axios
        .post("https://jlmining.app/admin/deletefolderall", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          settab3(true);
          setCheckboxes([]);
          setSelectAll(false);
          var alldata = res.data.results;
          setgetfolder(alldata);
          setdeleteerror(true);
          setTimeout(() => {
            setdeleteerror(false);
            setShowAlert(false);
            settab3(false);
          }, 4500);
        })
        .catch((err) => {});
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const checkall = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const updatedCheckboxes = getfolder.map((item, index) => ({
        id: item.id,
        isChecked: true,
      }));
      setCheckboxes(updatedCheckboxes);
      console.log(updatedCheckboxes);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange = (id, index, event) => {
    const isChecked = event.target.checked;
    let updatedCheckboxes = [...checkboxes];

    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox.id === id
    );

    if (isChecked) {
      if (checkboxIndex === -1) {
        updatedCheckboxes.push({ id, isChecked });
      } else {
        updatedCheckboxes[checkboxIndex].isChecked = isChecked;
      }
    } else {
      if (checkboxIndex !== -1) {
        updatedCheckboxes.splice(checkboxIndex, 1);
      }
    }
    setCheckboxes(updatedCheckboxes);
  };

  const downloadfolder = () => {
    if (checkboxes.length > 0) {
      console.log(checkboxes);
      let formdata = {
        folder: checkboxes,
      };
      axios
        .post("https://jlmining.app/admin/downloadfolder", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data;
          //console.log(alldata);
          downloadZipFiles(alldata);
        })
        .catch((err) => {});
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const downloadZipFiles = (foldersData) => {
    const promises = [];

    Object.keys(foldersData).forEach((folderName, index) => {
      const folderFiles = foldersData[folderName];
      const zip = new JSZip();

      folderFiles.forEach((file) => {
        var fn = folderName + "_" + file.folder_id;
        const fileURL = `https://jlmining.app/uploads/${fn}/${file.name}`;
        console.log(fileURL);
        // promises.push(
        //   fetch(fileURL)
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //       zip.file(file.name, blob);
        //     })
        //     .catch((error) => {
        //       console.error("Error fetching file:", error);
        //     })
        // );
      });

      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const fileName = `${folderName}.zip`;
          const blob = new Blob([content], { type: "application/zip" });
          saveAs(blob, fileName);
        });
      });
    });
  };
  const closetab1 = () => {
    settab1(false);
  };
  const opentab2 = () => {
    settab1(false);
    settab2(true);
  };
  const closetab2 = () => {
    settab2(false);
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-12">
                <div className="right-form-box bg-white shadow p-2 ">
                  <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                    Onboarding Document Folders
                  </h5>
                  <div className="block  px-4">
                    <div className="d-flex justify-content-between">
                      <h4 className="fs-5">Select Folder</h4>

                      <Link
                        to="javascript:void(0)"
                        onClick={openpopup}
                        className="btn btn-danger"
                      >
                        Add Folder{" "}
                        <img
                          src={require("../../assets/images/open-folder_5994725.png")}
                          width="30"
                          alt="Image"
                        />{" "}
                      </Link>
                    </div>

                    <div className="row">
                      <div className="form-check d-flex gap-2 align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectAll}
                          onChange={checkall}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label "
                          for="flexCheckDefault"
                        >
                          Select All
                        </label>
                        <Link to="javascript:void(0)" onClick={downloadfolder}>
                          Download
                        </Link>
                        <Link
                          to="javascript:void(0)"
                          onClick={deletefolder_tab1}
                        >
                          Delete
                        </Link>
                      </div>
                    </div>
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="Folder has been created successfully"
                        closable
                      />
                    )}

                    {deleteerror && (
                      <span className="text-danger">
                        Folder has been deleted successfully
                      </span>
                    )}
                    {deletemessage && (
                      <span className="text-danger mt-4">
                        Please choose atleast one folder
                      </span>
                    )}
                    <div className="row mt-4 scroll-text-box">
                      {Array.isArray(getfolder) && getfolder.length > 0 ? (
                        getfolder.map((item, index) => (
                          <div
                            className="form-check d-flex gap-3 align-items-start border-bottom py-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(event) =>
                                handleCheckboxChange(
                                  item.id,
                                  checkboxes.findIndex(
                                    (checkbox) => checkbox.id === item.id
                                  ),
                                  event
                                )
                              }
                              checked={
                                (checkboxes.find(
                                  (checkbox) => checkbox.id === item.id
                                ) &&
                                  checkboxes.find(
                                    (checkbox) => checkbox.id === item.id
                                  ).isChecked) ||
                                false
                              }
                              key={index}
                              id={`folderCheckbox_${index}`}
                            />
                            <Link to={`/admin/onboardingfiles/${item.id}`}>
                              <img
                                src={require("../../assets/images/open-folder_5994725.png")}
                                width="30"
                                alt="Image"
                              />{" "}
                              {item.name}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>No data available</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {tab1 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/3997872.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Confirmation</h4>
                              <span>
                                <b>
                                  Are You Sure You Want To Delete This Folder
                                </b>
                              </span>
                              <br />
                              <span
                                className="color-grey"
                                style={{ fontSize: "12px" }}
                              >
                                If you delete this folder, you might lose the
                                files in it <br />
                                and won't able to retrieve them again.
                                <br />
                                Think twice before deleting the folder or files.
                              </span>
                              <div className="d-flex justify-content-between ">
                                <button
                                  onClick={opentab2}
                                  type="button"
                                  className=" btn btn-danger"
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  onClick={closetab1}
                                  className=" btn btn-success"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {tab2 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/2959016.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Confirmation</h4>
                              <span>
                                <b>Still You have chance by clicking No</b>
                              </span>

                              <div className="d-flex justify-content-between ">
                                <button
                                  type="button"
                                  onClick={closetab2}
                                  className=" btn btn-success"
                                >
                                  No
                                </button>
                                <button
                                  type="button"
                                  className=" btn btn-danger"
                                  onClick={deletefolder}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {tab3 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/2441914.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Deleted</h4>
                              <span>
                                You have successfully deleted the file or folder{" "}
                                <br />
                                that you don't want
                              </span>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {isOpen && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        modalWidth="50%"
                        headerBackgroundColor="red"
                        headerTextColor="white"
                        headerHeight="40"
                        closeButtonColor="white"
                        bodyBackgroundColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                        headerText="Add Folder"
                      >
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white inperson mt-4">
                            {showAlert?.type === "error" && (
                              <span className="text-danger">
                                This folder is already exists
                              </span>
                            )}
                            <form
                              action="javascript:void(0)"
                              onSubmit={handleSubmit}
                              method="post"
                            >
                              <div className="d-flex flex-column gap-2">
                                <div className="mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Create Folder
                                  </label>
                                  <input
                                    type="text"
                                    name="folder"
                                    required
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter here..."
                                  />
                                </div>
                                <div className="mb-3">
                                  <button
                                    type="submit"
                                    style={{ background: "#F71701 !important" }}
                                    className={`btn btn-danger${
                                      docUp1 === true ? "docup" : ""
                                    }`}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}

                  <span className="fs-20 text-center"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
