import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
//import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Modal from "react-modal";
import Select from "react-select";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, Link, useParams } from "react-router-dom";
import "./assets/landing/fonts/all.min.css";
import "./assets/landing/main.css";
import "bootstrap/dist/js/bootstrap.js";
export const Landingpage = () => {
  const passworderr = useRef();
  const [textmessage, settextmessage] = useState(false);
  const [textmessages, settextmessages] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [UserId, setUserId] = useState("");
  const [mainimage, setmainimage] = useState("");
  const [userdetail, setuserdetail] = useState("");
  const emailerr = useRef();
  const { id } = useParams();
  const [allrecord, setallrecord] = useState("");
  const [tab1, settab1] = useState("tab1");
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;
  const placeholderText = "Type here...";
  const [loaderfirst, setloaderfirst] = useState(false);
  const [copylink, setcopylink] = useState(false);
  const [mainSection, setmainSection] = useState(true);
  const [sentMailForm, setsentMailForm] = useState(true);
  const [allcountry, setallcountry] = useState([]);
  //Step1
  const [Url_Step1, setUrl_Step1] = useState("");
  const [readyValue, setReadyValue] = useState("");
  const [checkbuttonStep1, setcheckbuttonStep1] = useState("0");
  const [TextCopyClipboard, setTextCopyClipboard] = useState("");
  //Step1

  //
  const [isemail, setemail] = useState("Email does not match");
  const [issstep2_title, setstep2_title] = useState("");
  const [isstep2_shirt_size, setstep2_shirt_size] = useState("");
  const [isstep2_postal_country, setisstep2_postal_country] = useState("");
  const [isstep2_postal_state, setisstep2_postal_state] = useState("");
  const [optioncountryfirst, setoptioncountryfirst] = useState("");
  const [optionstatefirst, setoptionstatefirst] = useState("");
  const [optionorigin, setoptionorigin] = useState("");
  const [genderOptions, setgenderOptions] = useState("");
  const [erroremail, seterroremail] = useState(false);
  const [errorpassword, seterrorpassword] = useState(false);
  const [step2_Postaladdress, setstep2_Postaladdress] = useState(false);
  const [auscitizenNo, setauscitizenNo] = useState(false);
  const [auscitizenYes, setauscitizenYes] = useState(false);

  const [auscitizenYesBirth, setauscitizenYesBirth] = useState(false);
  const [auscitizenYesPassport, setauscitizenYesPassport] = useState(false);
  const [auscitizenYesCert, setauscitizenYesCert] = useState(false);

  const [isbirthcheck, setisbirthcheck] = useState("");
  const [ispassportcheck, setispassportcheck] = useState("");
  const [iscertificatecheck, setiscertificatecheck] = useState("");

  const [islegalwork, setislegalwork] = useState("");
  const [islegalworkdesc, setislegalworkdesc] = useState(false);
  const [isresidentalAddress, setresidentalAddress] = useState("");
  const [isSelectedEmployeePhoto, setSelectedEmployeePhoto] = useState(null);
  const [isselectcountrybirth, setselectcountrybirth] = useState(null);
  const [isSelectedpermanentAddress, setSelectedpermanentAddress] =
    useState("");
  const [isSelectedPermanentpassport, setSelectedPermanentpassport] =
    useState(null);
  const [selectedProofs, setSelectedProofs] = useState([]);
  const [isBirthcertificate, setBirthcertificate] = useState(null);
  const [isPassportcertificate, setPassportcertificate] = useState(null);
  const [iscertificatefile, setcertificatefile] = useState(null);
  const [iscriminaloffensenses, setcriminaloffensenses] = useState("");
  const [isservedtime, setservedtime] = useState("");
  const [isdefenceforced, setdefenceforced] = useState("");
  const [isdayshift, setdayshift] = useState("");
  const [isnightshift, setnightshift] = useState("");
  const [selectedEmployetype, setselectedEmployetype] = useState([]);
  const [formValues, setFormValues] = useState({
    step2_title: allrecord.step2_title || "",
    first_name: allrecord.first_name || "",
    last_name: "",
    contact: "",
    step2_gender: "",
    step2_origin: "",
    email: "",
    step2_confirm_email: "",
    password: "",
    step2_confirm_password: "",
    address: "",
    step2_address: "",
    step2_city: "",
    step2_state: "",
    step2_Postal: "",
    step2_country: "",
    step2_postal_address: "",
    step2_postal_address2: "",
    step2_postal_city: "",
    step2_postal_state: "",
    step2_postal_code: "",
    step2_postal_country: "",
    step2_dob: "",
    step2_country_birth: "",
    step2_proof_upload: "",
    step2_available_date: "",
    step2_shirt_size: "",
  });
  const [contactstep2, setContactstep2] = useState("");
  const [invalidnumberstep2, setinvalidnumberstep2] = useState("");
  const [invalidnumberstep3, setinvalidnumberstep3] = useState("");
  //Step2

  //Step3
  const [invalidnumberstep3_alter, setinvalidnumberstep3_alter] = useState("");
  const [optioncountrythird, setoptioncountrythird] = useState("");
  const [isstep3_postal_state, setisstep3_postal_state] = useState("");
  const [formValues_Step3, setFormValues_Step3] = useState({
    step3_title: "",
    step3_first_name: "",
    step3_last_name: "",
    step3_relationship: "",
    step3_contact: "",
    step3_mobile_number: "",
    step3_phone_number: "",
    step3_address: "",
    step3_address2: "",
    step3_city: "",
    step3_state: "",
    step3_postal: "",
    step3_country: "",
  });
  const [issstep3_title, setissstep3_title] = useState("");
  const [isstep3_contact, setisstep3_contact] = useState("");
  const [contactstep3, setContactstep3] = useState("");
  const [contactstep3_alter, setContactstep3_alter] = useState("");
  //Step3

  //Step 5
  const [reviewApplicationform, setreviewApplicationform] = useState(false);
  const [checkwork, setcheckwork] = useState("");
  const [typecheck, settypecheck] = useState("");
  const [rostertype, setrostertype] = useState("");
  const [machinetextArrayy, setmachinetextArrayy] = useState([]);
  const [tradetextArray, settradetextArray] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [licenseArray, setlicenseArray] = useState([]);
  const [selectcertmsg, setselect_certmsg] = useState("");
  const [setselect_l_msg, setsetselect_l_msg] = useState("");
  const [selectedlicenec_File, setSelectedlicenec_File] = useState(null);
  const [selectvalue_skill, setselectvalue_skill] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [MentionLic, setSelectedMentionLic] = useState([]);
  const [MentionLCert, setSelectedMentionCert] = useState([]);
  const [MentionLCert_m, setSelectedMentionCert_m] = useState([]);
  const [trademach, setSelectedTrade] = useState([]);
  const [Vocationtra, setSelectedVocationtra] = useState([]);
  const [machineryget, setSelectedmachinery] = useState([]);
  const [Equipmentwork, setSelectedEquipmentwork] = useState([]);
  const [prevwork, setSelectedprevwork] = useState([]);
  const [sgetreferences, setSelectedreferences] = useState([]);
  const [MentionLic_get, setMentionLic_get] = useState([]);
  const [selectvalue_mentionl, setselectvalue_mentionl] = useState([]);
  const [MentionCertt_get, setMentionCertt_get] = useState([]);
  const [selectvalue_mentionc, setselectvalue_mentionc] = useState([]);
  const [selectvalue_trade, setselectvalue_trade] = useState([]);
  const [selectvalue_machinery, setselectvalue_machinery] = useState([]);
  const [selectvalue_vocational_training, setselectvalue_vocational_training] =
    useState([]);
  const [selectvalue_equipment_work, setselectvalue_equipment_work] = useState(
    []
  );
  const [selectedcertificate_file, setSelectedcertificate_file] =
    useState(null);
  const [setselect_t_msg, setsetselect_t_msg] = useState("");
  const [selectedtrade_file, setSelectedtrade_file] = useState(null);
  const [setselect_m_msg, setsetselect_m_msg] = useState("");
  const [selectedmachinery_file, setSelectedmachinery_file] = useState(null);
  const [formValues_step5, setFormValues_step5] = useState({
    years: "",
  });
  //Step 5
  const apiurl = "https://jlmining.app/uploads/";
  useEffect(() => {
    //Get Country
    axios
      .get("https://jlmining.app/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcountry(res.data.results);
      })
      .catch((err) => {});
    let formdata = {
      user_id: 1,
    };
    axios
      .post("https://jlmining.app/getskills", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });

    //Mention Licence
    axios
      .post("https://jlmining.app/getmentionlicence", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionLic(res.data.results);
      })
      .catch((err) => {});
    //Mention certificate
    axios
      .post("https://jlmining.app/getmentioncertificate", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionCert(res.data.results);
      })
      .catch((err) => {});
    //Trade
    axios
      .post("https://jlmining.app/gettrade", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedTrade(res.data.results);
      })
      .catch((err) => {});

    //Vocational Traninig
    axios
      .post("https://jlmining.app/getvocationaltra", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedVocationtra(res.data.results);
      })
      .catch((err) => {});
    //Equipment Worked
    axios
      .post("https://jlmining.app/getequipmentwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedEquipmentwork(res.data.results);
      })
      .catch((err) => {});

    //Previous Work
    axios
      .post("https://jlmining.app/getprevwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedprevwork(res.data.results);
      })
      .catch((err) => {});

    //Machinery
    axios
      .post("https://jlmining.app/getmachinery", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedmachinery(res.data.results);
      })
      .catch((err) => {});

    //References
    axios
      .post("https://jlmining.app/getreferences", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("refer");
        //console.log(res.data.results);
        setSelectedreferences(res.data.results);
      })
      .catch((err) => {});
    if (id !== undefined) {
      getallrecord();
      // console.log(formValues);
    }
  }, [id]);

  //Form Step 1

  const handleSubmitstep1 = (event) => {
    event.preventDefault();
    var vll = event.target;
    settab1("tab2");
  };
  function getallrecord() {
    if (id !== undefined) {
      let formdata = {
        unique_code: id,
      };
      axios
        .post("https://jlmining.app/getuserrecord", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data.status);
          if (res.data.status.length > 0) {
            var ress = res.data.status[0];

            setallrecord(ress);

            const url = "https://jlmining.app/uploads/";
            setmainimage(url + ress.image);
            setstep2_title(ress.step2_title);
            setisstep2_postal_state(ress.step2_postal_state);
            setisstep2_postal_country(ress.step2_postal_country);
            setgenderOptions(ress.step2_gender);
            setoptionorigin(ress.step2_origin);
            setoptionstatefirst(ress.step2_state);
            setoptioncountryfirst(ress.step2_country);
            setresidentalAddress(ress.step2_residential_address);
            if (ress.step2_residential_address === "No") {
              setstep2_Postaladdress(true);
            }
            if (ress.step2_residential_address === "Yes") {
              setstep2_Postaladdress(false);
            }

            setReadyValue(ress.proceed);
            setselectcountrybirth({
              value: ress.step2_country_birth,
              label: ress.step2_country_birth,
            });
            if (ress.step2_proof_upload) {
              const proofs = ress.step2_proof_upload
                .split(",")
                .map((item) => item.trim());
              const hasBirthCertificate = proofs.includes("Birth Certificate");
              const hasPassport = proofs.includes("Passport");
              const hasCitizenship = proofs.includes("Citizenship Certificate");
              if (hasBirthCertificate === true) {
                setauscitizenYesBirth(true);
              }
              if (hasPassport === true) {
                setauscitizenYesPassport(true);
              }
              if (hasCitizenship === true) {
                setauscitizenYesCert(true);
              }
              //console.log(hasBirthCertificate);
              setSelectedProofs(proofs);
            }
            setislegalwork(ress.step2_legal_work);
            if (ress.step2_legal_work === "No") {
              setislegalworkdesc(true);
            } else {
              setislegalworkdesc(false);
            }
            setContactstep2(ress.contact);
            setcriminaloffensenses(ress.step2_criminal_offenses);
            setservedtime(ress.step2_served_time);
            setdefenceforced(ress.step2_defence_forced);
            setstep2_shirt_size(ress.step2_shirt_size);
            setdayshift(ress.step2_which_dayshift);
            setnightshift(ress.step2_which_nightshift);
            if (ress.step2_employment_type) {
              const proofs = ress.step2_employment_type
                .split(",")
                .map((item) => item.trim());
              setselectedEmployetype(proofs);
            }
            setSelectedpermanentAddress(ress.step2_permanent_address);
            //console.log(ress.step2_permanent_address);
            if (ress.step2_permanent_address !== "") {
              // console.log("sss");
              if (ress.step2_permanent_address === "Yes") {
                setauscitizenYes(true);
                setauscitizenNo(false);
              }
              if (ress.step2_permanent_address === "No") {
                setauscitizenYes(false);
                setauscitizenNo(true);
              }
            }

            setSelectedEmployeePhoto(ress.image);
            setBirthcertificate(ress.step2_birthcertificate_file);
            setPassportcertificate(ress.step2_passportcertificate_file);
            setcertificatefile(ress.step2_auscitizencertificate_file);
            setSelectedPermanentpassport(ress.step2_passport);
            setFormValues({
              step2_title: ress.step2_title || "",
              first_name: ress.first_name || "",
              last_name: ress.last_name || "",
              contact: ress.contact || "",
              step2_gender: ress.step2_gender || "",
              email: ress.email || "",
              step2_confirm_email: ress.step2_confirm_email || "",
              password: ress.password || "",
              step2_confirm_password: ress.step2_confirm_password || "",
              address: ress.address || "",
              step2_address: ress.step2_address || "",
              step2_city: ress.step2_city || "",
              step2_Postal: ress.step2_Postal || "",
              step2_postal_address: ress.step2_postal_address || "",
              step2_postal_address2: ress.step2_postal_address2 || "",
              step2_postal_city: ress.step2_postal_city || "",
              step2_postal_code: ress.step2_postal_code || "",
              step2_dob: ress.step2_dob || "",
              step2_available_date: ress.step2_available_date || "",
            });
            setContactstep3(ress.step3_mobile_number);
            setContactstep3_alter(ress.step3_phone_number);
            setFormValues_Step3({
              step3_title: ress.step3_title || "",
              step3_first_name: ress.step3_first_name || "",
              step3_last_name: ress.step3_last_name || "",
              step3_relationship: ress.step3_relationship || "",

              step3_address: ress.step3_address || "",
              step3_address2: ress.step3_address2 || "",
              step3_city: ress.step3_city || "",

              step3_postal: ress.step3_postal || "",
              step3_country: ress.step3_country || "",
            });
            setFormValues_step5({
              years: ress.years || "",
            });
            if (ress.employmentHistorySections !== null) {
              setEmploymentHistorySections(
                JSON.parse(ress.employmentHistorySections)
              );
            }
            setissstep3_title(ress.step3_title);
            setisstep3_contact(ress.step3_contact);
            setisstep3_postal_state(ress.step3_state);
            setoptioncountrythird(ress.step3_country);

            if (ress.education !== null) {
              setEmploymenteducation(JSON.parse(ress.education));
            }
            if (ress.skills !== null) {
              const inputString = JSON.parse(ress.skills);
              const optionss = inputString.map((item) => ({
                value: item,
                label: item,
              }));

              setselectvalue_skill(optionss);
            }
            if (ress.licence !== null) {
              const mentionss = JSON.parse(ress.licence);
              const options_m = mentionss.map((item) => ({
                value: item,
                label: item,
              }));
              setMentionLic_get(options_m);
            }
            if (ress.certificate !== null) {
              const edd = JSON.parse(ress.certificate);
              const options_m = edd.map((item) => ({
                value: item,
                label: item,
              }));
              setSelectedMentionCert_m(options_m);
            }
            if (ress.trade !== null) {
              const sssvt = JSON.parse(ress.trade);
              const options_m = sssvt.map((item) => ({
                value: item,
                label: item,
              }));
              setselectvalue_trade(options_m);
            }
            if (ress.machinery !== null) {
              const sssvm = JSON.parse(ress.machinery);

              const options_m = sssvm.map((item) => ({
                value: item,
                label: item,
              }));
              setselectvalue_machinery(options_m);
            }
            if (ress.vocational_training !== null) {
              const sssv = JSON.parse(ress.vocational_training);
              const options_m = sssv.map((item) => ({
                value: item,
                label: item,
              }));
              setselectvalue_vocational_training(options_m);
            }
            if (ress.equipment_work !== null) {
              const eqp = JSON.parse(ress.equipment_work);
              const options_m = eqp.map((item) => ({
                value: item,
                label: item,
              }));
              setselectvalue_equipment_work(options_m);
            }
            if (ress.licence_file !== null) {
              const resultArrays = JSON.parse(ress.licence_file);
              setlicenseArray(resultArrays);
            }
            if (ress.certificate_file !== null) {
              const edd = JSON.parse(ress.certificate_file);
              setedfile(edd);
            }
            if (ress.trade_file !== null) {
              const sssvt = JSON.parse(ress.trade_file);
              settradetextArray(sssvt);
            }
            if (ress.machinery_file !== null) {
              const eddm = JSON.parse(ress.machinery_file);
              setmachinetextArrayy(eddm);
            }
            setuserdetail(ress);
            setUserId(ress.id);
          }
        })
        .catch((err) => {});
    }
  }
  //console.log("f");

  //console.log(formValues);
  const handleRadioChange = (event) => {
    setReadyValue(event.target.value);
  };
  const savelaterStep1 = () => {
    setloaderfirst(true);
    setmainSection(false);

    let formdataa = {
      proceed: readyValue,
    };
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step1", formdataa, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var url = "http://localhost:3000/register/" + res.data.code;
          console.log(url);
          setUrl_Step1(url);
          //  console.log(url);
          setcopylink(true);
          setloaderfirst(false);
        })
        .catch((err) => {});
    }, 1500);
  };
  const handlesubmitsaveLater = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdataa = {
      url: Url_Step1,
      sentemail: vll.sentemail.value,
    };
    //console.log(formdataa);
    axios
      .post("https://jlmining.app/sentemail_step1", formdataa, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "1") {
          setsentMailForm(false);
        }
      })
      .catch((err) => {});
  };
  const copyClipboard = () => {
    navigator.clipboard
      .writeText(Url_Step1)
      .then(() => {
        setTextCopyClipboard("Copied to clipboard.");
      })
      .catch((err) => {
        setTextCopyClipboard("Failed to copy: ", err);
        console.error("Failed to copy: ", err);
      });
    setTimeout(function () {
      setTextCopyClipboard("");
    }, 2500);
  };
  const nextStep1 = () => {
    setcheckbuttonStep1("1");
  };
  //Form Step 1

  //Form Step 2
  const previousstep = (id) => {
    ///console.log(id);
    settab1(id);
  };
  const handlesubmitStep2 = (event) => {
    event.preventDefault();
    var vll = event.target;

    const formattedValue = formatNumber(contactstep2);

    if (isValidFormat(formattedValue)) {
      setContactstep2(formattedValue);
      setinvalidnumberstep2("");
    } else {
      setinvalidnumberstep2("Invalid formate");
      return false;
    }
    if (vll.email.value !== vll.step2_confirm_email.value) {
      seterroremail(true);
      setTimeout(() => {
        emailerr.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
      return false;
    } else {
      seterroremail(false);
    }
    if (vll.step2_confirm_password.value !== vll.password.value) {
      seterrorpassword(true);
      setTimeout(() => {
        passworderr.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
      return false;
    } else {
      seterrorpassword(false);
    }

    settab1("tab3");
  };
  const residentalAddress = (event) => {
    setresidentalAddress(event);
    if (event === "Yes") {
      setstep2_Postaladdress(false);
    }
    if (event === "No") {
      setstep2_Postaladdress(true);
    }
  };
  const citizenaus = (event) => {
    setSelectedpermanentAddress(event);
    if (event === "Yes") {
      setauscitizenYes(true);
      setauscitizenNo(false);
    }
    if (event === "No") {
      setauscitizenYes(false);
      setauscitizenNo(true);
      setauscitizenNo(false);
    }
  };
  const birthcheck = (event) => {
    var value = "Birth Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setisbirthcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesBirth(true);
    } else {
      setauscitizenYesBirth(false);
    }
  };
  const passportcheck = (event) => {
    var value = "Passport";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setispassportcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesPassport(true);
    } else {
      setauscitizenYesPassport(false);
    }
  };
  const certificatecheck = (event) => {
    var value = "Citizenship Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setiscertificatecheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesCert(true);
    } else {
      setauscitizenYesCert(false);
    }
  };
  const employetype = (event) => {
    var value = event;
    if (selectedEmployetype.includes(value)) {
      // Remove value if already selected
      setselectedEmployetype(
        selectedEmployetype.filter((item) => item !== value)
      );
    } else {
      // Add value if not selected
      setselectedEmployetype([...selectedEmployetype, value]);
    }
  };
  const legalwork = (event) => {
    setislegalwork(event.target.value);
    //console.log(event.target.checked);
    if (event.target.value === "Yes") {
      setislegalworkdesc(false);
    } else {
      setislegalworkdesc(true);
    }
  };
  const handleEmployeephoto = (event) => {
    const file = event.target.files[0];

    setSelectedEmployeePhoto(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setmainimage(reader.result); // Set the main image to display the selected image
    };
    reader.readAsDataURL(file);
  };
  const handlePermanentpassport = (event) => {
    setSelectedPermanentpassport(event.target.files[0]);
  };
  const handleSelectCountryBirth = (selectedOptions) => {
    //console.log(selectedOptions);
    setselectcountrybirth(selectedOptions);
  };
  const handlebirthcertificate = (event) => {
    setBirthcertificate(event.target.files[0]);
  };
  const handlepassportcertificate = (event) => {
    setPassportcertificate(event.target.files[0]);
  };
  const handlecertificatefile = (event) => {
    setcertificatefile(event.target.files[0]);
  };
  const criminaloffensenses = (event) => {
    setcriminaloffensenses(event);
  };
  const servedtime = (event) => {
    setservedtime(event);
  };
  const defenceforced = (event) => {
    setdefenceforced(event);
  };
  const dayshift = (event) => {
    setdayshift(event);
  };
  const nightshift = (event) => {
    setnightshift(event);
  };
  const handlegenderOptions = (event) => {
    //console.log(event.target.value);
    setgenderOptions(event.target.value);
  };
  const handleoptionorigin = (event) => {
    setoptionorigin(event.target.value);
  };
  const handleoptionstatefirst = (event) => {
    setoptionstatefirst(event.target.value);
  };
  const handleoptioncountryfirst = (event) => {
    setoptioncountryfirst(event.target.value);
  };
  const handlestep2_postal_state = (event) => {
    setisstep2_postal_state(event.target.value);
  };
  const handlestep2_postal_country = (event) => {
    setisstep2_postal_country(event.target.value);
  };
  const handlestep2_shirt_size = (event) => {
    setstep2_shirt_size(event.target.value);
  };
  const handlestep2_title = (event) => {
    setstep2_title(event.target.value);
  };

  const handleInputChangeStep2 = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const savelaterStep2 = () => {
    setloaderfirst(true);
    setmainSection(false);
    const formData = new FormData();

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );

    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);
    formData.append("unique_code", id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step2", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data.code);
          var url = "http://localhost:3000/register/" + res.data.code;
          setUrl_Step1(url);
          //  console.log(url);
          setcopylink(true);
          setloaderfirst(false);
        })
        .catch((err) => {});
    }, 1500);
  };
  function formatDateForState(dateString) {
    if (!dateString) return ""; // Handle empty values

    // Create a new Date object from the string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.warn("Invalid date format provided:", dateString);
      return ""; // Handle invalid dates gracefully (optional)
    }
    // Format the date in YYYY-MM-DD using template literals
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
    var dd = `${month}/${day}/${year}`;
    //console.log(dd);
    return `${year}-${month}-${day}`;
  }
  const isImageValid = (image) => {
    return image !== null && image !== undefined && image !== "";
  };

  const formatNumber = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Apply formatting: #### ### ###
    const match = cleaned.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(" ");
    }
    return number;
  };

  const handleInputmobiletsep2 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      // Remove non-digit characters and limit the length to 12 digits
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep2(formattedValue);
      console.log(formattedValue);
      if (isValidFormat(cleanedValue)) {
        // Valid format logic (e.g., enable submit button)
        console.log("Valid format");
        setinvalidnumberstep2("");
      } else {
        // Invalid format logic (e.g., disable submit button)
        setinvalidnumberstep2("Invalid format");
        console.log("Invalid format");
      }
    }
  };

  const handleKeyDownstep2 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep2.length > 0) {
        setContactstep2((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const isValidFormat = (number) => {
    // Remove all non-digit characters and spaces
    const cleaned = number.replace(/\D/g, "");
    console.log(cleaned);
    // Check if the cleaned number matches the format #### ### ###
    return /^\d{4} ?\d{3} ?\d{3}$/.test(cleaned);
  };
  //Form Step 2

  //Form Step 3
  const handleInputMobilephone_step3 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3("");
      } else {
        setinvalidnumberstep3("Invalid format");
      }
    }
  };
  const handleKeyDownstep3 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3.length > 0) {
        setContactstep3((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const handleInputMobilephone_step3__alter = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3_alter(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3_alter("");
      } else {
        setinvalidnumberstep3_alter("Invalid format");
      }
    }
  };
  const handleKeyDownstep3_alter = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3_alter.length > 0) {
        setContactstep3_alter((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const savelaterStep3 = (event) => {
    setloaderfirst(true);
    setmainSection(false);
    const formData = new FormData();

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );

    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);

    formData.append("unique_code", id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step3", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          var url = "http://localhost:3000/register/" + res.data.code;
          setUrl_Step1(url);
          //  console.log(url);
          setcopylink(true);
          setloaderfirst(false);
        })
        .catch((err) => {});
    }, 1500);
  };
  const handlestep3_title = (event) => {
    setissstep3_title(event.target.value);
  };
  const handleInputChangeStep3 = (e) => {
    const { name, value } = e.target;
    setFormValues_Step3({
      ...formValues_Step3,
      [name]: value,
    });
  };
  const handlestep3_postal_state = (event) => {
    setisstep3_postal_state(event.target.value);
  };
  const handlestep3_contact = (event) => {
    setisstep3_contact(event.target.value);
  };
  const handleoptioncountryfirstthird = (event) => {
    setoptioncountrythird(event.target.value);
  };
  const handlesubmitStep3 = (event) => {
    const formattedValue = formatNumber(contactstep3);

    if (isValidFormat(formattedValue)) {
      setContactstep3(formattedValue);
      setinvalidnumberstep3("");
    } else {
      setinvalidnumberstep3("Invalid formate");
      return false;
    }
    const formattedValue_alter = formatNumber(contactstep3_alter);

    if (isValidFormat(formattedValue_alter)) {
      setContactstep3(formattedValue_alter);
      setinvalidnumberstep3_alter("");
    } else {
      setinvalidnumberstep3_alter("Invalid formate");
      return false;
    }
    settab1("tab4");
  };
  //Form Step

  //Form Step 4
  const [employmentHistorySections, setEmploymentHistorySections] = useState([
    {
      start_date: "",
      end_date: "",
      company: "",
      role: "",
      company_done: "",
    },
  ]);

  const handleInputChange = (index, name, value) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections[index][name] = value;
    setEmploymentHistorySections(updatedSections);
  };

  const addMoreDiv = () => {
    setEmploymentHistorySections([
      ...employmentHistorySections,
      {
        start_date: "",
        end_date: "",
        company: "",
        role: "",
        company_done: "",
      },
    ]);
  };
  const trashdiv = (index) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections.splice(index, 1);
    setEmploymentHistorySections(updatedSections);
  };
  const handleSubmitstep4 = (event) => {
    settab1("tab5");
  };
  const savelaterStep4 = (event) => {
    const formData = new FormData();
    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );
    setloaderfirst(true);
    setmainSection(false);

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );

    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);

    formData.append("unique_code", id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step4", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          var url = "http://localhost:3000/register/" + res.data.code;
          setUrl_Step1(url);
          //  console.log(url);
          setcopylink(true);
          setloaderfirst(false);
        })
        .catch((err) => {});
    }, 1500);
  };
  //Form Step 4

  //Form Step 5
  const handleSelectChange = (selectedOptions) => {
    // console.log(selectedOptions);
    setselectvalue_skill(selectedOptions);
  };
  const options = selectedOptions;
  const TradeM = trademach;
  const machinerygett = machineryget;
  const Vocationtraa = Vocationtra;
  const Equipmentworkk = Equipmentwork;
  const handleSelectChange_mentionl = (selectedOptions) => {
    setselectvalue_mentionl(selectedOptions);
    setMentionLic_get(selectedOptions);
  };
  const handleSelectChange_mentionc = (selectedOptions) => {
    setselectvalue_mentionc(selectedOptions);
    setSelectedMentionCert_m(selectedOptions);
  };
  const handleSelectChange_trade = (selectedOptions) => {
    setselectvalue_trade(selectedOptions);
  };
  const handleSelectChange_machinery = (selectedOptions) => {
    setselectvalue_machinery(selectedOptions);
  };
  const handleSelectChange_vocational_training = (selectedOptions) => {
    setselectvalue_vocational_training(selectedOptions);
  };
  const handleSelectChange_equipment_work = (selectedOptions) => {
    setselectvalue_equipment_work(selectedOptions);
  };
  const [employmenteducation, setEmploymenteducation] = useState([
    {
      year: "",
      course: "",
      institution: "",
    },
  ]);
  const handleInputChange_edication = (index, name, value) => {
    const updatedSections = [...employmenteducation];
    updatedSections[index][name] = value;
    setEmploymenteducation(updatedSections);
  };
  const addMoreDivEducation = () => {
    setEmploymenteducation([
      ...employmenteducation,
      {
        year: "",
        course: "",
        institution: "",
      },
    ]);
  };
  const trashdiveducation = (index) => {
    const updatedSections = [...employmenteducation];
    updatedSections.splice(index, 1);
    setEmploymenteducation(updatedSections);
  };
  const handlelicense_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSize > maxSize) {
        setsetselect_l_msg(
          "The total size of selected files exceeds the maximum allowed size (5MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedlicenec_File(null);
      } else {
        setsetselect_l_msg("");
        setSelectedlicenec_File(selectedFiles);
      }
    }
  };
  const handlecertificate_File = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setselect_certmsg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedcertificate_file(null);
      } else {
        setselect_certmsg("");
        setSelectedcertificate_file(selectedFiles);
      }
    }
  };
  const handletrade_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_t_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedtrade_file(null);
      } else {
        setsetselect_t_msg("");
        setSelectedtrade_file(selectedFiles);
      }
    }
  };
  const handlemachinery_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB
      console.log(maxSize);

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_m_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedmachinery_file(null);
      } else {
        setsetselect_m_msg("");
        setSelectedmachinery_file(selectedFiles);
      }
    }
  };
  const handlesubmitstep5 = (event) => {
    settab1("tab6");
  };
  const savelaterStep5 = (event) => {
    const formData = new FormData();
    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );
    setloaderfirst(true);
    setmainSection(false);

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );
    const skidata = [];
    if (selectvalue_skill.length > 0) {
      for (let i = 0; i < selectvalue_skill.length; i++) {
        skidata.push(selectvalue_skill[i].value);
      }
    }
    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }
    const selectvaluementionll = [];
    if (MentionLic_get !== null) {
      if (MentionLic_get.length > 0) {
        for (let i = 0; i < MentionLic_get.length; i++) {
          selectvaluementionll.push(MentionLic_get[i].value);
        }
      }
    }
    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }
    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    const selectvaluementionc = [];
    if (MentionLCert_m !== null) {
      if (MentionLCert_m.length > 0) {
        for (let i = 0; i < MentionLCert_m.length; i++) {
          selectvaluementionc.push(MentionLCert_m[i].value);
        }
      }
    }
    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }
    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("certificate", selectvaluementionc);
    formData.append("education", JSON.stringify(employmenteducation));
    formData.append("trade", selectvaluetrade);
    formData.append("licence", selectvaluementionll);
    formData.append("machinery", selectvaluemachinery);
    formData.append("skills", skidata);
    formData.append("years", formValues_step5.years);
    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);
    formData.append("unique_code", id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step5", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          var url = "http://localhost:3000/register/" + res.data.code;
          setUrl_Step1(url);
          //  console.log(url);
          setcopylink(true);
          setloaderfirst(false);
        })
        .catch((err) => {});
    }, 1500);
  };
  const handleInputChangeStep5 = (e) => {
    const { name, value } = e.target;
    setFormValues_step5({
      ...formValues_step5,
      [name]: value,
    });
  };
  console.log("df");
  console.log(options);
  const removemention = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemention", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.licence_file !== null) {
            const resultArrays = JSON.parse(alldata.licence_file);
            setlicenseArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removecertificate = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removecertificate", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.certificate_file != null) {
            const resultArrays = JSON.parse(alldata.certificate_file);
            setedfile(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removetrade = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removetrade", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.trade_file != null) {
            const resultArrays = JSON.parse(alldata.trade_file);
            settradetextArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removemachine = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemachine", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.machinery_file != null) {
            const resultArrays = JSON.parse(alldata.machinery_file);
            setmachinetextArrayy(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const checkrostertype = (event) => {
    console.log(event.target.value);
    setrostertype(event.target.value);
  };
  const handletypecheck = (event) => {
    console.log(event.target.value);
    settypecheck(event.target.value);
  };
  const handlecheckwork = (event) => {
    console.log(event.target.value);
    setcheckwork(event.target.value);
  };
  const handlesubmitlastoption = () => {
    setreviewApplicationform(true);
  };
  //Form Step 5

  //Final Submission Form
  const handleFormFinalsubmit = () => {
    const formData = new FormData();
    //setButtonDisabled(false);
    //settextmessage(true);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );
    const skidata = [];
    if (selectvalue_skill.length > 0) {
      for (let i = 0; i < selectvalue_skill.length; i++) {
        skidata.push(selectvalue_skill[i].value);
      }
    }
    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }
    const selectvaluementionll = [];
    if (MentionLic_get !== null) {
      if (MentionLic_get.length > 0) {
        for (let i = 0; i < MentionLic_get.length; i++) {
          selectvaluementionll.push(MentionLic_get[i].value);
        }
      }
    }
    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }
    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    const selectvaluementionc = [];
    if (MentionLCert_m !== null) {
      if (MentionLCert_m.length > 0) {
        for (let i = 0; i < MentionLCert_m.length; i++) {
          selectvaluementionc.push(MentionLCert_m[i].value);
        }
      }
    }
    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }
    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("certificate", selectvaluementionc);
    formData.append("education", JSON.stringify(employmenteducation));
    formData.append("trade", selectvaluetrade);
    formData.append("licence", selectvaluementionll);
    formData.append("machinery", selectvaluemachinery);
    formData.append("skills", skidata);
    formData.append("years", formValues_step5.years);
    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);
    formData.append("unique_code", id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/finalformsubmission", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code === "2") {
            settab1("tab2");
            seterroremail(true);
            setemail("Email is already exists");
            setTimeout(() => {
              emailerr.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          } else {
            setemail("");
            settextmessages(true);
            var url = "http://localhost:3000/register/" + res.data.code;
            setButtonDisabled(false);
            settextmessage(false);
            setTimeout(function () {
              settextmessages(false);
              window.location.href = "/login";
            }, 500);
          }
        })
        .catch((err) => {});
    }, 500);
  };
  //Final Submission Form
  return (
    <>
      {loaderfirst && (
        <div className="jl-loader d-flex flex-column justify-content-center align-items-center gap-5">
          <div className="d-block">
            <img
              className="w-100 h-100"
              src={require("./assets/landing/images/logo.png")}
              alt="logo"
            />
          </div>
          <h4>Application Form</h4>
          <div className="loader-line">
            <div className="inner-line"></div>
          </div>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {copylink && (
        <div className="save-later_mail py-5 d-flex flex-column justify-content-center align-items-center gap-5">
          <div className="d-block logo-top">
            <img
              className="w-100 h-100"
              src={require("./assets/landing/images/save-alter.png")}
              alt="img"
            />
          </div>
          <div className="d-flex flex-column gap-3 text-center">
            <h5>Your form has been saved as a draft</h5>
            <p>Receive a link in your inbox to continue your form later</p>
            {sentMailForm && (
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handlesubmitsaveLater}
              >
                <div className="d-flex flex-md-row flex-column gy-4 gap-2 send_mail-btn">
                  <input
                    className="px-3 py-2 "
                    type="text"
                    required
                    name="sentemail"
                    placeholder="Enter Your Email"
                  />

                  <button type="submit" className=" px-4 py-2">
                    Send me an email
                  </button>
                </div>
              </form>
            )}
            {!sentMailForm && <p>Your email has been saved</p>}
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
              <p>or copy your link now</p>
              <button
                className="d-flex align-items-center gap-1 py-2"
                type="button"
                onClick={copyClipboard}
              >
                <div className="d-block copy_link">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/landing/images/link.png")}
                    alt="copy-link"
                  />
                </div>
                <p>Copy link</p>
              </button>
              <p className="text-success">{TextCopyClipboard}</p>
            </div>
          </div>
        </div>
      )}
      {mainSection && (
        <div className="d-block form py-5">
          <Link to="/" className="logout mb-4">
            Home
          </Link>
          <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab1" ? "active " : ""} `}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                <span>01</span>
                <span>.</span>
                <p>
                  <span>Information</span>
                </p>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab2" ? "active " : ""} `}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                <span>02</span>
                <span>.</span>
                <p className="d-flex">
                  <span>Basic</span>
                  <span>Details</span>
                </p>
              </button>
            </li>
            <li className="nav-item " role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab3" ? "active " : ""} `}
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact-tab-pane"
                type="button"
                role="tab"
                aria-controls="contact-tab-pane"
                aria-selected="false"
              >
                <span>03</span>
                <span>.</span>
                <p className="d-flex">
                  <span>Emergency</span>
                  <span>con..</span>
                </p>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab4" ? "active " : ""} `}
                id="work-tab"
                data-bs-toggle="tab"
                data-bs-target="#work-tab-pane"
                type="button"
                role="tab"
                aria-controls="work-tab-pane"
                aria-selected="false"
              >
                <span>04</span>
                <span>.</span>
                <p className="d-flex">
                  <span>Work</span>
                  <span> History</span>
                </p>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab5" ? "active " : ""} `}
                id="qualifications-tab"
                data-bs-toggle="tab"
                data-bs-target="#qualifications-tab-pane"
                type="button"
                role="tab"
                aria-controls="qualifications-tab-pane"
                aria-selected="false"
              >
                <span>05</span>
                <span>.</span>
                <p>
                  <span>Qualifications</span>
                </p>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                disabled
                className={`nav-link  ${tab1 === "tab6" ? "active " : ""} `}
                id="preferences-tab"
                data-bs-toggle="tab"
                data-bs-target="#preferences-tab-pane"
                type="button"
                role="tab"
                aria-controls="preferences-tab-pane"
                aria-selected="false"
              >
                <span>06</span>
                <span>.</span>
                <p>
                  <span>Preferences</span>
                </p>
              </button>
            </li>
          </ul>
          <div className="tab-content py-5" id="myTabContent">
            <div
              className={`tab-pane fade  ${
                tab1 === "tab1" ? "show active " : ""
              } `}
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="d-flex flex-column gap-5">
                <div className="d-block text-center">
                  <h4>Application Form</h4>
                </div>
                <div className="d-flex flex-column gap-3">
                  <p>
                    Completing this application form will take about 10-15
                    minutes. Before you start, please have the following ready:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Copies of your licenses, certificates, inductions, VOCs,
                        visa, and identification
                      </p>
                    </li>
                    <li>
                      <p>
                        Emergency contact details, including phone number and
                        address
                      </p>
                    </li>
                    <li>
                      <p>Information for at least two references</p>
                    </li>
                    <li>
                      <p>Police clearance, if available</p>
                    </li>
                  </ul>
                </div>
                <div className="d-flex flex-column gap-3 cl-red">
                  <h5>File Upload Instructions</h5>
                  <p>
                    This form allows you to upload your licenses/tickets by
                    taking a photograph or selecting files from your device.
                  </p>
                  <ul>
                    <li>
                      <p>
                        You can upload multiple files for the same
                        license/ticket.
                      </p>
                    </li>
                    <li>
                      <p>
                        Upload only the specific license requested. A single PDF
                        containing all your licenses will not be accepted.
                      </p>
                    </li>
                  </ul>
                </div>
                <form
                  method="post"
                  action="javascript:void(0)"
                  onSubmit={handleSubmitstep1}
                >
                  <div className="d-flex flex-column gap-2">
                    <h5>Are you ready to proceed?</h5>
                    <div className="d-flex align-items-center gap-2 home-main">
                      <input
                        type="radio"
                        onChange={handleRadioChange}
                        name="ready"
                        id="proceed"
                        checked={readyValue === "Yes"}
                        value="Yes"
                        required
                      />
                      <label for="proceed">Yes</label>
                    </div>
                  </div>
                  <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 ">
                    <button
                      className="save-btn"
                      onClick={savelaterStep1}
                      type="button"
                    >
                      Save for later
                    </button>
                    <button
                      className="next-btn"
                      onClick={nextStep1}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`tab-pane fade  ${
                tab1 === "tab2" ? "show active " : ""
              } `}
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              <div className="d-block text-center">
                <h4>Application Form</h4>
              </div>
              <div className="d-block py-3">
                <h3>Basic Details</h3>
              </div>
              <form
                action="javascript:void(0)"
                onSubmit={handlesubmitStep2}
                method="post"
              >
                <div className="row gy-3 align-items-end">
                  <div className="col-md-3">
                    <div className="d-flex flex-column">
                      <label>
                        Name<span>*</span>
                      </label>

                      <select
                        className="p-1"
                        onChange={handlestep2_title}
                        name="step2_title"
                        value={issstep2_title}
                        required
                      >
                        <option value="">Title</option>
                        <option value="Ms">Ms</option>
                        <option value="Miss">Miss</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column">
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        placeholder="First"
                        type="text"
                        id="name"
                        defaultValue={allrecord.first_name}
                        name="first_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column">
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        placeholder="Last"
                        type="text"
                        defaultValue={allrecord.last_name}
                        id="name"
                        name="last_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column">
                      <label>
                        Mobile Number<span>*</span>
                      </label>

                      <input
                        onInput={handleInputmobiletsep2}
                        onKeyDown={handleKeyDownstep2}
                        className="px-2"
                        type="text"
                        name="contact"
                        value={contactstep2}
                        required
                      />
                      <span className="text-danger">{invalidnumberstep2}</span>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex flex-column">
                      <label>Gender</label>

                      <select
                        onChange={handlegenderOptions}
                        className="p-1"
                        name="step2_gender"
                        id="step2_gender"
                        value={genderOptions} // Bind value to state for controlled behavior
                      >
                        <option value="">Please select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Non Binary">Non Binary</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column">
                      <label>
                        Are you of Aboriginal or Torres Strait Island origin?
                      </label>

                      <select
                        onChange={handleoptionorigin}
                        className="p-1"
                        name="step2_origin"
                        id="step2_origin"
                        value={optionorigin}
                      >
                        <option value="">Please select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Prefer Not To Say">
                          Prefer Not To Say
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label>
                        Email<span>*</span>
                      </label>
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="email"
                        name="email"
                        defaultValue={allrecord.email}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6" ref={emailerr}>
                    <div className="d-flex flex-column">
                      <label>
                        Confirm Email<span>*</span>
                      </label>
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="email"
                        defaultValue={allrecord.step2_confirm_email}
                        name="step2_confirm_email"
                        required
                      />
                    </div>
                  </div>
                  {erroremail && (
                    <span class="text-danger" style={{ marginTop: "5px" }}>
                      {isemail}
                    </span>
                  )}
                  <div className="col-md-6" ref={passworderr}>
                    <div className="d-flex flex-column">
                      <label>
                        Password<span>*</span>
                      </label>
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="password"
                        defaultValue={allrecord.password}
                        name="password"
                        required
                      />
                    </div>
                  </div>
                  {errorpassword && (
                    <span class="text-danger" style={{ marginTop: "5px" }}>
                      Password does not match
                    </span>
                  )}
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label>
                        Confirm Password<span>*</span>
                      </label>
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="password"
                        defaultValue={allrecord.step2_confirm_password}
                        name="step2_confirm_password"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>
                        Home Address:<span>*</span>
                      </label>

                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="text"
                        defaultValue={allrecord.address}
                        placeholder="Street Address"
                        name="address"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="text"
                        placeholder="Street Address Line 2"
                        name="step2_address"
                        defaultValue={allrecord.step2_address}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="text"
                        defaultValue={allrecord.step2_city}
                        placeholder="City"
                        name="step2_city"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <select
                        className="p-1"
                        onChange={handleoptionstatefirst}
                        name="step2_state"
                        value={optionstatefirst}
                      >
                        <option value="">State</option>
                        <option value="New South Wales">New South Wales</option>
                        <option value="Northern Territory">
                          Northern Territory
                        </option>
                        <option value="Queensland">Queensland</option>
                        <option value="South Australia">South Australia</option>
                        <option value="Tasmania">Tasmania</option>
                        <option value="Victoria">Victoria</option>
                        <option value="Western Australia">
                          Western Australia
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="text"
                        placeholder="Postal Code"
                        name="step2_Postal"
                        defaultValue={allrecord.step2_Postal}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <select
                        className="p-1"
                        onChange={handleoptioncountryfirst}
                        name="step2_country"
                        value={optioncountryfirst}
                      >
                        <option value="">Country</option>
                        <option value="Australia">Australia</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Is your postal address the same as your residential
                        address?
                        <span>*</span>
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            id="step2_residential_address1"
                            name="step2_residential_address"
                            value="Yes"
                            checked={isresidentalAddress === "Yes"}
                            onClick={() => residentalAddress("Yes")}
                            required
                          />
                          <label for="step2_residential_address1">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            id="step2_residential_address2"
                            onClick={() => residentalAddress("No")}
                            name="step2_residential_address"
                            value="No"
                            checked={isresidentalAddress === "No"}
                            required
                          />
                          <label for="step2_residential_address2">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {step2_Postaladdress && (
                    <div className="col-12">
                      <div className="d-flex flex-column">
                        <label>
                          Postal Address:<span>*</span>
                        </label>

                        <input
                          className="px-2"
                          type="text"
                          onChange={handleInputChangeStep2}
                          placeholder="Street Address"
                          name="step2_postal_address"
                          required
                          defaultValue={allrecord.step2_postal_address}
                        />
                      </div>
                    </div>
                  )}
                  {step2_Postaladdress && (
                    <div className="col-12">
                      <div className="d-flex flex-column">
                        <input
                          className="px-2"
                          type="text"
                          onChange={handleInputChangeStep2}
                          placeholder="Street Address Line 2"
                          name="step2_postal_address2"
                          defaultValue={allrecord.step2_postal_address2}
                        />
                      </div>
                    </div>
                  )}
                  {step2_Postaladdress && (
                    <div className="col-md-6">
                      <div className="d-flex flex-column">
                        <input
                          className="px-2"
                          type="text"
                          onChange={handleInputChangeStep2}
                          placeholder="City"
                          name="step2_postal_city"
                          defaultValue={allrecord.step2_postal_city}
                        />
                      </div>
                    </div>
                  )}
                  {step2_Postaladdress && (
                    <div className="col-md-6">
                      <div className="d-flex flex-column">
                        <select
                          className="p-1"
                          value={isstep2_postal_state}
                          onChange={handlestep2_postal_state}
                          name="step2_postal_state"
                        >
                          <option value="">State</option>
                          <option value="New South Wales">
                            New South Wales
                          </option>
                          <option value="Northern Territory">
                            Northern Territory
                          </option>
                          <option value="Queensland">Queensland</option>
                          <option value="South Australia">
                            South Australia
                          </option>
                          <option value="Tasmania">Tasmania</option>
                          <option value="Victoria">Victoria</option>
                          <option value="Western Australia">
                            Western Australia
                          </option>
                        </select>
                      </div>
                    </div>
                  )}
                  {step2_Postaladdress && (
                    <div className="col-md-6">
                      <div className="d-flex flex-column">
                        <input
                          onChange={handleInputChangeStep2}
                          className="px-2"
                          type="text"
                          placeholder="Postal Code"
                          defaultValue={allrecord.step2_postal_code}
                          name="step2_postal_code"
                        />
                      </div>
                    </div>
                  )}
                  {step2_Postaladdress && (
                    <div className="col-md-6">
                      <div className="d-flex flex-column">
                        <select
                          className="p-1"
                          value={isstep2_postal_country}
                          onChange={handlestep2_postal_country}
                          name="step2_postal_country"
                        >
                          <option value="">Country</option>
                          <option value="Australia">Australia</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Employee Photo<span>*</span>
                      </label>
                      <p className="photo-drag">
                        <i>
                          Please upload an employee photo, this photo must be
                          taken within 30 days and be of Passport standard –
                          white background, from the shoulders upwards
                        </i>
                      </p>
                      <div
                        className="d-flex align-items-center gap-3"
                        style={{
                          pointerEvents: isImageValid(allrecord.image)
                            ? "none"
                            : "auto",
                        }}
                      >
                        <div className="d-flex align-items-center gap-2 position-relative">
                          <input
                            className="chose-file"
                            type="file"
                            name="image"
                            defaultValue={allrecord.image}
                            onChange={handleEmployeephoto}
                            required={
                              allrecord.image === null ||
                              allrecord.image === undefined ||
                              allrecord.image === ""
                            }
                          />
                          <button className="chose-btn">
                            {allrecord.image === null ||
                            allrecord.image === undefined ||
                            allrecord.image === ""
                              ? "Choose files or drag here"
                              : // Render something else when allrecord.image is not empty
                                allrecord.image}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8"></div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column">
                      <label>
                        Date of Birth <span>*</span>
                      </label>
                      <input
                        onChange={handleInputChangeStep2}
                        className="px-2"
                        type="date"
                        name="step2_dob"
                        required
                        defaultValue={formatDateForState(allrecord.step2_dob)}
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="d-flex flex-column">
                      <label>
                        Country of Birth<span>*</span>
                      </label>
                      <Select
                        onChange={handleSelectCountryBirth}
                        placeholder="Select a country"
                        options={allcountry.map((country) => ({
                          value: country.country_name,
                          label: country.country_name,
                        }))}
                        value={isselectcountrybirth} // Example: setting the default value to the first country
                        name="step2_country_birth"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Are you a permanent Resident/Citizen of Australia?
                        <span>*</span>
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_permanent_address"
                            required
                            onClick={() => citizenaus("Yes")}
                            checked={isSelectedpermanentAddress === "Yes"}
                            value="Yes"
                          />
                          <label>Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            onClick={() => citizenaus("No")}
                            name="step2_permanent_address"
                            required
                            checked={isSelectedpermanentAddress === "No"}
                            value="No"
                          />
                          <label>No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {auscitizenNo && (
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2">
                        <label>
                          Passport<span>*</span>
                        </label>

                        <div
                          className="d-flex align-items-center gap-3"
                          style={{
                            pointerEvents: isImageValid(
                              allrecord.step2_passport
                            )
                              ? "none"
                              : "auto",
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 position-relative">
                            <input
                              className="chose-file"
                              type="file"
                              name="step2_passport"
                              onChange={handlePermanentpassport}
                              required={
                                allrecord.step2_passport === null ||
                                allrecord.step2_passport === undefined ||
                                allrecord.step2_passport === ""
                              }
                            />
                            <button className="chose-btn">
                              {allrecord.step2_passport === "" ||
                              allrecord.step2_passport === null ||
                              allrecord.step2_passport === undefined
                                ? "Choose files or drag here"
                                : // Render something else when allrecord.image is not empty
                                  allrecord.step2_passport}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {auscitizenYes && (
                    <div className="col-md-12">
                      <div className="d-flex flex-column gap-2">
                        <label>Please select proof to upload</label>

                        <div className="d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_proof_upload"
                              onChange={birthcheck}
                              checked={selectedProofs.includes(
                                "Birth Certificate"
                              )}
                              value="Birth Certificate"
                            />
                            <label>Birth Certificate</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_proof_upload"
                              value="Passport"
                              checked={selectedProofs.includes("Passport")}
                              onChange={passportcheck}
                            />
                            <label>Passport</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_proof_upload"
                              onChange={certificatecheck}
                              checked={selectedProofs.includes(
                                "Citizenship Certificate"
                              )}
                              value="Citizenship Certificate"
                            />

                            <label>Citizenship Certificate</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {auscitizenYes && (
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        {auscitizenYesBirth && (
                          <div
                            className="d-flex flex-column gap-2"
                            style={{
                              pointerEvents: isImageValid(
                                allrecord.step2_birthcertificate_file
                              )
                                ? "none"
                                : "auto",
                            }}
                          >
                            <label>
                              Birth Certificate<span>*</span>
                            </label>

                            <div className="d-flex align-items-center gap-3">
                              <div className="d-flex align-items-center gap-2 position-relative">
                                <input
                                  className="chose-file"
                                  type="file"
                                  name="step2_birthcertificate_file"
                                  required={
                                    allrecord.step2_birthcertificate_file ===
                                      null ||
                                    allrecord.step2_birthcertificate_file ===
                                      undefined ||
                                    allrecord.step2_birthcertificate_file === ""
                                  }
                                  onChange={handlebirthcertificate}
                                />
                                <button className="chose-btn">
                                  {allrecord.step2_birthcertificate_file ===
                                    null ||
                                  allrecord.step2_birthcertificate_file ===
                                    undefined ||
                                  allrecord.step2_birthcertificate_file === ""
                                    ? "Choose files or drag here"
                                    : // Render something else when allrecord.image is not empty
                                      allrecord.step2_birthcertificate_file}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {auscitizenYesPassport && (
                          <div className="d-flex flex-column gap-2">
                            <label>
                              Passport<span>*</span>
                            </label>

                            <div
                              className="d-flex align-items-center gap-3"
                              style={{
                                pointerEvents: isImageValid(
                                  allrecord.step2_passportcertificate_file
                                )
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <div className="d-flex align-items-center gap-2 position-relative">
                                <input
                                  className="chose-file"
                                  type="file"
                                  onChange={handlepassportcertificate}
                                  name="step2_passportcertificate_file"
                                  required={
                                    allrecord.step2_passportcertificate_file ===
                                      null ||
                                    allrecord.step2_passportcertificate_file ===
                                      undefined ||
                                    allrecord.step2_passportcertificate_file ===
                                      ""
                                  }
                                />
                                <button className="chose-btn">
                                  {allrecord.step2_passportcertificate_file ===
                                    null ||
                                  allrecord.step2_passportcertificate_file ===
                                    undefined ||
                                  allrecord.step2_passportcertificate_file ===
                                    ""
                                    ? "Choose files or drag here"
                                    : // Render something else when allrecord.image is not empty
                                      allrecord.step2_passportcertificate_file}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {auscitizenYesCert && (
                          <div className="d-flex flex-column gap-2">
                            <label>
                              Australian Citizenship Certificate Upload
                              <span>*</span>
                            </label>

                            <div
                              className="d-flex align-items-center gap-3"
                              style={{
                                pointerEvents: isImageValid(
                                  allrecord.step2_auscitizencertificate_file
                                )
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <div className="d-flex align-items-center gap-2 position-relative">
                                <input
                                  className="chose-file"
                                  onChange={handlecertificatefile}
                                  type="file"
                                  name="step2_auscitizencertificate_file"
                                  required={
                                    allrecord.step2_auscitizencertificate_file ===
                                      null ||
                                    allrecord.step2_auscitizencertificate_file ===
                                      undefined ||
                                    allrecord.step2_auscitizencertificate_file ===
                                      ""
                                  }
                                />
                                <button className="chose-btn">
                                  {allrecord.step2_auscitizencertificate_file ===
                                    null ||
                                  allrecord.step2_auscitizencertificate_file ===
                                    undefined ||
                                  allrecord.step2_auscitizencertificate_file ===
                                    ""
                                    ? "Choose files or drag here"
                                    : // Render something else when allrecord.image is not empty
                                      allrecord.step2_auscitizencertificate_file}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Do you have the legal right to live and work in
                        Australia?
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            id="y1"
                            name="step2_legal_work"
                            onChange={legalwork}
                            checked={islegalwork === "Yes"}
                            value="Yes"
                          />
                          <label for="y1">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_legal_work"
                            onChange={legalwork}
                            checked={islegalwork === "No"}
                            value="No"
                            id="n1"
                          />
                          <label for="n1">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {islegalworkdesc && (
                    <div className="col-md-9">
                      <div className="d-flex flex-column gap-2">
                        <span className="text-white bgred">
                          If you do not have the right to work in Australia,
                          please do not proceed with this application.
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label>
                        What date do you become available?<span>*</span>
                      </label>
                      <input
                        className="px-2"
                        type="date"
                        onChange={handleInputChangeStep2}
                        name="step2_available_date"
                        required
                        defaultValue={formatDateForState(
                          allrecord.step2_available_date
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Have you ever been convicted of any criminal offenses?
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_criminal_offenses"
                            onChange={() => criminaloffensenses("Yes")}
                            checked={iscriminaloffensenses === "Yes"}
                            value="Yes"
                            id="yy"
                          />
                          <label for="yy">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_criminal_offenses"
                            value="No"
                            onChange={() => criminaloffensenses("No")}
                            checked={iscriminaloffensenses === "No"}
                            id="yy1"
                          />
                          <label for="yy1">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column gap-2">
                      <label>Have you served time in prison?</label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_served_time"
                            value="Yes"
                            onChange={() => servedtime("Yes")}
                            checked={isservedtime === "Yes"}
                            id="yy_1"
                          />
                          <label for="yy_1">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_served_time"
                            value="No"
                            onChange={() => servedtime("No")}
                            checked={isservedtime === "No"}
                            id="yu"
                          />
                          <label for="yu">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Are you an ex serving member of the Australian Defence
                        Force?
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_defence_forced"
                            value="Yes"
                            id="uu"
                            onChange={() => defenceforced("Yes")}
                            checked={isdefenceforced === "Yes"}
                          />
                          <label for="uu">Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="step2_defence_forced"
                            value="No"
                            id="u_u"
                            onChange={() => defenceforced("No")}
                            checked={isdefenceforced === "No"}
                          />
                          <label for="u_u">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column">
                      <label>
                        Shirt Size<span>*</span>
                      </label>
                      <select
                        className="p-1"
                        onChange={handlestep2_shirt_size}
                        name="step2_shirt_size"
                        value={isstep2_shirt_size}
                        required
                      >
                        <option value="">Please select</option>
                        <option value="Shirt Size S" data-index="0">
                          Shirt Size S
                        </option>
                        <option value="Shirt Size M" data-index="1">
                          Shirt Size M
                        </option>
                        <option value="Shirt Size L" data-index="2">
                          Shirt Size L
                        </option>
                        <option value="Shirt Size XL" data-index="3">
                          Shirt Size XL
                        </option>
                        <option value="Shirt Size XXL" data-index="4">
                          Shirt Size XXL
                        </option>
                        <option value="Shirt Size XXXL" data-index="5">
                          Shirt Size XXXL
                        </option>
                        <option value="Shirt Size XXXXL" data-index="6">
                          Shirt Size XXXXL
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Which shifts are you willing to work? Please select all
                        that apply
                      </label>
                      <div className="d-flex gap-5">
                        <div className="d-flex flex-column align-items-start gap-2">
                          <label>
                            Day Shift<span>*</span>
                          </label>
                          <div className="d-flex align-items-center gap-3">
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="step2_which_dayshift"
                                value="Yes"
                                onChange={() => dayshift("Yes")}
                                checked={isdayshift === "Yes"}
                              />
                              <label>Yes</label>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="step2_which_dayshift"
                                value="No"
                                onChange={() => dayshift("No")}
                                checked={isdayshift === "No"}
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start gap-2">
                          <label>
                            Night Shift<span>*</span>
                          </label>
                          <div className="d-flex align-items-center gap-3">
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="step2_which_nightshift"
                                value="Yes"
                                onChange={() => nightshift("Yes")}
                                checked={isnightshift === "Yes"}
                              />
                              <label>Yes</label>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="step2_which_nightshift"
                                value="No"
                                onChange={() => nightshift("No")}
                                checked={isnightshift === "No"}
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Employment Type - Please select all that apply
                      </label>
                      <div className="d-flex flex-column align-items-start  gap-2">
                        <div className="d-flex flex-md-row flex-column gy-3 align-items-start  w-100 justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_employment_type"
                              checked={selectedEmployetype.includes("FIFO")}
                              onChange={() => employetype("FIFO")}
                            />
                            <label>FIFO</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_employment_type"
                              value="Shutdowns"
                              checked={selectedEmployetype.includes(
                                "Shutdowns"
                              )}
                              onChange={() => employetype("Shutdowns")}
                            />
                            <label>Shutdowns</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              value="Local Work"
                              name="step2_employment_type"
                              checked={selectedEmployetype.includes(
                                "Local Work"
                              )}
                              onChange={() => employetype("Local Work")}
                            />
                            <label>Local Work</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_employment_type"
                              value="Casual"
                              checked={selectedEmployetype.includes("Casual")}
                              onChange={() => employetype("Casual")}
                            />
                            <label>Casual</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              name="step2_employment_type"
                              value="Full Time"
                              checked={selectedEmployetype.includes(
                                "Full Time"
                              )}
                              onChange={() => employetype("Full Time")}
                            />
                            <label>Full Time</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 pt-4">
                      <button
                        className="save-btn"
                        type="button"
                        onClick={() => previousstep("tab1")}
                      >
                        Previous
                      </button>
                      <button
                        className="save-btn"
                        type="button"
                        onClick={savelaterStep2}
                      >
                        Save for later
                      </button>
                      <button className="next-btn" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={`tab-pane fade  ${
                tab1 === "tab3" ? "show active " : ""
              } `}
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabindex="0"
            >
              <div className="d-block text-center">
                <h4>Application Form</h4>
              </div>
              <div className="d-block py-3">
                <h3>Emergency Contact Details</h3>
              </div>
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handlesubmitStep3}
              >
                <div className="row gy-3 align-items-end">
                  <div className="col-md-3">
                    <div className="d-flex flex-column">
                      <label>
                        Name<span>*</span>
                      </label>

                      <select
                        className="p-1"
                        name="step3_title"
                        id="step3_title"
                        onChange={handlestep3_title}
                        value={issstep3_title}
                        required
                      >
                        <option value="">Title</option>
                        <option value="Ms">Ms</option>
                        <option value="Miss">Miss</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column">
                      <input
                        className="px-2"
                        placeholder="First"
                        type="text"
                        id="name"
                        name="step3_first_name"
                        defaultValue={allrecord.step3_first_name}
                        onChange={handleInputChangeStep3}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column">
                      <input
                        className="px-2"
                        placeholder="Last"
                        type="text"
                        defaultValue={allrecord.step3_last_name}
                        onChange={handleInputChangeStep3}
                        name="step3_last_name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>
                        Relationship to you (eg. spouse, parent, friend)
                        <span>*</span>
                      </label>

                      <input
                        className="px-2"
                        required
                        defaultValue={allrecord.step3_relationship}
                        type="text"
                        onChange={handleInputChangeStep3}
                        name="step3_relationship"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Is your Emergency Contact located in Australia?
                        <span>*</span>
                      </label>

                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            onChange={handlestep3_contact}
                            value="Yes"
                            checked={isstep3_contact === "Yes"}
                            name="step3_contact"
                            required
                          />
                          <label>Yes</label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            onChange={handlestep3_contact}
                            value="No"
                            checked={isstep3_contact === "No"}
                            name="step3_contact"
                            required
                          />
                          <label>No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Mobile Phone Number<span>*</span>
                      </label>
                      <span className="dark-text">eg 0422 222 222</span>
                      <input
                        className="px-2"
                        type="text"
                        max="12"
                        value={contactstep3}
                        onKeyDown={handleKeyDownstep3}
                        onChange={handleInputMobilephone_step3}
                        required
                        name="step3_mobile_number"
                      />
                      <span className="text-danger">{invalidnumberstep3}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column gap-2">
                      <label>
                        Alternative Phone Number<span>*</span>
                      </label>
                      <span className="dark-text">eg 0422 222 222</span>
                      <input
                        className="px-2"
                        type="text"
                        max="12"
                        value={contactstep3_alter}
                        onKeyDown={handleKeyDownstep3_alter}
                        onChange={handleInputMobilephone_step3__alter}
                        name="step3_phone_number"
                        required
                      />
                      <span className="text-danger">
                        {invalidnumberstep3_alter}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>
                        Address<span>*</span>
                      </label>

                      <input
                        className="px-2"
                        onChange={handleInputChangeStep3}
                        type="text"
                        required
                        defaultValue={allrecord.step3_address}
                        placeholder="Street Address"
                        name="step3_address"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <input
                        className="px-2"
                        onChange={handleInputChangeStep3}
                        type="text"
                        defaultValue={allrecord.step3_address2}
                        placeholder="Street Address Line 2"
                        name="step3_address2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <input
                        className="px-2"
                        onChange={handleInputChangeStep3}
                        type="text"
                        defaultValue={allrecord.step3_city}
                        placeholder="City"
                        name="step3_city"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <select
                        className="p-1"
                        name="step3_state"
                        value={isstep3_postal_state}
                        onChange={handlestep3_postal_state}
                      >
                        <option value="">State</option>
                        <option value="New South Wales">New South Wales</option>
                        <option value="Northern Territory">
                          Northern Territory
                        </option>
                        <option value="Queensland">Queensland</option>
                        <option value="South Australia">South Australia</option>
                        <option value="Tasmania">Tasmania</option>
                        <option value="Victoria">Victoria</option>
                        <option value="Western Australia">
                          Western Australia
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <input
                        className="px-2"
                        type="text"
                        onChange={handleInputChangeStep3}
                        defaultValue={allrecord.step3_postal}
                        placeholder="Postal Code"
                        name="step3_postal"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <select
                        className="p-1"
                        onChange={handleoptioncountryfirstthird}
                        value={optioncountrythird}
                        name="step3_country"
                      >
                        <option value="Country">Country</option>
                        <option value="Australia">Australia</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 pt-4">
                      <button
                        className="save-btn"
                        onClick={() => previousstep("tab2")}
                        type="button"
                      >
                        Previous
                      </button>
                      <button
                        className="save-btn"
                        type="button"
                        onClick={savelaterStep3}
                      >
                        Save for later
                      </button>
                      <button className="next-btn" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={`tab-pane fade  ${
                tab1 === "tab4" ? "show active " : ""
              } `}
              id="work-tab-pane"
              role="tabpanel"
              aria-labelledby="work-tab"
              tabindex="0"
            >
              <div className="d-block text-center">
                <h4>Application Form</h4>
              </div>
              <div className="d-block py-3">
                <h3>Employement History</h3>
              </div>
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handleSubmitstep4}
              >
                <div className="d-block">
                  {employmentHistorySections.map((section, index) => (
                    <div className="row mt-2 gy-3 align-items-end" key={index}>
                      {index !== 0 && (
                        <div className="d-flex align-items-center justify-content-end">
                          <Link
                            to="javascript:void(0)"
                            className="text-white bg-danger py-2 px-3 rounded-1 float-end"
                            onClick={() => trashdiv(index)}
                          >
                            Remove
                          </Link>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="d-flex flex-column">
                          <label>Start date </label>
                          <input
                            className="px-2"
                            name={`start_date_${index}`}
                            value={section.start_date}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "start_date",
                                e.target.value
                              )
                            }
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column">
                          <label>End date </label>
                          <input
                            className="px-2"
                            type="date"
                            name={`end_date_${index}`}
                            value={section.end_date}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "end_date",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex flex-column">
                          <input
                            className="px-2"
                            type="text"
                            name={`company_${index}`}
                            placeholder="Enter Company"
                            value={section.company}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "company",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex flex-column">
                          <input
                            className="px-2"
                            type="text"
                            name={`role_${index}`}
                            value={section.role}
                            onChange={(e) =>
                              handleInputChange(index, "role", e.target.value)
                            }
                            placeholder="Enter Your Roll"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex flex-column">
                          <label>Enter What you done in this company</label>
                          <textarea
                            name={`company_done_${index}`}
                            placeholder="type here.."
                            id=""
                            rows="5"
                            value={section.company_done}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "company_done",
                                e.target.value
                              )
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 mt-2">
                    <div className=" add-btn">
                      <button type="button" onClick={addMoreDiv}>
                        <span>Add More</span>{" "}
                        <span>
                          <i className="fal fa-plus"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="d-block">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 pt-4">
                      <button
                        className="save-btn"
                        onClick={() => previousstep("tab3")}
                        type="button"
                      >
                        Previous
                      </button>
                      <button
                        className="save-btn"
                        type="button"
                        onClick={savelaterStep4}
                      >
                        Save for later
                      </button>
                      <button className="next-btn" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={`tab-pane fade  ${
                tab1 === "tab5" ? "show active " : ""
              } `}
              id="qualifications-tab-pane"
              role="tabpanel"
              aria-labelledby="qualifications-tab"
              tabindex="0"
            >
              <div className="d-block text-center">
                <h4>Application Form</h4>
              </div>
              <div className="d-block py-3">
                <h3>Education</h3>
              </div>
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handlesubmitstep5}
              >
                <div className="row gy-3 align-items-end">
                  {employmenteducation.map((section, index) => (
                    <div key={index} className="row gy-3 align-items-end">
                      {index !== 0 && (
                        <div className="d-flex align-items-center justify-content-end ">
                          <Link
                            to="javascript:void(0)"
                            className="bg-danger text-white float-end py-2 px-3 rounded-1"
                            onClick={() => trashdiveducation(index)}
                          >
                            Remove
                          </Link>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="d-flex flex-column">
                          <label>
                            Year of Completion<span>*</span>
                          </label>
                          <input
                            name={`year_${index}`}
                            style={{ paddingLeft: "10px" }}
                            type="text"
                            placeholder="Year of completion"
                            value={section.year}
                            onChange={(e) =>
                              handleInputChange_edication(
                                index,
                                "year",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column">
                          <label>
                            Course<span>*</span>
                          </label>
                          <input
                            name={`course_${index}`}
                            style={{ paddingLeft: "10px" }}
                            type="text"
                            placeholder="Course"
                            value={section.course}
                            onChange={(e) =>
                              handleInputChange_edication(
                                index,
                                "course",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column">
                          <label>
                            Institution<span>*</span>
                          </label>
                          <input
                            name={`institution_${index}`}
                            style={{ paddingLeft: "10px" }}
                            type="text"
                            placeholder="institution"
                            value={section.institution}
                            onChange={(e) =>
                              handleInputChange_edication(
                                index,
                                "institution",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="col-12">
                    <div className=" add-btn">
                      <button type="button" onClick={addMoreDivEducation}>
                        <span>Add More</span>{" "}
                        <span>
                          <i className="fal fa-plus"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-block py-3">
                  <h3>Skill and Extra Informations</h3>
                </div>
                <div className="row gy-3 align-items-end">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label>
                        Mention All Skills <span>*</span>
                      </label>

                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={options}
                        value={selectvalue_skill}
                        name="skills"
                        required
                        onChange={handleSelectChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label>Years of Experience</label>
                      <input
                        className="px-2"
                        type="number"
                        pattern="[0-9]*"
                        defaultValue={allrecord.years}
                        onChange={handleInputChangeStep5}
                        name="years"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Mention Licences</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={MentionLic}
                        value={MentionLic_get}
                        name="licence"
                        onChange={handleSelectChange_mentionl}
                      />
                      <input
                        className="px-0 pt-2"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handlelicense_File}
                        name="licence_file"
                      />
                      <br />
                      <span className="text-danger">{setselect_l_msg}</span>
                      {licenseArray !== null ? (
                        <div className="row">
                          {Array.isArray(licenseArray) &&
                            licenseArray.map((item, index) => (
                              <div key={index} className="col-md-2">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                  src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                  alt="Image"
                                />
                                <br />
                                <button
                                  className="removeskill text-danger  mt-2"
                                  type="button"
                                  onClick={() =>
                                    removemention(item, userdetail.id)
                                  }
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p>No result found</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Mention Other Certification</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={MentionLCert}
                        value={MentionLCert_m}
                        name="certificate"
                        onChange={handleSelectChange_mentionc}
                      />
                      <input
                        type="file"
                        onChange={handlecertificate_File}
                        multiple
                        accept="image/*"
                        name="certificate_file"
                        style={{ color: "#fff" }}
                      />
                      <br />
                      <span className="text-danger">{selectcertmsg}</span>
                      {edfile !== null ? (
                        <div className="row">
                          {Array.isArray(edfile) &&
                            edfile.map((item, index) => (
                              <div key={index} className="col-md-3">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                  src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                  alt="Image"
                                />
                                <br />
                                <button
                                  className="removeskill text-danger  mt-2"
                                  type="button"
                                  onClick={() =>
                                    removecertificate(item, userdetail.id)
                                  }
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p>No result found</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Trade Certifications</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        name="trade"
                        options={TradeM}
                        value={selectvalue_trade}
                        onChange={handleSelectChange_trade}
                      />
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handletrade_File}
                        name="trade_file"
                        style={{ color: "#fff" }}
                      />
                      <br />
                      <span className="text-danger">{setselect_t_msg}</span>
                      {tradetextArray !== null ? (
                        <div className="row">
                          {Array.isArray(tradetextArray) &&
                            tradetextArray.map((item, index) => (
                              <div key={index} className="col-md-3">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                  src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                  alt="Image"
                                />
                                <br />
                                <button
                                  className="removeskill text-danger  mt-2"
                                  type="button"
                                  onClick={() =>
                                    removetrade(item, userdetail.id)
                                  }
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p>No result found</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Machinery</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={machinerygett}
                        value={selectvalue_machinery}
                        name="machinery"
                        onChange={handleSelectChange_machinery}
                      />
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        name="machinery_file"
                        onChange={handlemachinery_File}
                        style={{ color: "#fff" }}
                      />
                      <br />
                      <span className="text-danger">{setselect_m_msg}</span>
                      {machinetextArrayy !== null ? (
                        <div className="row">
                          {Array.isArray(machinetextArrayy) &&
                            machinetextArrayy.map((item, index) => (
                              <div key={index} className="col-md-3">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                  src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                  alt="Image"
                                />
                                <br />
                                <button
                                  className="removeskill text-danger  mt-2"
                                  type="button"
                                  onClick={() =>
                                    removemachine(item, userdetail.id)
                                  }
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p>No result found</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Vocational training</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={Vocationtraa}
                        value={selectvalue_vocational_training}
                        name="vocational_training"
                        onChange={handleSelectChange_vocational_training}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Equipment worked</label>
                      <CreatableSelect
                        isMulti
                        formatCreateLabel={formatCreateLabel}
                        placeholder={placeholderText}
                        options={Equipmentworkk}
                        value={selectvalue_equipment_work}
                        name="equipment_work"
                        onChange={handleSelectChange_equipment_work}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 pt-4">
                      <button
                        className="save-btn"
                        type="button"
                        onClick={() => previousstep("tab4")}
                      >
                        Previous
                      </button>
                      <button
                        className="save-btn"
                        onClick={savelaterStep5}
                        type="button"
                      >
                        Save for later
                      </button>
                      <button className="next-btn" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={`tab-pane fade  ${
                tab1 === "tab6" ? "show active " : ""
              } `}
              id="preferences-tab-pane"
              role="tabpanel"
              aria-labelledby="preferences-tab"
              tabindex="0"
            >
              {!reviewApplicationform && (
                <div className="d-block text-center">
                  <h4>Application Form</h4>
                </div>
              )}
              {!reviewApplicationform && (
                <div className="row gy-4 align-items-end pt-5">
                  <div className="col-12">
                    <div className="d-flex flex-column gap-3">
                      <label className="text-center selsct_roster">
                        Select Roster Type
                      </label>
                      <div className="d-flex flex-column align-items-start  gap-2">
                        <div className="d-flex flex-md-row flex-column gy-3 align-items-start  w-100 justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              checked={rostertype === "7/7"}
                              onChange={checkrostertype}
                              value="7/7"
                            />
                            <label>7/7</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              checked={rostertype === "2/2"}
                              onChange={checkrostertype}
                              value="2/2"
                            />
                            <label>2/2</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              checked={rostertype === "3/1"}
                              onChange={checkrostertype}
                              value="3/1"
                            />
                            <label>3/1</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              onChange={checkrostertype}
                              checked={rostertype === "8/6"}
                              value="8/6"
                            />
                            <label>8/6</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              onChange={checkrostertype}
                              checked={rostertype === "2/1"}
                              value="2/1"
                            />
                            <label>2/1</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="rostertype"
                              checked={rostertype === "15/13"}
                              onChange={checkrostertype}
                              value="15/13"
                            />
                            <label>15/13</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column gap-3">
                      <label className=" selsct_roster">
                        Select Roster Type
                      </label>
                      <div className="d-flex flex-column align-items-start  gap-2">
                        <div className="d-flex flex-md-row flex-column gy-3 align-items-start  w-100 justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="typecheckn"
                              onChange={handletypecheck}
                              value="Open_Cut"
                              checked={typecheck === "Open_Cut"}
                            />
                            <label>Open Cut</label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="typecheckn"
                              onChange={handletypecheck}
                              value="Underground_mining"
                              checked={typecheck === "Underground_mining"}
                            />
                            <label>Underground mining </label>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="typecheckn"
                              value="Projects"
                              onChange={handletypecheck}
                              checked={typecheck === "Projects"}
                            />
                            <label>Projects</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label>Any sites you don’t want to work at ?</label>

                      <input
                        className="px-2"
                        type="text"
                        name="work"
                        defaultValue={checkwork}
                        onInput={handlecheckwork}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-center gap-2 pt-4">
                      <button
                        className="save-btn"
                        type="submit"
                        onClick={() => previousstep("tab5")}
                      >
                        Previous
                      </button>

                      <button
                        className="next-btn"
                        type="submit"
                        onClick={handlesubmitlastoption}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {reviewApplicationform && (
                <div className="row gy-4 align-items-end pt-5">
                  <table
                    style={{
                      width: "800px",
                      margin: "0 auto",
                      border: "5px solid #001c71",
                    }}
                  >
                    <tbody
                      style={{
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "14px",
                        position: "relative",
                      }}
                    >
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          width: "100%",
                          position: "absolute",
                          top: "55px",
                          right: "20px",
                        }}
                      >
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            width: "150px",
                            height: "150px",
                            position: "absolute",
                            top: "55px",
                            right: "20px",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={`${mainimage}`}
                            alt="image"
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "30px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Application Form
                          </h4>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "5px",
                          justifyContent: "start",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Basic Details
                          </h4>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          paddingTop: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Name :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {issstep2_title} {allrecord.first_name}{" "}
                            {allrecord.last_name}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Mobile Number :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {contactstep2}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Gender :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {genderOptions}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Are you of Aboriginal or Torres Strait Island
                            origin? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {optionorigin}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Email :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues.email}
                          </p>
                        </td>
                      </tr>

                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Home Address :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues.address}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Street Address Line 2 :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues.step2_address}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              City :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formValues.step2_city}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              State :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {optionstatefirst}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Postal Code :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formValues.step2_Postal}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Country :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {optioncountryfirst}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {/* Row 2 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Is your postal address the same as your residential
                            address? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {isresidentalAddress}
                          </p>
                        </td>
                      </tr>

                      {/* Row 3 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Postal Address :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues.step2_postal_address}
                          </p>
                        </td>
                      </tr>

                      {/* Row 4 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Street Address Line 2 :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues.step2_postal_address2}
                          </p>
                        </td>
                      </tr>

                      {/* Row 5 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              City :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formValues.step2_postal_city}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              State :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {isstep2_postal_state}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Postal Code :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formValues.step2_postal_code}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Country :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {isstep2_postal_country}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {/* Row 6 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Date of Birth :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formatDateForState(formValues.step2_dob)}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Country of Birth :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {isselectcountrybirth ? (
                                <>{isselectcountrybirth.label}</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {/* Row 7 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Are you a permanent Resident/Citizen of Australia? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            Yes
                          </p>
                        </td>
                      </tr>

                      {/* Row 8 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Do you have the legal right to live and work in
                            Australia? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {islegalwork ? <>{islegalwork}</> : <></>}
                          </p>
                        </td>
                      </tr>

                      {/* Row 9 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            What date do you become available? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {allrecord.step2_available_date ? (
                              <>
                                {formatDateForState(
                                  allrecord.step2_available_date
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                      </tr>

                      {/* Row 10 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Have you ever been convicted of any criminal
                            offenses? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {iscriminaloffensenses ? (
                              <>{iscriminaloffensenses}</>
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Have you served time in prison? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {isservedtime ? <>{isservedtime}</> : <></>}
                          </p>
                        </td>
                      </tr>

                      {/* Row 2 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Are you an ex serving member of the Australian
                            Defence Force? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {isdefenceforced ? <>{isdefenceforced}</> : <></>}
                          </p>
                        </td>
                      </tr>

                      {/* Row 3 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Shirt Size :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {isstep2_shirt_size ? (
                              <>{isstep2_shirt_size}</>
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                      </tr>

                      {/* Row 4 */}
                      <tr
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Which shifts are you willing to work? Please select
                            all that apply:
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Day Shift :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {isdayshift ? <>{isdayshift}</> : <></>}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Night Shift :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {isnightshift ? <>{isnightshift}</> : <></>}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {/* Row 5 */}
                      <tr
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Employment Type - Please select all that apply:
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                FIFO :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {selectedEmployetype.includes("FIFO") ? (
                                  <p>Yes</p>
                                ) : (
                                  <p>No</p>
                                )}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Shutdowns :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {selectedEmployetype.includes("Shutdowns") ? (
                                  <p>Yes</p>
                                ) : (
                                  <p>No</p>
                                )}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Local Work :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {selectedEmployetype.includes("Local Work") ? (
                                  <p>Yes</p>
                                ) : (
                                  <p>No</p>
                                )}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Casual :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {selectedEmployetype.includes("Casual") ? (
                                  <p>Yes</p>
                                ) : (
                                  <p>No</p>
                                )}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                Full Time :
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {selectedEmployetype.includes("Full Time") ? (
                                  <p>Yes</p>
                                ) : (
                                  <p>No</p>
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {/* Row 6 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "5px",
                          justifyContent: "start",
                          paddingTop: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Emergency Contact Details
                          </h4>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          paddingTop: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Name :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {issstep3_title} {allrecord.step3_first_name}{" "}
                            {allrecord.step3_last_name}
                          </p>
                        </td>
                      </tr>

                      {/* Relationship */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Relationship to you (eg. spouse, parent, friend) :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues_Step3.step3_relationship}
                          </p>
                        </td>
                      </tr>

                      {/* Emergency Contact in Australia */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Is your Emergency Contact located in Australia? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {isstep3_contact ? <>{isstep3_contact}</> : <>No</>}
                          </p>
                        </td>
                      </tr>

                      {/* Mobile Phone Number */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Mobile Phone Number :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {contactstep3}
                          </p>
                        </td>
                      </tr>

                      {/* Alternative Phone Number */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Alternative Phone Number :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {contactstep3_alter}
                          </p>
                        </td>
                      </tr>

                      {/* Home Address */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Home Address :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues_Step3.step3_address}
                          </p>
                        </td>
                      </tr>

                      {/* Street Address Line 2 */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Street Address Line 2 :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues_Step3.step3_address2}
                          </p>
                        </td>
                      </tr>

                      {/* City, State, Postal Code, Country */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              City :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {" "}
                              {formValues_Step3.step3_city}{" "}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              State :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {isstep3_postal_state ? (
                                <>{isstep3_postal_state}</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Postal Code :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {formValues_Step3.step3_postal}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Country :
                            </h4>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {optioncountrythird ? (
                                <>{optioncountrythird}</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {/* Start date, End date */}

                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Relationship to you (eg. spouse, parent, friend) :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {allrecord.step3_relationship}
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Is your Emergency Contact located in Australia? :
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            Yes
                          </p>
                        </td>
                      </tr>
                      {/* Add more contact details here as needed */}

                      {/* Employment History */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "5px",
                          justifyContent: "start",
                          paddingTop: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Employment History
                          </h4>
                        </td>
                      </tr>
                      {employmentHistorySections.map((section, index) => (
                        <>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingTop: "10px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  ({index + 1}) Start Date :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.start_date}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingTop: "10px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  End Date :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.end_date}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingTop: "10px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  Company Name :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.company}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  Your Role :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.role}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  What you did in this company :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.company_done}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                      {/* Education */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "5px",
                          justifyContent: "start",
                          paddingTop: "10px",
                        }}
                      >
                        <td>
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Education
                          </h4>
                        </td>
                      </tr>
                      {employmenteducation.map((section, index) => (
                        <>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingTop: "10px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  ({index + 1}) Year of Completion :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.year}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  Course :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.course}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  Institution :
                                </h4>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {section.institution}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "5px",
                            justifyContent: "start",
                            paddingTop: "10px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "#001c71",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Skill and Extra Information
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            gap: "5px",
                            paddingTop: "10px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Mention All Skills:
                          </h4>
                          {selectvalue_skill.map((item, index) => (
                            <React.Fragment key={index}>
                              <br />
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {item.value},
                              </p>
                            </React.Fragment>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Years of Experience:
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {formValues_step5.years}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Mention Licences:
                            </h4>
                            {MentionLic_get.map((item, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {item.value},
                                </p>
                              </React.Fragment>
                            ))}
                          </div>

                          {licenseArray !== null ? (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {Array.isArray(licenseArray) &&
                                licenseArray.map((item, index) => (
                                  <div
                                    style={{
                                      display: "block",
                                      width: "100px",
                                      height: "100px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${apiurl}${item}`}
                                      alt="image"
                                    />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>No result found</p>
                          )}
                        </td>
                      </tr>
                      {/* Mention Other Certification */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Mention Other Certification:
                            </h4>
                            {MentionLCert_m.map((item, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {item.value},
                                </p>
                              </React.Fragment>
                            ))}
                          </div>
                          {edfile !== null ? (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {Array.isArray(edfile) &&
                                edfile.map((item, index) => (
                                  <div
                                    style={{
                                      display: "block",
                                      width: "100px",
                                      height: "100px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${apiurl}${item}`}
                                      alt="image"
                                    />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>No result found</p>
                          )}
                        </td>
                      </tr>

                      {/* Trade Certifications */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Trade Certifications:
                            </h4>
                            {selectvalue_trade.map((item, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {item.value},
                                </p>
                              </React.Fragment>
                            ))}
                          </div>
                          {tradetextArray !== null ? (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {Array.isArray(tradetextArray) &&
                                tradetextArray.map((item, index) => (
                                  <div
                                    style={{
                                      display: "block",
                                      width: "100px",
                                      height: "100px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${apiurl}${item}`}
                                      alt="image"
                                    />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>No result found</p>
                          )}
                        </td>
                      </tr>

                      {/* Machinery */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Machinery:
                            </h4>
                            {selectvalue_machinery.map((item, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {item.value},
                                </p>
                              </React.Fragment>
                            ))}
                          </div>
                          {machinetextArrayy !== null ? (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {Array.isArray(machinetextArrayy) &&
                                machinetextArrayy.map((item, index) => (
                                  <div
                                    style={{
                                      display: "block",
                                      width: "100px",
                                      height: "100px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${apiurl}${item}`}
                                      alt="image"
                                    />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>No result found</p>
                          )}
                        </td>
                      </tr>

                      {/* Vocational training */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Vocational training:
                            </h4>
                            {selectvalue_vocational_training.map(
                              (item, index) => (
                                <React.Fragment key={index}>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      margin: 0,
                                    }}
                                  >
                                    {item.value},
                                  </p>
                                </React.Fragment>
                              )
                            )}
                          </div>
                        </td>
                      </tr>

                      {/* Equipment worked */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Equipment worked:
                            </h4>
                            {selectvalue_equipment_work.map((item, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    margin: 0,
                                  }}
                                >
                                  {item.value},
                                </p>
                              </React.Fragment>
                            ))}
                          </div>
                        </td>
                      </tr>

                      {/* Select Roster Type */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Select Roster Type:
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {rostertype}
                          </p>
                        </td>
                      </tr>

                      {/* Select Roster Type */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Select Roster Type:
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {typecheck}
                          </p>
                        </td>
                      </tr>

                      {/* Any sites you don’t want to work at? */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            Any sites you don’t want to work at?
                          </h4>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {checkwork}
                          </p>
                        </td>
                      </tr>

                      {/* Submit Button */}
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: "10px",
                          }}
                        >
                          {/* <button
                            className="save-btn"
                            type="submit"
                            onClick={() => previousstep("tab5")}
                          >
                            Previous
                          </button> */}
                          <button
                            disabled={isButtonDisabled}
                            type="button"
                            className="gap-2"
                            onClick={handleFormFinalsubmit}
                            style={{
                              padding: "10px 30px",
                              border: "none",
                              borderRadius: "3px",
                              backgroundColor: "green",
                              color: "#fff",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                          >
                            Submit
                          </button>
                          {textmessage && (
                            <div className="textmessage text-center mt-2 ml-2">
                              <div class="spinner-border text-success"></div>
                            </div>
                          )}
                          <br />
                          {textmessages && (
                            <div className="textmessage">
                              Your application has been submitted and wait for
                              the approval
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
