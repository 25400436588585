import { useEffect, useRef, useState } from "react";
import axios from "axios";
export const Checkpdf = ({ pdfPath }) => {
  const containerRef = useRef(null);
  const [pspdfInstance, setPspdfInstance] = useState(null);
  const [successmessage, setsuccessmessage] = useState(false);

  const [errormessage, seterrormessage] = useState("");

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;
    (async function () {
      PSPDFKit = await import("pspdfkit");

      PSPDFKit.unload(container); // Ensure only one instance exists.

      const instance = await PSPDFKit.load({
        container,
        document: pdfPath,
        baseUrl: `${window.location.origin}/`,
      });

      setPspdfInstance(instance); // Save instance for later use
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [pdfPath]);

  const handleSubmit = async () => {
    if (!pspdfInstance) {
      console.error("PSPDFKit instance not found!");
      return;
    }

    try {
      console.log("PSPDFKit Instance:", pspdfInstance);

      // ✅ Export the modified PDF as a Uint8Array
      const pdfData = await pspdfInstance.exportPDF();
      console.log("PDF Exported Successfully!");

      // ✅ Convert Uint8Array to Blob
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      const fileName = pdfPath.split("/").pop();
      // ✅ Create a FormData object
      const userStr = JSON.parse(localStorage.getItem("users"));
      let formData = new FormData();
      formData.append("user_id", userStr[0].id);
      formData.append("old_name", fileName);
      formData.append("file", pdfBlob, "signed_document.pdf");

      console.log("Sending FormData:", formData);

      // ✅ Send FormData to the backend
      axios
        .post(
          "https://jlmining.app/api/admincontracts/uploadcontractFiles",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          seterrormessage("");
          setsuccessmessage(true);
          setTimeout(() => {
            setsuccessmessage(false);
            window.location.reload();
          }, 3500);
          console.log("File uploaded successfully", res.data);
        })
        .catch((err) => {
          seterrormessage(err);
          setsuccessmessage(false);
          setTimeout(() => {
            seterrormessage("");
          }, 3500);
          console.error("File upload error:", err);
        });
    } catch (error) {
      setsuccessmessage(false);
      seterrormessage(error);
      setTimeout(() => {
        seterrormessage("");
      }, 3500);
      console.error("Error exporting PDF:", error);
    }
  };

  return (
    <div>
      <div ref={containerRef} style={{ width: "100%", height: "80vh" }} />
      <button
        onClick={handleSubmit}
        className="btn btn-success"
        type="button"
        style={{ margin: "5px", padding: "10px" }}
      >
        Submit
      </button>
      {successmessage && (
        <span className="text-success gap-2">File uploaded successfully</span>
      )}
      {errormessage && (
        <span className="text-danger gap-2">{errormessage}</span>
      )}
    </div>
  );
};
