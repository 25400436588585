import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { HeaderHome } from "./HeaderHome";
import { Footer } from "./Footer";
import "./assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Notificationforuser = () => {
  const inputFileRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [issetgetalldocuments, setgetalldocuments] = useState([]);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [docscategory, setdocscategory] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Notification Page";
    const storedUserSearch = localStorage.getItem("userSearch");
    const userSearch = JSON.parse(storedUserSearch);

    if (userSearch === null) {
      var search = "";
    } else {
      var search = userSearch.search;
    }
    let formdata = {
      user_id: userStr[0].id,
      search: search,
    };

    axios
      .post("https://jlmining.app/getallnotificationusers", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetalldocuments(res.data.results);
      })
      .catch((err) => {});
  }, []);

  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  function formatDate_notes(dateString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const handleDownload = (m, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = m;
    link.click();
  };
  const searcSite = (event) => {
    const search = event.target.value;
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      search: search,
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/searchQuerynotification", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetalldocuments(res.data.results);
      })
      .catch((err) => {});
  };
  return (
    <>
      <HeaderHome />
      <section className="d-block pb-5 mt-4">
        <div className="container-lg">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-1 notif-content">
              <h3>Admin Sent Notification</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block pb-5">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <ul
                className="nav nav-tabs inci-tab"
                id="myTab"
                role="tablist"
              ></ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane show active fade p-3 border border-secondary border-opacity-25"
                  id="document-tab-pane"
                  role="tabpanel"
                  aria-labelledby="document-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title">
                        {/* <div className="flex-shrink-0">
                          <div className="num-six">
                            <div className="circle-small">
                              <div className="text">
                                {getincidentDataLength}
                                <br />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="flex-grow-1"></div>
                      </div>
                      {/* <div>
                        <Link
                          to="javascript:void(0)"
                          onClick={uploaddoc}
                          className="cbtn"
                        >
                          Upload Document <i className="fal fa-plus"></i>
                        </Link>
                      </div> */}
                    </div>
                    <div className="d-flex flex-column gap-2 align-items-start number-siz-title">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onInput={searcSite}
                        />
                      </div>
                      <div className="table-w w-100 d-block">
                        <table className="table tableprimary doctable">
                          <thead>
                            <th>Message</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Date Created</th>
                          </thead>
                          <tbody className="trbody">
                            {Array.isArray(issetgetalldocuments) ? (
                              issetgetalldocuments.map((item, index) => (
                                <tr className="even">
                                  <td>{item.message}</td>
                                  <td>
                                    {item.href_status
                                      ? item.href_status
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.href_status.slice(1)
                                      : "N/A"}
                                  </td>

                                  <td>{item.status}</td>
                                  <td>{formatDate_notes(item.created_at)}</td>
                                </tr>
                              ))
                            ) : (
                              <div> No data available </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
