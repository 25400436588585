import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
export const Forgotpassword = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [refreshnotification, setrefreshnotification] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log(userStr);
    document.title = "Forgot Password Page";
    if (userStr != null) {
      navigate("/home");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
    };
    axios
      .post("https://jlmining.app/forgotpassword", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }

        setTimeout(() => {
          setrefreshnotification(true);
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  return (
    <>
      <header>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-9 col-md-4">
              <div className="navbar-list d-flex gap-3 justify-content-end">
                <Link to="/login">Login</Link>
                <Link to="/register">Register</Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="fullscreen d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex flex-column gap-3">
          <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600 mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex flex-column gap-2">
                <h3 className="fs-30 fw-bold text-center mytext-primary">
                  Forgot Password
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Invalid email address, Please enter the correct email."
                    closable
                  />
                )}

                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Please check your mail,Your new password has been successfully created!"
                    closable
                  />
                )}
                <form action="" onSubmit={handleSubmit} method="post">
                  <div className="d-flex flex-column gap-3">
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Email-Id <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-envelope top-icon"></i>
                        <input
                          type="text"
                          name="email"
                          required
                          placeholder="Email-Id"
                        />
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="submit-btn">
                        Send
                      </button>
                    </div>
                  </div>
                </form>

                <div className="d-flex justify-content-center align-items-center my-3">
                  <div className="flex flex-column gap-4 w-md-300">
                    <h3 className="fs-18 fw-bold text-center mytext-primary">
                      <Link to="/login" className="mytext-primary">
                        Login
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="www.hssonline.com.au">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.hssptyltd.com.au">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.jlme.com.au">www.jlme.com.au </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
