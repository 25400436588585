import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { HeaderHome } from "./HeaderHome";
import { Footer } from "./Footer";
import { ReactDialogBox } from "react-js-dialog-box";
import { CiCircleCheck } from "react-icons/ci";
import Slider from "react-slick";
import { SlEnvolopeLetter } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

import { arrow } from "@popperjs/core";

export const Homenew = () => {
  const navigate = useNavigate();
  const [showAlert_time, setShowAlert_time] = useState(false);
  const boxesRef = useRef([]);
  const [opendialogheader1, setopendialogheader] = useState(false);
  const [opendialogheader2, setopendialogheader2] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [SelectedFile_doc, setSelectedFile_doc] = useState([]);
  const [doc1, setdoc1] = useState("");
  const [doc2, setdoc2] = useState("");
  const [doc3, setdoc3] = useState("");
  const [doc4, setdoc4] = useState("");
  const [doc5, setdoc5] = useState("");
  const [Userlicence, setUserlicence] = useState([]);
  const [UsernotificationAdminend, setUsernotificationAdminend] = useState([]);
  const [Usernotificationcourse, setUsernotificationcourse] = useState([]);
  const [UsernotificationDoc, setUsernotificationDoc] = useState([]);
  const [SelectedFile_docarray, setSelectedFile_docarray] = useState(null);
  const [Usernotification1, setUsernotification1] = useState("");
  const [succ1, setsucc1] = useState(false);
  const [succ, setsucc] = useState(false);
  useEffect(() => {
    const ws = new WebSocket("wss://jlmining.app");

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log(receivedMessage, "headerhome");

        if (receivedMessage.event === "AssignNewCourse") {
          getnotification();
          checklicence();
          getnotificationadmin();
          getnotificationcourse();
          getnotificationdocs();
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
      console.log("WebSocket connection closed");
    };
  }, []);
  useEffect(() => {
    function setEqualHeight() {
      if (boxesRef.current.length > 0) {
        let maxHeight = 0;
        // Find the max height
        boxesRef.current.forEach((box) => {
          if (box) {
            box.style.height = "auto"; // Reset height before measuring
            maxHeight = Math.max(maxHeight, box.offsetHeight);
          }
        });
        // Apply max height
        boxesRef.current.forEach((box) => {
          if (box) box.style.height = `${maxHeight}px`;
        });
      }
    }

    setEqualHeight();
    window.addEventListener("resize", setEqualHeight);

    return () => window.removeEventListener("resize", setEqualHeight);
  }, []);
  const handleFileChange = (event) => {
    if (event.target.files[0] != null) {
      setSelectedFile(event.target.files[0]);
      handleprofile_update(event.target.files[0]);
    }
  };
  const handleprofile_update = (file) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/admin/timesheetupload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert_time(true);
          setShowAlert_time({ type: "success" });
        } else {
          setShowAlert_time(true);
          setShowAlert_time({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert_time(false);
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {});
  };
  const opendoc = () => {
    setopendialogheader(true);
  };
  const closeBox = () => {
    setopendialogheader(false);
    setopendialogheader2(false);
  };
  const handleFileChange_doc1 = (event, index) => {
    if (event.target.files != null) {
      const file = event.target.files[0];
      if (index === 0) {
        setdoc1(file.name);
      }
      if (index === 1) {
        setdoc2(file.name);
      }
      if (index === 2) {
        setdoc3(file.name);
      }
      if (index === 3) {
        setdoc4(file.name);
      }
      if (index === 4) {
        setdoc5(file.name);
      }

      setSelectedFile_doc((prevFiles) => {
        const newFiles = Array.isArray(prevFiles) ? [...prevFiles] : [];
        newFiles[index] = file;
        const ss = newFiles.slice(0, 2); // Ensure array length does not exceed 2
        console.log(ss);
        return ss;
      });
    }
  };
  const handleSubmitdoc = (event) => {
    event.preventDefault();
    console.log(SelectedFile_doc);
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formDataa = new FormData();
    if (SelectedFile_doc !== null) {
      if (SelectedFile_doc.length > 0) {
        for (let i = 0; i < SelectedFile_doc.length; i++) {
          formDataa.append("files", SelectedFile_doc[i]);
        }
      }
    }

    formDataa.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/userUpdateDocfile", formDataa, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setsucc(true);
          event.target.reset();
          setSelectedFile_doc([]);
          setSelectedFile_docarray([]);
          setdoc1("");
          setdoc2("");
          setdoc3("");
          setdoc4("");
          setdoc5("");
          setTimeout(() => {
            setsucc(false);
          }, 2500);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getnotification();
  }, []);
  const getnotification = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/getusernotificationhome", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          setUsernotification1(res.data.results);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    checklicence();
  }, []);
  const checklicence = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/checklicence", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.results.length > 0) {
          setUserlicence(res.data.results);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getnotificationadmin();
  }, []);
  const getnotificationadmin = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/getnotificationadmin", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          setUsernotificationAdminend(res.data.results);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getnotificationcourse();
  }, []);
  const getnotificationcourse = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/getusernotificationhomecourse", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUsernotificationcourse(res.data.results);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getnotificationdocs();
  }, []);
  const getnotificationdocs = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/getusernotificationhomedoc", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUsernotificationDoc(res.data.results);
      })
      .catch((err) => {});
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 4 slides at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides on medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 slides on small screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 slide on extra small screens
        },
      },
    ],
  };
  const handleseencourse = (id) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
      id: id,
    };

    axios
      .post("https://jlmining.app/courseSeen", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate("/ohs");
      })
      .catch((err) => {});
  };
  const handleadminend = (id) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
      id: id,
    };

    axios
      .post("https://jlmining.app/adminnotificationread", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate("/notifications");
      })
      .catch((err) => {});
  };
  function formatDate_notes(dateString) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  console.log(UsernotificationDoc.length);
  return (
    <>
      <HeaderHome />
      <div>
        <section className="d-block jlhome py-4">
          <div className="container-xl">
            <div className="row py-4 gy-4 d-flex">
              {[
                {
                  title: "Current Roster",
                  description:
                    "Here, you can access client information, view locations, and manage rosters. Additionally, you have capability to set up roster and generate timesheets.",
                  image: require("./assets/images/current-roster.png"),
                  url: "/currentroster",
                },
                {
                  title: "Timesheet Signature",
                  description:
                    "Here, you can view your hours, obtain a signature from the client or supervisor, and submit it. This will generate your timesheet and send it to the payroll officer.",
                  image: require("./assets/images/timesheet.png"),
                  url: "/timesheet",
                },
                {
                  title: "Client info",
                  description:
                    "Click here to view client information and site details, including phone number, emergency contact details, and email ID.",
                  image: require("./assets/images/ohs.png"),
                  url: "/clinetinformation",
                },
                {
                  title: "OHS",
                  description:
                    "Click here to access OHS, assigned inductions, and induction certificates. You can also report incidents, hazards, and near misses",
                  image: require("./assets/images/client.png"),
                  url: "/ohs",
                },
                {
                  title: "Site Access",
                  description:
                    "Access all site access documents and search for the ones you need. If you cannot find the required document, please contact admin.",
                  image: require("./assets/images/site.png"),
                  url: "/site_access",
                },
                {
                  title: "Manual", // Special Case for File Upload
                  description: "Click here to access all manual documents.",
                  image: require("./assets/images/site.png"),
                  url: "/user_mannual",
                  isUpload: true, // Add a flag for special case
                },
                {
                  title: "Acknowledge Documents",
                  description:
                    "Click here to access acknowledged documents sent for your review. Fill out the document, sign where required, and submit it.",
                  image: require("./assets/images/site.png"),
                  url: "/acknowledgedoc",
                },
              ].map((item, index) => (
                <div className="col-md-3 px-2" key={index}>
                  <div className="border_home">
                    <a
                      href={item.url}
                      ref={(el) => (boxesRef.current[index] = el)}
                      className="jlhome_box d-flex flex-column gap-3 justify-content-between align-items-center"
                    >
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>

                      <div className="jl_home_img">
                        <img
                          className="w-100 h-100"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                    </a>

                    {/* Show Notification if Acknowledge Documents is found */}
                  </div>
                </div>
              ))}

              {showAlert_time?.type === "success" && (
                <Alert
                  type="success"
                  description="Time sheet has been updated successfully!"
                  closable
                />
              )}
              {showAlert_time?.type === "error" && (
                <Alert
                  type="error"
                  description="Something went wrong!"
                  closable
                />
              )}
            </div>
          </div>
        </section>
        <section className="d-block notification_center py-5">
          <div className="container-xl">
            <div className="row gy-3">
              <div className="col-12">
                <h3>Notification Center</h3>
                <p className="mt-1">
                  "From this notification area, you'll receive alerts at»ut
                  tasks, or from clients. These notifications can vary in
                  nature. if you notice an alert. Simply click on it and follow
                  the provided steps to address it."
                </p>
              </div>
              <div className="col-12 px-md-5">
                {UsernotificationDoc.length > 0 ||
                Usernotificationcourse.length > 0 ||
                UsernotificationAdminend.length > 0 ||
                Userlicence.length > 0 ? (
                  <Slider {...settings}>
                    {/* Static Slide for "Required to hire" */}
                    {/* {Usernotificationcourse.length === 0 &&
                  UsernotificationDoc.length === 0 && <p>No result found</p>} */}
                    {UsernotificationDoc.length > 0 && (
                      <div className="p-2">
                        <div className="d-flex">
                          <button
                            className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                            type="button"
                          >
                            <h4>Required to hire</h4>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start gap-4"
                              onClick={opendoc}
                            >
                              {/* <CiCircleCheck className="fontsize" /> */}
                              <h3 className="text-start">
                                Upload Other Documents
                              </h3>
                              <p className="text-start m-0">
                                You must upload the neccessary OHS certificate.
                              </p>
                              {/* <SlEnvolopeLetter className="fontsize" /> */}
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                    {Usernotificationcourse.length > 0 && (
                      <div className="p-2">
                        <div className="d-flex">
                          <button
                            className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                            type="button"
                          >
                            <h4>Required to hire</h4>
                            <div className="d-flex flex-column justify-content-between align-items-start gap-4">
                              <h3 className="text-start">Complete Induction</h3>
                              <p className="text-start m-0">
                                You got notification to complete site induction
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Dynamic Slides for Usernotificationcourse */}
                    {Usernotificationcourse?.length > 0 &&
                      Usernotificationcourse.map((notification, index) => (
                        <div key={index} className="p-2">
                          <div className="d-flex">
                            <button
                              className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                              type="button"
                              onClick={() => handleseencourse(notification.id)}
                            >
                              <h3 className="text-start">Assign Course</h3>
                              <p className="text-start">
                                {notification.message ||
                                  "You got notification to complete site induction"}
                              </p>
                            </button>
                          </div>
                        </div>
                      ))}

                    {/* Dynamic Slides for UsernotificationDoc */}
                    {UsernotificationDoc?.length > 0 &&
                      UsernotificationDoc.map((notification, index) => (
                        <div key={index} className="p-2">
                          <div className="d-flex">
                            <button
                              className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                              type="button"
                              onClick={() => navigate("/acknowledgedoc")}
                            >
                              <h3 className="text-start">Acknowledge Doc</h3>
                              <p className="text-start">
                                {notification.message ||
                                  "You got notification to complete site induction"}
                              </p>
                            </button>
                          </div>
                        </div>
                      ))}
                    {Userlicence?.length > 0 &&
                      Userlicence.map((notification, index) => (
                        <div key={index} className="p-2">
                          <div className="d-flex">
                            <button
                              className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                              type="button"
                            >
                              <p>
                                <strong>{notification.name}</strong> Licence Exp
                              </p>
                              <p className="text-start">
                                {formatDate_notes(notification.expirydate)}
                              </p>
                            </button>
                          </div>
                        </div>
                      ))}
                    {UsernotificationAdminend?.length > 0 &&
                      UsernotificationAdminend.map((notification, index) => (
                        <div key={index} className="p-2">
                          <div className="d-flex">
                            <button
                              className="d-flex flex-column align-items-start gap-1 p-4 w-100"
                              type="button"
                              onClick={() => handleadminend(notification.id)}
                            >
                              <h3 className="text-start">Admin</h3>
                              <p className="text-start">
                                {notification.message}
                              </p>
                            </button>
                          </div>
                        </div>
                      ))}
                  </Slider>
                ) : (
                  <h5>You have no notifications</h5>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      {opendialogheader1 && (
        <ReactDialogBox
          closeBox={closeBox}
          modalWidth="40%"
          headerBackgroundColor="red"
          headerTextColor="white"
          headerHeight="50"
          closeButtonColor="white"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          bodyHeight="400px"
          headerText="Upload Docmument"
        >
          <div style={{ height: "400px", overflowY: "auto" }}>
            <div className="d-flex flex-column gap-3 bg-white   p-4 inperson">
              <p className="mt-4">
                You have successfully accepted the job you applied for. Please
                upload the documents listed below{" "}
              </p>
              <form onSubmit={handleSubmitdoc} method="post">
                <div className="d-flex flex-column gap-3 py-3">
                  {succ && (
                    <p className="text-success ">File uploaded successfully</p>
                  )}
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc1 && <p>{doc1}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 0)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc2 && <p>{doc2}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 1)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc3 && <p>{doc3}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 2)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc4 && <p>{doc4}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 3)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc5 && <p>{doc5}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 4)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div className="position-relative  Document-nm1">
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ReactDialogBox>
      )}
    </>
  );
};
